import type { Bin } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useAtomValue } from 'jotai'

import { useCollectionPoint } from '@/hooks/api/use-collection-point'
import { selectedCollectionPointIdAtom } from '@/state'

interface CollectionPointBinsLoaderProps {
    children: (data: Array<Bin> ) => React.ReactNode
}

const CollectionPointBinsLoader: React.FC<CollectionPointBinsLoaderProps> = ({
    children,
}) => {

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const { status, data, error } = useCollectionPoint(collectionPointId)

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst nádoby svozového místa ID: ${collectionPointId}`}
        />
    }

    if (data?.bins) {
        return <>{children(data.bins)}</>
    }

    return null
}

export default CollectionPointBinsLoader
