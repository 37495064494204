import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import { useEffect, useState, useRef, useCallback } from "react"
import { type Editor, type EditorOptions, useEditor } from "@tiptap/react"
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    MenuSelectTextAlign,
    MenuButtonSuperscript,
    MenuButtonSubscript,
    MenuButtonTextColor,
    MenuButtonEditLink,
    MenuButtonOrderedList,
    MenuButtonBulletedList,
    MenuButtonAddTable,
    MenuButtonImageUpload,
    MenuButtonRemoveFormatting,
    LinkBubbleMenu,
    RichTextEditorProvider,
    RichTextField,
    TableBubbleMenu,
    insertImages,
} from "mui-tiptap"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"

import useTipTapExtensions from "@/hooks/use-tip-tap-extensions"
import { GlobalStyles } from "@mui/material"

type TEditorProps = {
    value: string,
    onChange(body: string): void,
    multipleRows?: boolean,
}

function fileListToImageFiles(fileList: FileList): File[] {
    // Possibly use a package such as (https://www.npmjs.com/package/attr-accept)
    // to restrict to certain file types.
    return Array.from(fileList).filter(file => {
        const mimeType = (file.type || '').toLowerCase()
        return mimeType.startsWith('image/')
    })
}

const TipTap = ({
    onChange,
    value,
    multipleRows,
}: TEditorProps) => {

    const extensions = useTipTapExtensions()

    const rteRef: React.MutableRefObject<Editor | null> = useRef(null)

    const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
        if (!rteRef.current) return

        // Convert each file to base64 and then use it as the src
        const readFileAsBase64 = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => resolve(reader.result as string)
                reader.onerror = error => reject(error)
                reader.readAsDataURL(file)
            })
        }

        // Read each file as base64 and then insert into the editor
        Promise.all(files.map(readFileAsBase64))
            .then(base64Images => {
                const attributesForImageFiles = base64Images.map((base64, index) => ({
                    src: base64,
                    alt: files[index].name,
                }))

                insertImages({
                    images: attributesForImageFiles,
                    editor: rteRef.current,
                    position: insertPosition,
                })
            })
            .catch(error => console.error('Error reading files as Base64:', error))
    }, [])
    
    // Allow for dropping images into the editor
    const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
        (view, event, _slice, _moved) => {
            console.log(event)
            if (!(event instanceof DragEvent) || !event.dataTransfer) {
                return false
            }

            const imageFiles = fileListToImageFiles(event.dataTransfer.files)
            if (imageFiles.length > 0) {
                const insertPosition = view.posAtCoords({
                    left: event.clientX,
                    top: event.clientY,
                })?.pos

                handleNewImageFiles(imageFiles, insertPosition)

                // Return true to treat the event as handled. We call preventDefault
                // ourselves for good measure.
                event.preventDefault()
                return true
            }

            return false
        },
        [handleNewImageFiles]
    )
    
    // Allow for pasting images
    const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
        (_view, event, _slice) => {
            if (!event.clipboardData) {
                return false
            }

            const pastedImageFiles = fileListToImageFiles(event.clipboardData.files)
            if (pastedImageFiles.length > 0) {
                handleNewImageFiles(pastedImageFiles)
                // Return true to mark the paste event as handled. This can for
                // instance prevent redundant copies of the same image showing up,
                // like if you right-click and copy an image from within the editor
                // (in which case it will be added to the clipboard both as a file and
                // as HTML, which Tiptap would otherwise separately parse.)
                return true
            }

            // We return false here to allow the standard paste-handler to run.
            return false
        },
        [handleNewImageFiles]
    )
    
    const editor = useEditor({
        extensions,
        content: value,
        onUpdate({ editor }) {
            onChange(editor.getHTML())
        },
        editorProps: {
            handleDrop: handleDrop,
            handlePaste: handlePaste,
        },
    })

    // Set rteRef required for image pasting and drag&drop
    if (editor) {
        rteRef.current = editor
    }

    useEffect(() => {
        if (!editor) return
        // Wrapping in a setTimeout to avoid flushSync errors
        // (https://github.com/ueberdosis/tiptap/issues/3764)
        setTimeout(() => {
            const { from, to } = editor.state.selection
            editor.commands.setContent(value,
                false, {
                preserveWhitespace: "full"
            })
            editor.commands.setTextSelection({ from, to })
        })
    }, [value, editor])

    return (
        <RichTextEditorProvider editor={editor}>
            <LinkBubbleMenu />
            <TableBubbleMenu />
            <Box sx={{
                // Heading overrides - due to clash with custom MUI theme
                '& .ProseMirror': {
                    minHeight: multipleRows ? 250 : undefined,
                    '& h1': {
                        fontSize: '24px !important',
                        fontWeight: '500 !important',
                    },
                    '& h2': {
                        fontSize: '22px !important',
                    },
                    '& h3': {
                        fontSize: '20px !important',
                    },
                    '& h4': {
                        fontSize: '18px !important',
                    },
                    '& h5': {
                        fontSize: '16px !important',
                    },
                    '& h6': {
                        fontSize: '15px !important',
                    },
                },
            }}>
                <RichTextField
                    controls={
                        <MenuControlsContainer>
                            <MenuSelectHeading />
                            <MenuDivider />
                            <MenuButtonBold />
                            <MenuButtonItalic />
                            <MenuButtonSubscript />
                            <MenuButtonSuperscript />
                            <MenuDivider />
                            <MenuButtonTextColor
                                defaultTextColor={'#000000'}
                                swatchColors={[
                                    // Row 1: Core colors in each group (dark shades)
                                    { value: "#000000", label: "Black" },         // Neutral
                                    { value: "#B00020", label: "Dark Crimson" },  // Red
                                    { value: "#E65100", label: "Dark Orange" },   // Orange
                                    { value: "#F57F17", label: "Dark Gold" },     // Yellow
                                    { value: "#1B5E20", label: "Dark Green" },    // Green
                                    { value: "#0D47A1", label: "Dark Blue" },     // Blue
                                    { value: "#4A148C", label: "Dark Purple" },   // Purple
                                    { value: "#880E4F", label: "Dark Pink" },     // Pink

                                    // Row 2: Mid-tone shades
                                    { value: "#424242", label: "Charcoal Grey" }, // Neutral
                                    { value: "#D32F2F", label: "Crimson" },       // Red
                                    { value: "#F4511E", label: "Burnt Orange" },  // Orange
                                    { value: "#F9A825", label: "Goldenrod" },     // Yellow
                                    { value: "#388E3C", label: "Forest Green" },  // Green
                                    { value: "#1976D2", label: "Royal Blue" },    // Blue
                                    { value: "#7B1FA2", label: "Purple" },        // Purple
                                    { value: "#C2185B", label: "Fuchsia" },       // Pink

                                    // Row 3: Bright and vibrant tones
                                    { value: "#757575", label: "Grey" },          // Neutral
                                    { value: "#F44336", label: "Bright Red" },    // Red
                                    { value: "#FF7043", label: "Bright Orange" }, // Orange
                                    { value: "#FFB300", label: "Amber" },         // Yellow
                                    { value: "#4CAF50", label: "Lime Green" },    // Green
                                    { value: "#42A5F5", label: "Sky Blue" },      // Blue
                                    { value: "#AB47BC", label: "Lavender" },      // Purple
                                    { value: "#E91E63", label: "Hot Pink" },      // Pink

                                    // Row 4: Soft and pastel tones
                                    { value: "#BDBDBD", label: "Light Grey" },    // Neutral
                                    { value: "#EF9A9A", label: "Soft Red" },      // Red
                                    { value: "#FFAB91", label: "Peach" },         // Orange
                                    { value: "#FFE082", label: "Light Gold" },    // Yellow
                                    { value: "#A5D6A7", label: "Pale Green" },    // Green
                                    { value: "#90CAF9", label: "Pastel Blue" },   // Blue
                                    { value: "#CE93D8", label: "Lilac" },         // Purple
                                    { value: "#F48FB1", label: "Baby Pink" },     // Pink
                                ]}
                                ColorPickerProps={{
                                    disableAlpha: true,
                                    
                                }}
                                labels={{
                                    cancelButton: 'Zavřít',
                                    saveButton: 'Vybrat',
                                }}
                            />
                            <MenuDivider />
                            <MenuSelectTextAlign />
                            <MenuDivider />
                            <MenuButtonEditLink />
                            <MenuDivider />
                            <MenuButtonOrderedList />
                            <MenuButtonBulletedList />
                            <MenuButtonAddTable />
                            <MenuDivider />
                            <MenuButtonImageUpload
                                onUploadFiles={(files: File[]) => {
                                    const convertToBase64 = (file: File): Promise<{ src: string; alt: string }> =>
                                        new Promise((resolve, reject) => {
                                            const reader = new FileReader()
                                            reader.onloadend = () => {
                                                if (reader.result) {
                                                    resolve({ src: reader.result as string, alt: file.name })
                                                } else {
                                                    reject(new Error("Failed to convert file to Base64"))
                                                }
                                            };
                                            reader.onerror = reject
                                            reader.readAsDataURL(file)
                                        });

                                    // Convert all files to Base64
                                    return Promise.all(files.map((file) => convertToBase64(file)))
                                }}
                            />
                            {/* <MenuButtonImageUpload
                                onUploadFiles={() => ([{
                                    src: 'https://picsum.photos/200',
                                    alt: 'Test',
                                }])
                                }
                            /> */}
                            <MenuDivider />
                            <MenuButtonRemoveFormatting />
                        </MenuControlsContainer>
                    }
                />
            </Box>
        </RichTextEditorProvider>
    )
}

interface RHFAutocompleteProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    multipleRows?: boolean,
}

const RHFRichText = <
    TField extends FieldValues,
>(
    props: RHFAutocompleteProps<TField>
) => {

    const {
        control,
        name,
        label,
        multipleRows,
    } = props

    const [editHtml, setEditHtml] = useState<boolean>(false)

    return (
        <Box sx={{
            '& .MuiTiptap-MenuBar-root': {
                backgroundColor: 'unset',
            },
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box display="flex" alignItems="center" mb={0.5}>
                <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
                    {label}
                </Typography>
                <IconButton
                    size="small"
                    onClick={() => setEditHtml(!editHtml)}
                    color={editHtml ? 'success' : 'secondary'}
                >
                    <Icon sx={{ fontSize: '18px' }}>code</Icon>
                </IconButton>
            </Box>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (editHtml ?
                        <TextField
                            id="rhf-textarea"
                            size="small"
                            autoComplete="off"
                            multiline
                            minRows={multipleRows ? 4 : undefined}
                            {...field}
                        />
                        :
                        <TipTap value={field.value} onChange={field.onChange} multipleRows={multipleRows} />
                    )
                }}
            />
            {/* Heading list items in menu overrides - due to clash with custom MUI theme */}
            <GlobalStyles styles={(theme) => ({
                'li[data-value="Heading 1"] span': {
                    ...theme.typography.h1,
                    fontSize: '24px',
                },
                'li[data-value="Heading 2"] span': {
                    ...theme.typography.h2,
                    fontSize: '22px',
                },
                'li[data-value="Heading 3"] span': {
                    ...theme.typography.h3,
                    fontSize: '20px',
                },
                'li[data-value="Heading 4"] span': {
                    ...theme.typography.h4
                },
                'li[data-value="Heading 5"] span': {
                    ...theme.typography.h5
                },
                'li[data-value="Heading 6"] span': {
                    ...theme.typography.h6
                },
            })} />
        </Box>
    )
}

export default RHFRichText
