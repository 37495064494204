import { useState } from 'react'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Icon from '@mui/material/Icon'
import { Link, useLocation } from 'react-router-dom'

import { ALLOW_ALL, ROUTE_SEGMENTS } from '@/constants/general'
import PermissionsGate from '@/components/permissions-gate'
import { UserRole } from '@/types'

type NavigationItem = {
    text: string
    icon: string
    link: string
    roles: string[]
    permissionScope: string | null
    open: boolean
    onClick: () => void
    subNav: SubNavigationItem[]
    additionalLinks?: string[]
}

type SubNavigationItem = {
    text: string
    link: string
    roles: string[]
    permissionScope: string | null
    additionalLinks?: string[]
    disabled?: boolean
}

function Menu({
    handleMobileClose,
}: {
    handleMobileClose?: () => void,
}) {

    const [openMunicipality, setOpenMunicipality] = useState(true)
    const [openLocation, setOpenLocation] = useState(true)
    const [openMunicipalityManagement, setOpenMunicipalityManagement] = useState(true)
    const [openAppManagement, setOpenAppManagement] = useState(true)
    const [openCollectionCompany, setOpenCollectionCompany] = useState(true)

    const handleClickMunicipality = () => {
        setOpenMunicipality(!openMunicipality);
    }

    const handleClickLocation = () => {
        setOpenLocation(!openLocation);
    }

    const handleClickMunicipalityManagement = () => {
        setOpenMunicipalityManagement(!openMunicipalityManagement);
    }

    const handleClickAppManagement = () => {
        setOpenAppManagement(!openAppManagement);
    }

    const handleClickCollectionCompany = () => {
        setOpenCollectionCompany(!openCollectionCompany);
    }

    const location = useLocation()

    const navigation: NavigationItem[] = [
        {
            text: 'Obec',
            icon: 'maps_home_work',
            link: ROUTE_SEGMENTS.municipality,
            roles: [ALLOW_ALL],
            permissionScope: null,
            open: openMunicipality,
            onClick: handleClickMunicipality,
            subNav: [
                {
                    text: 'Trend separace v obci',
                    link: ROUTE_SEGMENTS.municipalitySortingTrend,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Svozový kalendář',
                    link: ROUTE_SEGMENTS.collectionCalendar,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Upozornění ke svozu',
                    link: ROUTE_SEGMENTS.collectionNotification,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Poplatek za svoz',
                    link: ROUTE_SEGMENTS.wasteFee,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Jak platit méně',
                    link: ROUTE_SEGMENTS.howToPayLess,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Hlášení černé skládky',
                    link: ROUTE_SEGMENTS.reportIllegalDump,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Veřejná svozová místa',
                    link: ROUTE_SEGMENTS.largeContainerMap,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Sběrné dvory',
                    link: ROUTE_SEGMENTS.collectingYards,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                // {
                //     text: 'Seznam svozových míst',
                //     link: ROUTE_SEGMENTS.collectionPointList,
                //     roles: [ALLOW_ALL],
                //     permissionScope: null,
                //     disabled: true,
                // },
                // {
                //     text: 'Kontakty - obec',
                //     link: ROUTE_SEGMENTS.contactsMunicipality,
                //     roles: [ALLOW_ALL],
                //     permissionScope: null,
                // },
                // {
                //     text: 'Kontakty - svozovka',
                //     link: ROUTE_SEGMENTS.contactsCollectionCompany,
                //     roles: [ALLOW_ALL],
                //     permissionScope: null,
                // },
                {
                    text: 'Kontakty',
                    link: ROUTE_SEGMENTS.contacts,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
            ],
        },
        {
            text: 'Svozové místo',
            icon: 'gite',
            link: ROUTE_SEGMENTS.collectionPoint,
            roles: [ALLOW_ALL],
            permissionScope: null,
            open: openLocation,
            onClick: handleClickLocation,
            subNav: [
                {
                    text: 'Detail',
                    link: ROUTE_SEGMENTS.detail,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Trend výsypu',
                    link: ROUTE_SEGMENTS.disposalTrend,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                {
                    text: 'Reklamace svozu',
                    link: ROUTE_SEGMENTS.collectionClaim,
                    roles: [ALLOW_ALL],
                    permissionScope: null,
                },
                // {
                //     text: 'Objednání extra svozu',
                //     link: ROUTE_SEGMENTS.extraCollection,
                //     roles: [ALLOW_ALL],
                //     permissionScope: null,
                //     disabled: true,
                // },
                // {
                //     text: 'Objednání kontejneru',
                //     link: ROUTE_SEGMENTS.largeContainer,
                //     roles: [ALLOW_ALL],
                //     permissionScope: null,
                //     disabled: true,
                // },
            ],
        },
        {
            text: 'Správa obce',
            icon: 'settings',
            link: ROUTE_SEGMENTS.municipalityManagement,
            roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
            permissionScope: null,
            open: openMunicipalityManagement,
            onClick: handleClickMunicipalityManagement,
            subNav: [
                {
                    text: 'Motivační program',
                    link: ROUTE_SEGMENTS.motivationProgram,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Svozová místa',
                    link: ROUTE_SEGMENTS.collectionPointCategories,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Hospodaření obce',
                    link: ROUTE_SEGMENTS.finances,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Produkce odpadu',
                    link: ROUTE_SEGMENTS.collectedWasteStats,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Černé skládky',
                    link: ROUTE_SEGMENTS.illegalDumps,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Upozornění ke svozu',
                    link: ROUTE_SEGMENTS.collectionNotificationManagement,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
                {
                    text: 'Komunikace s občany',
                    link: ROUTE_SEGMENTS.communication,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
            ],
        },
        {
            text: 'Svozová společnost',
            icon: 'local_shipping',
            link: ROUTE_SEGMENTS.collectionCompany,
            roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
            permissionScope: null,
            open: openCollectionCompany,
            onClick: handleClickCollectionCompany,
            subNav: [
                {
                    text: 'Reklamace svozů',
                    link: ROUTE_SEGMENTS.collectionClaims,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
                    permissionScope: null,
                },
                {
                    text: 'Správa obcí',
                    link: ROUTE_SEGMENTS.municipalitiesManagement,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
                    permissionScope: null,
                },
                {
                    text: 'EKOKOM bonus',
                    link: ROUTE_SEGMENTS.ekokomBonus,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
                    permissionScope: null,
                },
                {
                    text: 'EKOKOM statistiky',
                    link: ROUTE_SEGMENTS.ekokomStats,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
                    permissionScope: null,
                },
            ],
        },
        {
            text: 'Správa aplikace',
            icon: 'tune',
            link: ROUTE_SEGMENTS.appManagement,
            roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany ],
            permissionScope: null,
            open: openAppManagement,
            onClick: handleClickAppManagement,
            subNav: [
                {
                    text: 'Správa uživatelů',
                    link: ROUTE_SEGMENTS.users,
                    roles: [ UserRole.admin, UserRole.eokoDataAdmin, UserRole.collectionCompany, UserRole.municipalityOfficial ],
                    permissionScope: null,
                },
            ],
        },
    ]

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                pr: 1,
                py: 0,
                maxHeight: 'calc(100vh - 6rem)',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(47, 49, 145, .1)',
                },
                scrollBehavior: 'smooth',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {navigation.map(mainNav => (
                <PermissionsGate userRoles={mainNav.roles} key={mainNav.text}>
                    <Box sx={{mb: 1}}>
                        <ListItemButton
                            onClick={mainNav.onClick} 
                            sx={{
                                pl: 1.25,
                                py: 0.75,
                                borderTopRightRadius: 20,
                                borderBottomRightRadius: 20,
                                '&.Mui-selected': {
                                    backgroundColor: 'customLightGreen.dark'
                                },
                            }}
                            selected={mainNav.link === location.pathname.slice(1).split('/')[1]}
                        >
                            <ListItemIcon sx={{ minWidth: '24px', pr: 1.25, color: 'inherit' }}>
                                <Icon>{mainNav.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={mainNav.text} primaryTypographyProps={{ sx: { fontWeight: 500 }}}/>
                            {mainNav.open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={mainNav.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {mainNav.subNav.map(subNav => (
                                    <PermissionsGate userRoles={subNav.roles} key={subNav.text}>
                                        <ListItemButton
                                            key={subNav.text}    
                                            component={Link}
                                            sx={{
                                                pl: 5.5,
                                                py: {
                                                    xs: 0.5,
                                                    md: 0.3,
                                                },
                                                borderTopRightRadius: 20,
                                                borderBottomRightRadius: 20,
                                                '&.Mui-selected': {
                                                    backgroundColor: 'customLightGreen.light'
                                                },
                                            }}
                                            to={`/${ROUTE_SEGMENTS.base}/${mainNav.link}/${subNav.link}`}
                                            selected={subNav.additionalLinks ?
                                                [subNav.link, ...subNav.additionalLinks].includes(location.pathname.slice(1).split('/')[1]) :
                                                subNav.link === location.pathname.slice(1).split('/')[2]
                                            }
                                            disabled={subNav.disabled}
                                            onClick={() => {
                                                if (handleMobileClose) handleMobileClose()
                                            }}
                                        >
                                            <ListItemText primary={subNav.text} />
                                        </ListItemButton>
                                    </PermissionsGate>
                                ))}
                            </List>
                        </Collapse>
                    </Box>
                </PermissionsGate>
            ))}
        </List>
    )
}

export default Menu
