import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type {
    Pagination,
    MutationOptions,
    UsersFilters,
    UsersResponse,
    UserFormInput,
    UserEmailFormInput,
    UserRoleFormInput,
    UserPasswordFormInput,
    ChangeMyPasswordFormInput,
} from '@/types'
import { isErrorResponse } from '@/helpers'

export function useUsers(
    pagination: Pagination,
    filters: UsersFilters,
) {
    return useQuery({
        queryKey: [
            'users',
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            `roles-${filters.roles.join(',')}`,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            if (filters.roles.length > 0) queryParams.append('roles', JSON.stringify(filters.roles))
            
            const { data } : { data: UsersResponse } = await axios.get(
                `/api/user/list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export const useCreateUser = (options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: UserFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('email', data.email)
            queryParams.append('password', data.password)
            queryParams.append('role', data.role)
            if (data.municipality_id) queryParams.append('municipality_id', data.municipality_id)
            
            return axios.post(`/api/user/create?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpdateUserEmail = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: UserEmailFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('user_id', userId)
            queryParams.append('email', data.email)
            
            return axios.post(`/api/user/update_email?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpdateUserRole = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: UserRoleFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('user_id', userId)
            queryParams.append('role', data.role)
            
            return axios.post(`/api/user/update_role?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpdateUserPassword = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: UserPasswordFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('user_id', userId)
            queryParams.append('password', data.password)
            
            return axios.post(`/api/user/set_password?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useToggleUserActive = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (active: boolean) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('user_id', userId)
            queryParams.append('active', active ? 'true' : 'false')
            
            return axios.post(`/api/user/active?${queryParams.toString()}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useApproveUser = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {            
            return axios.post(`/api/user/approve?user_id=${userId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useSendUserWelcomeEmail = (userId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {            
            return axios.post(`/api/user/send_welcome_email?user_id=${userId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useChangeMyPassword = (options: MutationOptions) => {

    const {
        onSuccessCallback,
        onErrorCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ChangeMyPasswordFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('old_password', data.old_password)
            queryParams.append('password', data.password)
            queryParams.append('password_confirmation', data.password_confirmation)
            
            return axios.post(`/api/user/change_my_password?${queryParams.toString()}`)
        },
        onSuccess: response => {
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}
