import {
    type Dispatch,
    type SetStateAction,
    useState,
    type MouseEvent,
} from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useNavigate } from 'react-router-dom'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { ROUTE_SEGMENTS } from '@/constants/general'

function BinActions({
    binId,
    setChangeId,
    setSuccess,
}: {
    binId: number,
    setChangeId: Dispatch<SetStateAction<number | null>>,
    setSuccess: Dispatch<SetStateAction<boolean>>,
}) {

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClickClaim = () => {
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.collectionClaim}?bin-id=${binId}`)
    }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id={'actions-'+binId}
                    aria-label={'actions-'+binId}
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="terciary"
                >
                    <MoreVertOutlinedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id={`${binId}-actions-menu`}
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                <MenuItem
                    onClick={() => {
                        setSuccess(false)
                        setChangeId(binId)
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <ChangeCircleRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Žádost o výměnu
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        handleClickClaim()
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    Reklamace svozu
                </MenuItem>
            </Menu>
        </>
    )
}

export default BinActions
