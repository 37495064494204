import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Button from '@mui/material/Button'
import KeyIcon from '@mui/icons-material/Key'

import {
    UserRole,
    type User,
} from '@/types'
import { USER_ROLE_LABEL_MAP } from '@/constants/general'
import MunicipalitiesLoader from '@/wrappers/municipalities-loader'
import UserEmailForm from '@/components/forms/user-email'
import UserRoleForm from '@/components/forms/user-role'
import UserPasswordForm from '../forms/user-password'
import ToggleUserActive from './toggle-user-active'
import ApproveUser from './approve-user'
import SendUserWelcomeEmail from './send-user-welcome-email'
import PermissionsGate from '@/components/permissions-gate'

function UserDetail({
    user,
    handleClose,
}: {
    user: User,
    handleClose?: () => void,
}) {

    const [editEmail, setEditEmail] = useState(false)
    const [editRole, setEditRole] = useState(false)
    const [changePassword, setChangePassword] = useState(false)

    const tieredRoles = [
        UserRole.admin,
        ...(user.role !== UserRole.admin ?
            [UserRole.eokoDataAdmin, UserRole.collectionCompany ] :
            []
    ),
        ...(user.role === UserRole.collectionPointAdmin || user.role === UserRole.resident ?
                [UserRole.municipalityOfficial] :
                []
        ),
    ]

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            position: 'relative',
        }}>
            <Box>
                <Typography variant="h3" component="h3">
                    Detail uživatele
                    <Box component="span" color="customGrey.secondaryText"> ID {user.id}</Box>
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <Box sx={theme => ({
                    borderTop: `1px dashed ${theme.palette.customGrey.cardBorder}`,
                    pt: 1,
                })}>
                    <Typography variant="h5" component="h3">
                        Aktivní
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        {user.active ?
                            <Box sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor: 'success.light',
                            }}/>
                            :
                            <Box sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor: 'error.light',
                            }}/>
                        }
                        <Typography variant="body1" component="p">
                            {user.active ? 'Ano' : 'Ne'}
                        </Typography>
                    </Box>
                    <PermissionsGate
                        userRoles={tieredRoles}
                    >
                        <ToggleUserActive user={user} />
                    </PermissionsGate>
                </Box>
                <Box sx={theme => ({
                    borderTop: `1px dashed ${theme.palette.customGrey.cardBorder}`,
                    pt: 1,
                })}>
                    <Typography variant="h5" component="h3">
                        Email
                    </Typography>
                    <Typography variant="body1" component="p">
                        {user.email}
                    </Typography>
                </Box>
                <Box position="relative">
                    <Typography variant="h5" component="h3">
                        Role
                    </Typography>
                    <Typography variant="body1" component="p">
                        {USER_ROLE_LABEL_MAP[user.role]}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" component="h3">
                        Obec
                    </Typography>
                    {user.municipality_id ?
                        <MunicipalitiesLoader>
                            {municipalities => (
                                <Typography variant="body1" component="p">
                                    {municipalities.find(municipality => municipality.id === user.municipality_id).name}
                                </Typography>
                            )}
                        </MunicipalitiesLoader>
                        :
                        '-'
                    }
                </Box>
                <Box>
                    <Typography variant="h5" component="h3">
                        EOKO ID kontaktu
                    </Typography>
                    <Typography variant="body1" component="p">
                        {user.eoko_contacts_users_junctions.length > 0 ?
                            user.eoko_contacts_users_junctions.map(junction => junction.eoko_contact_id).join(', ')
                            : '-'
                        }
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'flex-start',
                }}>
                    {user.role !== UserRole.collectionPointAdmin && (editEmail ?
                        <UserEmailForm user={user} closeCallback={() => setEditEmail(false)}/>
                        :
                        <PermissionsGate
                            userRoles={tieredRoles}
                        >
                            <Button
                                startIcon={<EditRoundedIcon />}
                                onClick={() => setEditEmail(true)}
                                color='terciary'
                                variant="outlined"
                            >
                                Upravit email
                            </Button>
                        </PermissionsGate>
                    )}
                    {user.role !== UserRole.collectionPointAdmin && (editRole ?
                        <UserRoleForm user={user} closeCallback={() => setEditRole(false)}/>
                        :
                        <PermissionsGate
                            userRoles={tieredRoles}
                        >
                            <Button
                                startIcon={<EditRoundedIcon />}
                                onClick={() => setEditRole(true)}
                                color='terciary'
                                variant="outlined"
                            >
                                Upravit roli
                            </Button>
                        </PermissionsGate>
                    )}
                </Box>
                <Box sx={theme => ({
                    borderTop: `1px dashed ${theme.palette.customGrey.cardBorder}`,
                    pt: 1,
                })}>
                    <Typography variant="h5" component="h3">
                        Datum vytvoření
                    </Typography>
                    <Typography variant="body1" component="div" color="customGrey.secondaryText">
                        {user.created_at ? dayjs(user.created_at).format('D. M. YYYY HH:mm') : '-'}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" component="h3">
                        Datum posledních úprav
                    </Typography>
                    <Typography variant="body1" component="div" color="customGrey.secondaryText">
                        {user.updated_at ? dayjs(user.updated_at).format('D. M. YYYY HH:mm') : '-'}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" component="h3">
                        Schváleno
                    </Typography>
                    <Typography variant="body1" component="div" color="customGrey.secondaryText">
                        {user.approved_at ? dayjs(user.approved_at).format('D. M. YYYY HH:mm') : 'Ne'}
                        {user.approved_by_id ? ' uživatelem ID '+user.approved_by_id : null}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" component="h3">
                        Odeslán přístupový email
                    </Typography>
                    <Typography variant="body1" component="div" color="customGrey.secondaryText">
                        {user.welcome_email_sent_at ? dayjs(user.welcome_email_sent_at).format('D. M. YYYY HH:mm') : 'Ne'}
                    </Typography>
                </Box>
                <Box>
                    {changePassword ?
                        <UserPasswordForm user={user} closeCallback={() => setChangePassword(false)}/>
                        :
                        <PermissionsGate
                            userRoles={tieredRoles}
                        >
                            <Button
                                startIcon={<KeyIcon />}
                                onClick={() => setChangePassword(true)}
                                color='terciary'
                                variant="outlined"
                            >
                                Změnit heslo
                            </Button>
                        </PermissionsGate>
                    }
                    {!user.approved_at &&
                        <PermissionsGate
                            userRoles={tieredRoles}
                        >
                            <ApproveUser user={user} />
                        </PermissionsGate>
                    }
                    {user.active && user.approved_at &&
                        <PermissionsGate
                            userRoles={tieredRoles}
                        >
                            <SendUserWelcomeEmail user={user} />
                        </PermissionsGate>
                    }
                </Box>
            </Box>
            {handleClose &&
                <Box sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '1rem',
                }}>
                    <IconButton
                        onClick={handleClose}
                        color="terciary"
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            }
        </Box>
    )
}

export default UserDetail
