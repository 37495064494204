import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import type { CollectionPointCategoriesTable } from '@/types'

function CategoryTable({
    heading,
    categories,
    attribute,
}: {
    heading: string,
    categories: CollectionPointCategoriesTable[],
    attribute: 'primary_weight' | 'primary_volume' | 'comparison_weight' | 'comparison_volume'
}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Typography variant="h5" component="h3" mb={1} textAlign="center">
                {heading}
            </Typography>
            <Box display="flex" gap={1.5}>
                <Box sx={(theme) => ({
                    borderRadius: 2.5,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 3,
                    py: 1.5,
                    color: theme.palette.customGrey.tableText,
                })}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {categories.map(categoryCount => (
                                <tr key={categoryCount.text}>
                                    <td>
                                        <Typography variant="h5" component="span" sx={{ color: categoryCount.color }}>
                                            {categoryCount.text}
                                        </Typography>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount[attribute+'_count']}
                                        </Box>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount[attribute+'_percentage']}%
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
                <Box sx={{
                    width: 10,
                    backgroundColor: 'customGrey.cardBorder',
                    borderRadius: 5,
                    overflow: 'hidden',
                }}>
                    {categories.map(categoryCount => (
                        <Box key={categoryCount.text} sx={{
                            height: `${categoryCount[attribute+'_percentage']}%`,
                            backgroundColor: categoryCount.color,
                        }}/>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default CategoryTable
