import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type {
    FormMutationOptions,
    MunicipalitySize,
    Region,
    EkokomStat,
    EkokomStatByMunicipalitySizeUpsertFormInput,
    EkokomStatByRegionUpsertFormInput,
} from '@/types'
import { isErrorResponse } from '@/helpers'

export function useRegions() {
    return useQuery({
        queryKey: ['regions'],
        queryFn: async () => {
            const { data } : { data: Array<Region> } = await axios.get(
                '/api/region/list',
            )
            return data
        },
    })
}

export function useEkokomStats(
    year: number,
) {
    return useQuery({
        queryKey: ['ekokomStats', year],
        queryFn: async () => {
            const { data } : { data: Array<EkokomStat>} = await axios.get(
                `/api/ekokom_stat/list?year=${year}`
            )
            return data
        },
    })
}

export const useUpsertEkokomStatsByMunicipality = (
    municipalitySizes: Array<MunicipalitySize>,
    options: FormMutationOptions
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: EkokomStatByMunicipalitySizeUpsertFormInput) => {
            const params = municipalitySizes.map(size => ({
                "municipality_size_id": size.id,
                "year": data.year,
                "mixed_weight_per_citizen": data[`mixed_weight${size.id}`],
                "sorted_weight_per_citizen": data[`sorted_weight${size.id}`],
            }))

            const queryParam = encodeURIComponent(JSON.stringify(params))
            
            return axios.post(`/api/ekokom_stat/upsert?stats=${queryParam}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['ekokomStats'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useUpsertEkokomStatsByRegion = (
    regions: Array<Region>,
    options: FormMutationOptions
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        onErrorCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: EkokomStatByRegionUpsertFormInput) => {
            const params = regions.map(region => ({
                "region_id": region.id,
                "year": data.year,
                "mixed_weight_per_citizen": data[`mixed_weight${region.id}`],
                "sorted_weight_per_citizen": data[`sorted_weight${region.id}`],
            }))

            const queryParam = encodeURIComponent(JSON.stringify(params))
            
            return axios.post(`/api/ekokom_stat/upsert?stats=${queryParam}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['ekokomStats'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}
