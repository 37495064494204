import { useState } from 'react'
import type { MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import KeyIcon from '@mui/icons-material/Key'
import Alert from '@mui/material/Alert'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { DOM_NODE_ID, ROUTE_SEGMENTS } from '@/constants/general'
import ChangeMyPasswordForm from '@/components/forms/change-my-password'


const domNode = document.getElementById(DOM_NODE_ID)
const userEmail = domNode?.getAttribute('user-email')

const ProfileButton = () => {

    const [changePassword, setChangePassword] = useState<boolean>(false)
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const initials = (userEmail[0]+userEmail[1]).toUpperCase()

    return (
        <>
            <Tooltip title="Uživatelský účet">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'primary.main',
                        fontSize: '16px',
                        color: 'common.white'
                    }}>{initials ? initials : <AccountCircleRoundedIcon />}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem disabled>
                    {userEmail}
                </MenuItem>
                <MenuItem onClick={() => setChangePassword(true)}>
                    <ListItemIcon>
                        <KeyIcon fontSize="small" />
                    </ListItemIcon>
                    Změnit heslo
                </MenuItem>
                <MenuItem component="a" href={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.signOut}`}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Odhlásit se
                </MenuItem>
            </Menu>
            <Dialog
                key={'change-my-password'}
                open={!!changePassword}
                onClose={() => {
                    if (passwordChangeSuccess) {
                        window.location.href = `/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.signOut}`;
                    } else {
                        setChangePassword(false);
                    }
                }}
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                        pb: 2,
                    }}>
                        <Avatar sx={{
                            width: 32,
                            height: 32,
                            backgroundColor: 'primary.main',
                            fontSize: '16px',
                            color: 'common.white'
                        }}>
                            {initials ? initials : <AccountCircleRoundedIcon />}
                        </Avatar>
                        <Box>{userEmail}</Box>
                    </Box>
                    {passwordChangeSuccess ?
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            px: 3,
                        }}>
                            <Alert
                                severity="success"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                Heslo úspěšně změněno.
                            </Alert>
                            <Button
                                variant="contained"
                                color="terciary"
                                size="small"
                                component="a"
                                href={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.signOut}`}
                            >
                                Znovu se přihlásit
                            </Button>
                        </Box>
                        :
                        <>
                            <Alert
                                severity="info"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                Po změně hesla bude nutné se znovu přihlásit
                            </Alert>
                            <ChangeMyPasswordForm
                                onSuccessCallback={() => setPasswordChangeSuccess(true)}
                            />
                        </>
                    }
                </DialogContent>
                <Box sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '1rem',
                }}>
                    {passwordChangeSuccess ?
                        <IconButton
                            color="terciary"
                            component="a"
                            href={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.signOut}`}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                        :
                        <IconButton
                            onClick={() => setChangePassword(false)}
                            color="terciary"
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    }
                </Box>
            </Dialog>
        </>
    )
}

export default ProfileButton
