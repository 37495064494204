import { useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type {
    MunicipalityFinanceMonth,
    MonthYear,
    MunicipalityFinanceMonthFormInput,
} from "@/types"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import { useCreateMunicipalityFinanceMonth, useUpdateMunicipalityFinanceMonth } from "@/hooks/api/use-municipality-finances"
import FormRow from "./reusables/form-row"

const MunicipalityFinanceMonthForm = ({
    municipalityFinanceMonth,
    closeCallback,
    yearMonth,
} : {
    municipalityFinanceMonth?: MunicipalityFinanceMonth,
    closeCallback?: () => void,
    yearMonth?: MonthYear,
}) => {

    const formDataId = `municipalityFinanceMonthForm-${municipalityFinanceMonth?.id}-v${APP_VERSION}`

    const initialValues = {
        year_month: municipalityFinanceMonth?.year_month ? municipalityFinanceMonth.year_month : yearMonth ? yearMonth : '',
        bin_collection_expenses: municipalityFinanceMonth?.bin_collection_expenses ? municipalityFinanceMonth.bin_collection_expenses : '',
        collection_yard_expenses: municipalityFinanceMonth?.collection_yard_expenses ? municipalityFinanceMonth.collection_yard_expenses : '',
        other_expenses: municipalityFinanceMonth?.other_expenses ? municipalityFinanceMonth.other_expenses : '',
        ekokom_income: municipalityFinanceMonth?.ekokom_income ? municipalityFinanceMonth.ekokom_income : '',
        citizen_fee_income: municipalityFinanceMonth?.citizen_fee_income ? municipalityFinanceMonth.citizen_fee_income : '',
        collection_yard_income: municipalityFinanceMonth?.collection_yard_income ? municipalityFinanceMonth.collection_yard_income : '',
        other_income: municipalityFinanceMonth?.other_income ? municipalityFinanceMonth.other_income : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateMunicipalityFinanceMonth({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
    })

    const mutationUpdate = useUpdateMunicipalityFinanceMonth(
        municipalityFinanceMonth?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<MunicipalityFinanceMonthFormInput> = data => {
        setRequestInProgress(true)
        if (municipalityFinanceMonth?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={(municipalityFinanceMonth ?
                'Upravit hodnoty pro ' :
                'Vyplnit hodnoty pro '
            )+dayjs(yearMonth).locale('cs').format('MMMM YYYY')}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={municipalityFinanceMonth ? 'Upravit' : 'Uložit'}
        >
            <Typography variant="h5" component="h3" gutterBottom>
                Náklady
            </Typography>
            <FormRow>
                <RHFNumberTextfield
                    name="bin_collection_expenses"
                    control={control}
                    label="Nádobový sběr"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
                <RHFNumberTextfield
                    name="collection_yard_expenses"
                    control={control}
                    label="Provoz sběrných dvorů"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
                <RHFNumberTextfield
                    name="other_expenses"
                    control={control}
                    label="Ostatní"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
            </FormRow>
            <Typography variant="h5" component="h3" gutterBottom>
                Příjmy
            </Typography>
            <FormRow>
                <RHFNumberTextfield
                    name="ekokom_income"
                    control={control}
                    label="EKOKOM"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
                <RHFNumberTextfield
                    name="citizen_fee_income"
                    control={control}
                    label="Občané"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
                <RHFNumberTextfield
                    name="collection_yard_income"
                    control={control}
                    label="Sběrné dvory"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
                <RHFNumberTextfield
                    name="other_income"
                    control={control}
                    label="Ostatní"
                    thousandSeparator
                    disallowNegative
                    size="small"
                    endAdornment="Kč"
                />
            </FormRow>
        </Form>
    )
}

export default MunicipalityFinanceMonthForm
