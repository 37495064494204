import { useForm, type SubmitHandler } from "react-hook-form"
import Box from '@mui/material/Box'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type {
    MunicipalitySize,
    EkokomStatByMunicipalitySizeUpsertFormInput,
    EkokomStat,
} from "@/types"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import { useUpsertEkokomStatsByMunicipality } from "@/hooks/api/use-ekokom-stats"

const materialKeys = [
    "mixed_weight",
    "sorted_weight",
]

const EkokomStatsByMunicipalitySizeForm = ({
    stats,
    closeCallback,
    year,
    municipalitySizes,
} : {
    stats?: Array<EkokomStat>,
    closeCallback?: () => void,
    year: number
    municipalitySizes: Array<MunicipalitySize>,
}) => {

    const formDataId = `ekokomStatsByMunicipalitySizeForm-${year}-v${APP_VERSION}`

    const mappedValues = {}

    for (const size of municipalitySizes) {
        const ekokomStat = stats.find(stat => stat.municipality_size_id === size.id)
        for (const key of materialKeys) {
            let value = ''
            if (ekokomStat) {
                value = ekokomStat[key+'_per_citizen'] != null ? ekokomStat[key+'_per_citizen'] : '0'
            }
            mappedValues[`${key}${size.id}`] = value
        }
    }

    const initialValues: EkokomStatByMunicipalitySizeUpsertFormInput = ({
        year,
        ...mappedValues,
    } as EkokomStatByMunicipalitySizeUpsertFormInput)

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useUpsertEkokomStatsByMunicipality(
        municipalitySizes,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<EkokomStatByMunicipalitySizeUpsertFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading="Upravit hodnoty pro statistiky dle velikosti obce"
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            error={error}
            setError={setError}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowX: 'auto',
            }}>
                <table style={{
                    maxWidth: 560,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 160, fontWeight: 500, fontSize: '15px', textAlign: 'left' }}>Velikost obce</th>
                            <th style={{ minWidth: 140, fontWeight: 500, fontSize: '15px' }}>Směsný (SKO)</th>
                            <th style={{ minWidth: 140, fontWeight: 500, fontSize: '15px' }}>Tříděný</th>
                        </tr>
                    </thead>
                    <tbody>
                        {municipalitySizes.map(size => (
                            <tr key={size.id}>
                                <th style={{fontWeight: 500, fontSize: '15px', textAlign: 'left'}}>{size ? `${size?.citizens_count_gte} - ${size?.citizens_count_lt}` : "Velikost neurčena"}</th>
                                {materialKeys.map(key => (
                                    <td key={key}>
                                        <RHFNumberTextfield
                                            name={(key+size.id) as keyof EkokomStatByMunicipalitySizeUpsertFormInput}
                                            control={control}
                                            thousandSeparator
                                            disallowNegative
                                            decimalScale={2}
                                            size="small"
                                            required
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </Form>
    )
}

export default EkokomStatsByMunicipalitySizeForm
