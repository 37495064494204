import { useAtom } from 'jotai'
import { Link, Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import RecyclingIcon from '@mui/icons-material/Recycling'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import Toolbar from '@mui/material/Toolbar'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'

import AppBar from '@/components/app-bar'
import { ROUTE_SEGMENTS } from '@/constants/general'
import { Typography } from '@mui/material'
import { selectedMunicipalityIdAtom } from "@/state"
import MunicipalitiesLoader from '@/wrappers/municipalities-loader'

const Main = styled('main')<{
    open?: boolean
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(0, 1.5, 1.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: theme.breakpoints.values.xl,
}))

function PublicLayout() {

    const [municipalityId, setMunicipalityId] = useAtom(selectedMunicipalityIdAtom)

    const navigate = useNavigate()

    const location = useLocation()
    const locationSlugs = location.pathname.slice(1).split('/')
    const showNav = locationSlugs.length > 1

    const fitsLarge = useMediaQuery('(min-width:420px)')
    const isVerySmall = useMediaQuery('(max-width:380px)')

    const nav: {
        text: string,
        icon: JSX.Element,
        link: string,
        disabled?: boolean,
    }[] = [
        {
            text: 'Trend separace v obci',
            icon: <InsertChartOutlinedIcon />,
            link: ROUTE_SEGMENTS.municipalitySortingTrend,
        },
        {
            text: 'Svozový kalendář',
            icon: <CalendarMonthOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectionCalendar,
        },
        {
            text: 'Upozornění ke svozu',
            icon: <ChatOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectionNotification,
        },
        {
            text: 'Poplatek za svoz',
            icon: <MoneyOutlinedIcon />,
            link: ROUTE_SEGMENTS.wasteFee,
        },
        {
            text: 'Jak platit méně',
            icon: <SentimentSatisfiedAltIcon />,
            link: ROUTE_SEGMENTS.howToPayLess,
        },
        {
            text: 'Hlášení černé skládky',
            icon: <ReportOutlinedIcon />,
            link: ROUTE_SEGMENTS.reportIllegalDump,
        },
        {
            text: 'Veřejná svozová místa',
            icon: <FmdGoodOutlinedIcon />,
            link: ROUTE_SEGMENTS.largeContainerMap,
        },
        {
            text: 'Sběrné dvory',
            icon: <RecyclingOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectingYards,
        },
        {
            text: 'Kontakty',
            icon: <ContactPhoneOutlinedIcon />,
            link: ROUTE_SEGMENTS.contacts,
        },
    ]

    return (
        <Box display="flex">
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => ({ md: theme.zIndex.drawer + 1 }) }}>
                <Toolbar sx={{
                    paddingX: { xs: 1, sm: 2},
                    marginX: 'auto',
                    width: '100%',
                    maxWidth: 'xl'
                }}>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        {showNav ?
                            <Button
                                color="primary"
                                component={Link}
                                to={`/${ROUTE_SEGMENTS.public}`}
                                startIcon={<ArrowBackOutlinedIcon />}
                                size={fitsLarge ? 'large' : 'medium'}
                                sx={{
                                    paddingX: isVerySmall ? 1 : null,
                                }}
                            >
                                Zpět
                            </Button>
                        :
                            <Button
                                color="primary"
                                component={Link}
                                to={`/${ROUTE_SEGMENTS.public}`}
                                startIcon={<RecyclingIcon />}
                                size={fitsLarge ? 'large' : 'medium'}
                                sx={{
                                    paddingX: isVerySmall ? 1 : null,
                                }}
                            >
                                Domů
                            </Button>
                        }
                    </Box>
                    <Box flexGrow={1} display="flex" justifyContent="center">
                        {municipalityId &&
                            <Button
                                color="primary"
                                onClick={() => {
                                    navigate(`/${ROUTE_SEGMENTS.public}`)
                                    setMunicipalityId(null)
                                }}
                                startIcon={<MapsHomeWorkOutlinedIcon />}
                                size={fitsLarge ? 'large' : 'medium'}
                                sx={{
                                    paddingX: isVerySmall ? 2 : null,
                                }}
                            >
                                Výběr obce
                            </Button>
                        }
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        component="a"
                        href="/users/sign_in"
                        size={fitsLarge ? 'medium' : 'small'}
                    >
                        Přihlásit se
                    </Button>
                </Toolbar>
            </AppBar>
            <Main>
                <Toolbar />
                {municipalityId &&
                    <Box mt={1} mb={4}>
                        <MunicipalitiesLoader>
                            {municipalities => (
                                <Typography
                                    variant="h1"
                                    component="h1"
                                    sx={{
                                        fontSize: {
                                            md: '2.4rem',
                                        },
                                        textAlign: 'center',
                                        color: 'primary.dark',
                                    }}
                                >
                                    {municipalities.find(item => item.id === municipalityId).name}
                                </Typography>
                            )}
                        </MunicipalitiesLoader>
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row'
                    },
                    gap: 2,
                }}>
                    {showNav &&
                        <Box sx={{
                            display: {
                                xs: 'none',
                                md: 'flex',
                            },
                            flexDirection: 'column',
                            gap: 2,
                            minWidth: 220,
                        }}>
                            {nav.map(navItem => (
                                <Box
                                    key={navItem.text}    
                                    component={Link}
                                    to={navItem.link}
                                    sx={{
                                        borderRadius: 2.5,
                                        backgroundColor: 'white',
                                        padding: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        color: navItem.disabled ? 'customGrey.secondaryText' :
                                            navItem.link === locationSlugs[1] ? 'primary.main' : 'inherit',
                                        textDecoration: 'inherit',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255,255,255,0.7)',
                                        },
                                        pointerEvents: navItem.disabled ? 'none' : 'unset',
                                    }}
                                >
                                    <Box sx={{
                                        '& svg': {
                                            fontSize: '2rem',
                                        }
                                    }}>
                                        {navItem.icon}
                                    </Box>
                                    <Typography variant="h5" component="div" textAlign="center">
                                        {navItem.text}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    }
                    <Box flexGrow={1}>
                        <Outlet />
                    </Box>
                </Box>
            </Main>
            <ScrollRestoration />
        </Box>
    )
}

export default PublicLayout
