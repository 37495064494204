import { useState, useEffect, Fragment } from 'react'
import { atom, type PrimitiveAtom, useAtom, useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import HouseRoundedIcon from '@mui/icons-material/HouseRounded'
import CabinRoundedIcon from '@mui/icons-material/CabinRounded'
import RecyclingRoundedIcon from '@mui/icons-material/RecyclingRounded'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BlockIcon from '@mui/icons-material/Block'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import StraightRoundedIcon from '@mui/icons-material/StraightRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded'
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded'

import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { useCollectionPointCategoryList, useRefreshHouseholdCategories } from '@/hooks/api/use-municipality'
// import useDateInterval from '@/hooks/use-date-interval'
import {
    CollectionPointTypes,
    OrderDirection,
    type MotivationModelListItem,
    type Categories,
    type Pagination,
    type CollectionPointCategoryWithSingleExtension,
    MMParticipation,
    CollectionPointCategoryListOrderAttribute as OrderAttribute,
} from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP } from '@/constants/general'
import CategorySelector from './category-selector'
import PaginationControls from '@/components/pagination-controls'
import { Typography } from '@mui/material'
import MotivationModelSelector from './motivation-model-selector'
import TypeSelector from './type-selector'
import NumberTextfield from '@/components/form-fields/number-textfield'
import { convertFromCents, printPrice, roundPrice } from '@/helpers'
import CollectionPointCategoriesExport from './export'
import CollectionPointCategoriesActions from './actions'
import CollectionPointParticipationForm from '../forms/collection-point-participation'
import useMounted from '@/hooks/use-mounted'
import CategoryOverview from './category-overview'
import SearchField from './search-field'

const COLLECTION_POINT_TYPE_ICON_MAP = {
    [CollectionPointTypes.apartmentBuilding]: <ApartmentIcon/>,
    [CollectionPointTypes.holidayHome]: <CabinRoundedIcon/>,
    [CollectionPointTypes.familyHouse]: <HouseRoundedIcon/>,
    [CollectionPointTypes.publicWastepoint]: <RecyclingRoundedIcon />,
    [CollectionPointTypes.unknown]: <BlockIcon/>,
}

const CATEGORIES_COLORS_MAP = {
    '1': 'categories.first',
    '2': 'categories.second',
    '3': 'categories.third',
    '4': 'categories.fourth',
    '5': 'categories.fifth',
}

// Filters
const mixedWeightCategoriesAtom = atom<Categories[]>([])
const mixedVolumeCategoriesAtom = atom<Categories[]>([])
const typesAtom = atom<CollectionPointTypes[]>([])
const numberOfMembersAtom = atom<string>('')
const inMotivationProgramAtom = atom<MMParticipation>(MMParticipation.all)
const showFiltersAtom = atom<boolean>(false)
const addressLikeAtom = atom<string>('')
const adminNameLikeAtom = atom<string>('')
const addressLikeValueAtom = atom<string>('')
const adminNameLikeValueAtom = atom<string>('')
// Order
const orderAttributeAtom = atom<OrderAttribute | null>(null) as PrimitiveAtom<OrderAttribute | null>
const orderDirectionAtom = atom<OrderDirection>(OrderDirection.asc)

const paginationAtom = atom<Pagination>({
    limit: 20,
    offset: 0,
})

const showOverviewAtom = atom<boolean>(false)

const columnDefinitions = [
    { label: 'Účast v MP', attribute: null, enableSort: false, participation: true },
    { label: 'Kód', attribute: OrderAttribute.code, enableSort: true },
    { label: 'Typ', attribute: OrderAttribute.type, enableSort: true },
    { label: 'Adresa', attribute: OrderAttribute.address, enableSort: true },
    { label: 'Správce', attribute: OrderAttribute.name, enableSort: true },
    { label: 'Počet členů', attribute: OrderAttribute.numberOfMembers, enableSort: true },
    { label: 'Kat. hmotnost', attribute: OrderAttribute.activeMixedWeightCategoryLevel, enableSort: true },
    { label: 'Kat. objem', attribute: OrderAttribute.byActiveMixedVolumeCategoryLevel, enableSort: true },
    { label: 'Sleva celkem', attribute: OrderAttribute.discountCents, enableSort: true },
    { label: 'Poplatek/os.', attribute: OrderAttribute.feeCents, enableSort: true },
]

function CollectionPointCategoriesList({
    year,
    motivationModels,
}: {
    year: number,
    motivationModels: Array<MotivationModelListItem>,
}) {

    const activeMotivationModel = motivationModels.find(model => model.active)
    const inComparisonMotivationModel = motivationModels.find(model => model.in_comparison)
    const initialModelId = activeMotivationModel?.id ?? inComparisonMotivationModel?.id ?? motivationModels[0]?.id ?? null

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [motivationModelId, setMotivationModelId] = useState<number | null>(initialModelId)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const [edit, setEdit] = useState<CollectionPointCategoryWithSingleExtension | null>(null)
    
    const [pagination, setPagination] = useAtom(paginationAtom)

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    const [showOverview, setShowOverview] = useAtom(showOverviewAtom)

    const [mixedWeightCategories, setMixedWeightCategories] = useAtom(mixedWeightCategoriesAtom)
    const [mixedVolumeCategories, setMixedVolumeCategories] = useAtom(mixedVolumeCategoriesAtom)
    const [types, setTypes] = useAtom(typesAtom)
    const [numberOfMembers, setNumberOfMembers] = useAtom(numberOfMembersAtom)
    const [inMotivationProgram, setInMotivationProgram] = useAtom(inMotivationProgramAtom)
    const [showFilters, setShowFilters] = useAtom(showFiltersAtom)
    const [addressLike, setAddressLike] = useAtom(addressLikeAtom)
    const [adminNameLike, setAdminNameLike] = useAtom(adminNameLikeAtom)
    const [addressLikeValue, setAddressLikeValue] = useAtom(addressLikeValueAtom)
    const [adminNameLikeValue, setAdminNameLikeValue] = useAtom(adminNameLikeValueAtom)

    const [orderAttribute, setOrderAttribute] = useAtom(orderAttributeAtom)
    const [orderDirection, setOrderDirection] = useAtom(orderDirectionAtom)

    const handleResetFilters = () => {
        setMixedWeightCategories([])
        setMixedVolumeCategories([])
        setTypes([])
        setNumberOfMembers('')
        setInMotivationProgram(MMParticipation.all)
        setAddressLike('')
        setAdminNameLike('')
        setAddressLikeValue('')
        setAdminNameLikeValue('')
    }

    const handleNumberOfMembersChange = ({
        target: {
            value,
        },
    }) => {
        setNumberOfMembers(value)
    }

    const getFiltersCount = (): number => {
        let count = 0
        if (mixedWeightCategories.length > 0) count++
        if (mixedVolumeCategories.length > 0) count++
        if (types.length > 0) count++
        if (numberOfMembers !== '') count++
        if (inMotivationProgram !== MMParticipation.all) count++
        if (addressLike !== '') count++
        if (adminNameLike !== '') count++
        return count
    }

    const filtersCount = getFiltersCount()

    const isMounted = useMounted()

    /*  biome-ignore lint: useEffect deps
        We want to run this in reaction to municipalityId change
    */
    useEffect(() => {
        if(isMounted) {
            handleResetPagination()
            setMotivationModelId(initialModelId)
        }
    }, [municipalityId])

    const { status, data, error, isPlaceholderData } = useCollectionPointCategoryList(
        municipalityId,
        motivationModelId,
        pagination,
        year,
        {
            mixedWeightCategories,
            mixedVolumeCategories,
            types,
            numberOfMembers,
            inMotivationProgram,
            addressLike,
            adminNameLike,
        },
        {
            orderAttribute,
            orderDirection,
        },
    )

    const mutation = useRefreshHouseholdCategories(
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickRefresh = () => {
        setRequestInProgress(true)
        mutation.mutate({ motivationModelId, municipalityId, year })
    }

    const handleClickOrder = (attribute: OrderAttribute) => {
        if (orderAttribute !== attribute) {
            setOrderAttribute(attribute)
            setOrderDirection(OrderDirection.asc)
        } else {
            if (orderDirection === OrderDirection.asc) {
                setOrderDirection(OrderDirection.desc)
            } else {
                setOrderDirection(OrderDirection.asc)
            }
        }
    }

    const handleToggleParticipationFilter = () => {
        if (inMotivationProgram === MMParticipation.all) {
            setInMotivationProgram(MMParticipation.no)
        } else if (inMotivationProgram === MMParticipation.no) {
            setInMotivationProgram(MMParticipation.yes)
        } else {
            setInMotivationProgram(MMParticipation.all)
        }
    }

    if (requestInProgress) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress />
                    <Typography variant='h6' component="div" mt={2}>
                        Přepočítávám kategorie
                    </Typography>
                    <Typography variant='body2' component="div">
                        Pro velké obce může tato operace trvat i několik minut.
                    </Typography>
                </Box>
            </Box>
        )
    }

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst svozová místa a jejich kategorie pro obec s ID ${municipalityId}`}
        />
    }

    const lacksComparison = !activeMotivationModel ||
        motivationModelId === activeMotivationModel?.id ||
        !inComparisonMotivationModel ||
        motivationModelId !== inComparisonMotivationModel?.id

    const categorySelector = <Box sx={{
        display: 'flex',
        columnGap: 2,
        rowGap: 1,
        flexWrap: 'wrap',
        alignItems: 'center',
        pb: {
            xs: 1,
            sm: 0,
        }
    }}>
        <Typography variant="h6" component="h6" sx={{ pb: { xs: 0.5, sm: 0 }}}>{activeMotivationModel ? 'Srovnat s' : 'Kategorie dle'}</Typography>
        <Box sx={{
            display: 'flex',
            columnGap: 1.5,
            rowGap: 1,
            flexWrap: 'wrap',
        }}>
            <Box sx={{
                minWidth: {
                    xs: 200,
                    sm: 220,
                }
            }}>
                <MotivationModelSelector
                    motivationModelId={motivationModelId}
                    setMotivationModelId={setMotivationModelId}
                    year={year}
                />
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                    onClick={() => handleClickRefresh()}
                    disabled={requestInProgress || !motivationModelId || (activeMotivationModel && motivationModelId === initialModelId)}
                >
                    Přepočítat
                </Button>
            </Box>
        </Box>
    </Box>

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto',
            pb: 2,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                px: {
                    xs: 1.5,
                    sm: 2.5,
                },
                pt: 1,
                gap: 1,
            }}>
                <Box sx={{
                    display: {
                        lg: 'none',
                    }
                }}>
                    {categorySelector}
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: 0.5,
                    rowGap: 1,
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                    }}>
                        <Button
                            onClick={() => setShowOverview(!showOverview)}
                            startIcon={<InsertChartOutlinedRoundedIcon />}
                            color={showOverview ? 'primary' : 'terciary'}
                            sx={{
                                backgroundColor: showOverview ? 'customLightGreen.light' : 'customGrey.boxBackgroundLight',
                            }}
                        >
                            Souhrn
                        </Button>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            minWidth: 165,
                        }}>
                            <Button
                                onClick={() => setShowFilters(!showFilters)}
                                startIcon={<FilterAltRoundedIcon />}
                                color={showFilters ? 'primary' : 'terciary'}
                                sx={{
                                    backgroundColor: showFilters ? 'customLightGreen.light' : 'customGrey.boxBackgroundLight',
                                }}
                            >
                                Filtry
                            </Button>
                            {!!filtersCount &&
                                <Tooltip title="Resetovat filtry">
                                    <Button
                                        onClick={() => handleResetFilters()}
                                        startIcon={<DoNotDisturbAltRoundedIcon />}
                                        sx={{
                                            backgroundColor: 'customLightGreen.dark',
                                            px: 1,
                                        }}
                                    >
                                        {filtersCount}
                                    </Button>
                                </Tooltip>
                            }
                        </Box>
                    </Box>
                    <Box sx={{
                        display: {
                            xs: 'none',
                            lg: 'block',
                        }
                    }}>
                        {categorySelector}
                    </Box>
                    <CollectionPointCategoriesExport
                        year={year}
                        motivationModelId={motivationModelId}
                        filters={{
                            mixedWeightCategories,
                            mixedVolumeCategories,
                            types,
                            numberOfMembers,
                            inMotivationProgram,
                            addressLike,
                            adminNameLike,
                        }}
                    />
                </Box>
            </Box>
            <Box>
                <Collapse in={showOverview}>
                    <Box sx={{
                        pt: 2,
                        px: 2.5,
                        pb: 2,
                    }}>
                        <CategoryOverview year={year} lacksComparison={lacksComparison} />
                    </Box>
                </Collapse>
                <Collapse in={showFilters}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        pt: 1,
                        flexWrap: 'wrap',
                        px: 2.5,
                        alignItems: 'center',
                    }}>
                        <Box display="flex">
                            <CategorySelector
                                label="Kategorie hmotnost"
                                categories={mixedWeightCategories}
                                setCategories={setMixedWeightCategories}
                                callback={handleResetPagination}
                            />
                        </Box>
                        <Box display="flex">
                            <CategorySelector
                                label="Kategorie objem"
                                categories={mixedVolumeCategories}
                                setCategories={setMixedVolumeCategories}
                                callback={handleResetPagination}
                            />
                        </Box>
                        <Box display="flex" maxWidth={190}>
                            <TypeSelector
                                label="Typy"
                                types={types}
                                setTypes={setTypes}
                                callback={handleResetPagination}
                            />
                        </Box>
                        <Box display="flex">
                            <NumberTextfield
                                label="Počet členů"
                                onChange={(e) => handleNumberOfMembersChange(e)}
                                name='numberOfMembers'
                                value={numberOfMembers}
                                size='small'
                                thousandSeparator
                            />
                        </Box>
                        <Box sx={{ minWidth: 190 }}>
                            <FormControl fullWidth>
                                <InputLabel id="in-motivation-program-select-label" size="small">Účast v motivačním programu</InputLabel>
                                <Select
                                    labelId="in-motivation-program-select-label"
                                    id="in-motivation-program-select"
                                    value={inMotivationProgram}
                                    name="inMotivationProgram"
                                    label="Účast v motivačním programu"
                                    onChange={(e) => setInMotivationProgram(e.target.value as MMParticipation)}
                                    size="small"
                                >
                                    <MenuItem value={MMParticipation.all}>Všichni</MenuItem>
                                    <MenuItem value={MMParticipation.yes}>Účastní se</MenuItem>
                                    <MenuItem value={MMParticipation.no}>Neúčastní se</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <SearchField
                                setValue={setAddressLike}
                                inputValue={addressLikeValue}
                                setInputValue={setAddressLikeValue}
                                label="Hledat adresu"
                            />
                        </Box>
                        <Box>
                            <SearchField
                                setValue={setAdminNameLike}
                                inputValue={adminNameLikeValue}
                                setInputValue={setAdminNameLikeValue}
                                label="Hledat správce"
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
            {success &&
                <Box px={2.5}>
                    <Alert
                        severity="success"
                        sx={{
                            flexGrow: 1,
                        }}
                        action={
                            <Button
                                color="success"
                                size="small"
                                onClick={() => setSuccess(false)}
                            >
                                Zavřít
                            </Button>
                        }
                    >
                        Kategorie úspěšně přepočítány.
                    </Alert>
                </Box>
            }
            <Box>
                <Table
                    aria-label="Roční statistika odpadu ve sběrných dvorech"
                    sx={{
                        mt: 1,
                        minWidth: 600,
                    }}
                    size='small'
                >
                    <TableHead>
                        <TableRow>
                            {columnDefinitions.map(({ label, attribute, enableSort, participation }, index) => (
                                <TableCell
                                    key={index}
                                    onClick={
                                        participation ? () => handleToggleParticipationFilter() :
                                            enableSort ? () => handleClickOrder(attribute) : undefined
                                    }
                                    sx={{
                                        paddingLeft: index === 0 ? 3 : undefined,
                                        ...(enableSort || participation) && {
                                            cursor: 'pointer',
                                            '&:hover': {
                                                opacity: 0.7,
                                            },
                                        },
                                    }}
                                >
                                    {label}
                                    {enableSort && attribute === orderAttribute &&
                                        <Box sx={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            pointerEvents: 'none',
                                        }}>
                                            <StraightRoundedIcon sx={{
                                                transform: orderDirection === OrderDirection.asc ? undefined : 'rotate(180deg)',
                                                position: 'absolute',
                                                left: 0,
                                                top: '-17px',
                                                color: 'primary.main',
                                            }}/>
                                        </Box>
                                    }
                                    {participation && inMotivationProgram !== MMParticipation.all &&
                                            <Box sx={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                pointerEvents: 'none',
                                            }}>
                                                <Box sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    position: 'absolute',
                                                    left: 3,
                                                    top: -8,
                                                    backgroundColor: inMotivationProgram === MMParticipation.no ? 'error.main' : 'success.main',
                                                }}/>
                                            </Box>
                                        }
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.eoko_household.map(row => {
                            const ext = row.exts.find(ext => ext.year === year)
                            
                            const primaryMixedWeightCategory =
                                ext.active_mixed_weight_category ?? ext.mixed_weight_category ?? null
                            const primaryMixedVolumeCategory =
                                ext.active_mixed_volume_category ?? ext.mixed_volume_category ?? null

                            const comparisonMixedWeightCategory = lacksComparison ? null :
                                ext.mixed_weight_category ?? null
                            const comparisonMixedVolumeCategory = lacksComparison ? null :
                                ext.mixed_volume_category ?? null

                            const primaryDiscountCents = ext.in_motivation_program ?
                                primaryMixedWeightCategory?.discount_amount_cents+primaryMixedVolumeCategory?.discount_amount_cents :
                                ext.force_discount_cents
                            const comparisonDiscountCents = lacksComparison ? null : ext.in_motivation_program ?
                                comparisonMixedWeightCategory?.discount_amount_cents+comparisonMixedVolumeCategory?.discount_amount_cents :
                                ext.force_discount_cents

                            const primaryBaseFeeCents = primaryMixedWeightCategory?.motivation_model?.citizen_fee_cents || primaryMixedVolumeCategory?.motivation_model?.citizen_fee_cents
                            const comparisonBaseFeeCents = lacksComparison ? null :
                                comparisonMixedWeightCategory?.motivation_model?.citizen_fee_cents || comparisonMixedVolumeCategory?.motivation_model?.citizen_fee_cents

                            const primaryFeeAfterDiscount = primaryBaseFeeCents != null ?
                                primaryDiscountCents != null ?
                                    primaryBaseFeeCents > primaryDiscountCents ?
                                        primaryBaseFeeCents-primaryDiscountCents
                                        :
                                        0
                                    :
                                    primaryBaseFeeCents
                                :
                                null
                            const comparisonFeeAfterDiscount = comparisonBaseFeeCents != null ?
                                comparisonDiscountCents != null ?
                                    comparisonBaseFeeCents > comparisonDiscountCents ?
                                        comparisonBaseFeeCents-comparisonDiscountCents
                                        :
                                        0
                                    :
                                    comparisonBaseFeeCents
                                :
                                null
                            
                            return (
                                <Fragment key={row.id}>
                                    <TableRow
                                        key={row.id}
                                        sx={(theme) => ({
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& > td, th': { borderBottom: 'unset' },
                                            ...(edit?.id === row.id ? {
                                                backgroundColor: theme.palette.customLightYellow.light,
                                            } : {})
                                        })}
                                    >
                                        <TableCell component="th" sx={{ paddingLeft: 3 }}>
                                            {ext?.in_motivation_program != null ?
                                                ext.in_motivation_program ?
                                                    null :
                                                    <CancelRoundedIcon color="error" />
                                                : '-'
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            {row.code}
                                        </TableCell>
                                        <TableCell sx={{color: 'customGrey.tableText'}}>
                                            <Tooltip title={COLLECTION_POINT_TYPE_LABEL_MAP[row.type]}>
                                                {COLLECTION_POINT_TYPE_ICON_MAP[row.type]}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {row.address?.street ?? '-'} {row.address?.street_number ?? '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.admin?.first_name ?? '-'} {row.admin?.last_name ?? '-'}
                                        </TableCell>
                                        <TableCell>
                                            {row.number_of_members ?? '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex">
                                                {primaryMixedWeightCategory?.level &&
                                                    <Box sx={{
                                                        backgroundColor: CATEGORIES_COLORS_MAP[primaryMixedWeightCategory.level],
                                                        width: 22,
                                                        height: 22,
                                                        borderRadius: 10,
                                                        color: 'white',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {primaryMixedWeightCategory.level}
                                                    </Box>
                                                }
                                                
                                                {comparisonMixedWeightCategory?.level &&
                                                    <>
                                                        <ArrowRightAltRoundedIcon sx={{ color: 'customGrey.secondaryText' }} />
                                                        <Box sx={{
                                                            backgroundColor: CATEGORIES_COLORS_MAP[comparisonMixedWeightCategory.level],
                                                            width: 22,
                                                            height: 22,
                                                            borderRadius: 10,
                                                            color: 'white',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            opacity: 0.8,
                                                        }}>
                                                            {comparisonMixedWeightCategory.level}
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex">
                                                {primaryMixedVolumeCategory?.level &&
                                                    <Box sx={{
                                                        backgroundColor: CATEGORIES_COLORS_MAP[primaryMixedVolumeCategory.level],
                                                        width: 22,
                                                        height: 22,
                                                        borderRadius: 10,
                                                        color: 'white',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {primaryMixedVolumeCategory.level}
                                                    </Box>
                                                }
                                                {comparisonMixedVolumeCategory?.level &&
                                                    <>
                                                        <ArrowRightAltRoundedIcon sx={{ color: 'customGrey.secondaryText' }} />
                                                        <Box sx={{
                                                            backgroundColor: CATEGORIES_COLORS_MAP[comparisonMixedVolumeCategory.level],
                                                            width: 22,
                                                            height: 22,
                                                            borderRadius: 10,
                                                            color: 'white',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                            {comparisonMixedVolumeCategory.level}
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex">
                                                {primaryDiscountCents != null ? printPrice(roundPrice(convertFromCents(primaryDiscountCents))) : '-'}
                                                {comparisonDiscountCents &&
                                                    <>
                                                        <ArrowRightAltRoundedIcon sx={{ color: 'customGrey.secondaryText' }} />
                                                        <Box color="customGrey.secondaryText">
                                                            {comparisonDiscountCents != null ? printPrice(roundPrice(convertFromCents(comparisonDiscountCents))) : '-'}
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex">
                                                {primaryFeeAfterDiscount != null ? printPrice(roundPrice(convertFromCents(primaryFeeAfterDiscount))) : '-'}
                                                {comparisonFeeAfterDiscount &&
                                                    <>
                                                        <ArrowRightAltRoundedIcon sx={{ color: 'customGrey.secondaryText' }} />
                                                        <Box color="customGrey.secondaryText">
                                                            {comparisonFeeAfterDiscount != null ? printPrice(roundPrice(convertFromCents(comparisonFeeAfterDiscount))) : '-'}
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <CollectionPointCategoriesActions
                                                collectionPoint={{
                                                    ...row,
                                                    ext,
                                                }}
                                                setEdit={setEdit}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: 0 }} colSpan={11}>
                                            <Collapse in={edit?.id === row.id} timeout="auto" unmountOnExit>
                                                <Box sx={(theme) => ({
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 1.5,
                                                    p: 1.5,
                                                    justifyContent: 'center',
                                                    borderTop: `1px solid ${theme.palette.customGrey.divider}`
                                                })}>
                                                    {edit?.id === row.id &&
                                                        <CollectionPointParticipationForm
                                                            collectionPoint={edit}
                                                            year={year}
                                                            closeCallback={() => setEdit(null)}
                                                        />
                                                    }
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
                <Box minHeight={4}>
                    {isPlaceholderData &&
                        <LinearProgress />
                    }
                </Box>
                <PaginationControls
                    pagination={pagination}
                    setPagination={setPagination}
                    currentArrayLength={data.eoko_household.length}
                    disabled={isPlaceholderData}
                    recordsCount={data.records_count}
                />
            </Box>
            {data.records_count === 0 &&
                <Box px={2.5} pt={2}>
                    V tomto roce obec nemá žádná data o svozových místech a jejich kategoriích. K doplnění dat dochází každou noc. Zkuste to prosím zítra.
                </Box>
            }
        </Box>
    )
}

export default CollectionPointCategoriesList
