import type { Region } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useRegions } from '@/hooks/api/use-ekokom-stats'

interface RegionsLoaderProps {
    children: (data: Array<Region> ) => React.ReactNode
}

const RegionsLoader: React.FC<RegionsLoaderProps> = ({
    children,
}) => {
    const { status, data, error } = useRegions()

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst kraje'}
        />
    }


    if (data.length > 0) {
        return <>{children(data)}</>
    }

    return null
}

export default RegionsLoader
