import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CommunicationList from '@/components/communication/list'
import CollectionCompanyLoader from '@/wrappers/collection-company-loader'
import CommunicationListCollectionCompany from '@/components/communication/list-collection-company'
import MunicipalitiesLoader from '@/wrappers/municipalities-loader'

function CommunicationView({
    collectionCompanyView,
}: {
    collectionCompanyView?: boolean,
}) {

    const title = collectionCompanyView ? 'Reklamace svozů' : 'Komunikace s občany'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper noPadding>
                <Box p={2.5}>
                    <Typography variant="h2" component="h1">
                        {title}
                    </Typography>
                </Box>
                {collectionCompanyView ?
                    <CollectionCompanyLoader>
                        {collectionCompany => (
                            <MunicipalitiesLoader>
                                {municipalities => (
                                    <CommunicationListCollectionCompany
                                        collectionCompanyId={collectionCompany.id}
                                        municipalities={municipalities}
                                    />
                                )}
                            </MunicipalitiesLoader>
                        )}
                    </CollectionCompanyLoader>
                    :
                    <CommunicationList />
                }
            </CustomPaper>
        </>
    )
}

export default CommunicationView
