import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextarea from "@/components/form-fields/rhf-textarea"
import type {
    CreateCommunicationFormInput,
} from "@/types"
import { useCreateAttributesChangeRequest } from "@/hooks/api/use-communication"

const CreateAttributesChangeRequestForm = ({
    municipalityId,
    closeCallback,
    toggleSuccess,
} : {
    municipalityId: string | number,
    closeCallback?: () => void,
    toggleSuccess: () => void,
}) => {

    const formDataId = `createAttributesChangeRequestForm-${municipalityId}-v${APP_VERSION}`

    const initialValues = {
        description: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useCreateAttributesChangeRequest(
        municipalityId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: toggleSuccess,
        }
    )

    const onSubmit: SubmitHandler<CreateCommunicationFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Nahlašte prosím změnu, kterou má obec zpracovat'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={'Odeslat'}
        >
            <RHFTextarea
                name="description"
                control={control}
                label="Změny k nahlášení"
                size="small"
                required
            />
        </Form>
    )
}

export default CreateAttributesChangeRequestForm
