import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAtomValue } from 'jotai'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CollectionPointDetailWrapper from '@/components/collection-point/detail/wrapper'
import { selectedCollectionPointIdAtom, selectedMunicipalityIdAtom } from '@/state'
import HistoryReturnButton from '@/components/history-return-button'
import CollectionPointMessageListMunicipality from '@/components/collection-point/message-list-municipality'

function DetailView() {

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)
    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const title = 'Detail svozového místa'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        lg: 'row',
                    },
                    gap: 3,
                }}>
                    <Box sx={{
                        flexBasis: {
                            xs: '100%',
                            lg: '65%',
                        },
                    }}>
                        {collectionPointId ?
                            <>
                                <HistoryReturnButton />
                                <CollectionPointDetailWrapper />
                            </>
                            :
                            <>
                                <Typography variant="h2" component="h1" mb={2.5}>
                                    {title}
                                </Typography>
                                <Box>
                                    Vyberte prosím konkrétní svozové místo výše.
                                </Box>
                            </>
                        }
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: {
                            xs: '100%',
                            lg: '35%',
                        },
                        pl: {
                            xs: 0,
                            lg: 3,
                        },
                        pt: {
                            xs: 3,
                            lg: 0,
                        },
                        borderLeft: {
                            xs: '0px',
                            lg: `1px solid ${theme.palette.customGrey.divider}`,
                        },
                        borderTop: {
                            xs: `1px solid ${theme.palette.customGrey.divider}`,
                            lg: '0px',
                        },
                    })}>
                        <CollectionPointMessageListMunicipality municipalityId={municipalityId} />
                    </Box>
                </Box>
            </CustomPaper>
        </>
    )
}

export default DetailView
