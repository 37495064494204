import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import IconButton from '@mui/material/IconButton'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { HISTORY_RETURN_PARAM_KEY, ROUTE_SEGMENTS } from '@/constants/general'
import CommunicationThreadDetail from '@/components/communication/thread-detail'

function CommunicationDetailView({
    collectionCompanyView,
}: {
    collectionCompanyView?: boolean,
}) {

    const title = 'Detail komunikace'

    const { communicationId } = useParams()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const historyReturn = params.get(HISTORY_RETURN_PARAM_KEY)

    const navigate = useNavigate()

    const link = collectionCompanyView ?
        `/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.communication}` :
        `/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionCompany}/${ROUTE_SEGMENTS.collectionClaims}`

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box pb={3} display="flex" alignItems="center" gap={1}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        color="terciary"
                        onClick={historyReturn === 'true' ?
                            () => navigate(-1) :
                            () => navigate(link)}
                    >
                        <ArrowBackRoundedIcon />
                    </IconButton>
                    <Typography variant="h3" component="h1">
                        {title}
                    </Typography>
                </Box>
                <Box sx={theme => ({
                    p: {
                        xs: 0,
                        sm: 2,
                    },
                    border: {
                        xs: 'none',
                        sm: `1px solid ${theme.palette.divider}`,
                    },
                    borderRadius: {
                        xs: 0,
                        sm: 2.5,
                    },
                    maxWidth: 'sm',
                })}>
                    <CommunicationThreadDetail communicationId={Number(communicationId)} full />
                </Box>
            </CustomPaper>
        </>
    )
}

export default CommunicationDetailView
