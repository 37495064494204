import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'
import { Link } from 'react-router-dom'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import Fade from '@mui/material/Fade'
import { Helmet } from 'react-helmet-async'

import { APP_VERSION, ROUTE_SEGMENTS } from '@/constants/general'
import { selectedMunicipalityIdAtom } from '@/state'
import MunicipalitySortingTrendHome from '@/components/municipality-sorting-trend/home'
import MunicipalitySelector from '@/components/municipality-selector'
import CollectionNotificationListHome from '@/components/collection-notification/list-home'
import HeadingWithIcon from '@/components/heading-with-icon'
import Scoreboard from '@/components/scoreboard'

function PublicHomeView() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    

    const nav: {
        text: string,
        icon: JSX.Element,
        link: string,
        disabled?: boolean,
    }[] = [
        {
            text: 'Trend separace v obci',
            icon: <InsertChartOutlinedIcon />,
            link: ROUTE_SEGMENTS.municipalitySortingTrend,
        },
        {
            text: 'Svozový kalendář',
            icon: <CalendarMonthOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectionCalendar,
        },
        {
            text: 'Upozornění ke svozu',
            icon: <ChatOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectionNotification,
        },
        {
            text: 'Poplatek za svoz',
            icon: <MoneyOutlinedIcon />,
            link: ROUTE_SEGMENTS.wasteFee,
        },
        {
            text: 'Jak platit méně',
            icon: <SentimentSatisfiedAltIcon />,
            link: ROUTE_SEGMENTS.howToPayLess,
        },
        {
            text: 'Hlášení černé skládky',
            icon: <ReportOutlinedIcon />,
            link: ROUTE_SEGMENTS.reportIllegalDump,
        },
        {
            text: 'Veřejná svozová místa',
            icon: <FmdGoodOutlinedIcon />,
            link: ROUTE_SEGMENTS.largeContainerMap,
        },
        {
            text: 'Sběrné dvory',
            icon: <RecyclingOutlinedIcon />,
            link: ROUTE_SEGMENTS.collectingYards,
        },
        {
            text: 'Kontakty',
            icon: <ContactPhoneOutlinedIcon />,
            link: ROUTE_SEGMENTS.contacts,
        },
    ]

    const title = 'jaktridim.cz - Přehled obce'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {!municipalityId &&
                <>
                    <Container maxWidth="sm">
                        <Typography variant="h4" component="h2" color="primary.dark" textAlign="center">
                            Vyberte obec
                        </Typography>
                        <Box mt={2} mb={4}>
                            <MunicipalitySelector publicList />
                        </Box>
                    </Container>
                    <Container maxWidth="sm">
                        <Scoreboard />
                    </Container>
                    <Container maxWidth="sm" sx={{
                        paddingX: 0,
                    }}>
                        <Box mb={3}>
                            <img
                                src="/south-moravia-dots.svg"
                                loading="lazy"
                                alt="Mapa Jizni Morava"
                                style={{
                                    maxWidth: '100%',
                                    width: '100%',
                                }}
                            />
                        </Box>
                    </Container>
                </>
            }
            <Fade in={!!municipalityId} unmountOnExit>
                <Box>
                    <Box sx={{
                        px: {
                            xs: 0,
                            sm: 2
                        },
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            lg: 'row',
                        },
                        gap: 2,
                    }}>
                        <Box sx={{
                            flexGrow: 1,
                            flexBasis: {
                                lg: '50%',
                            }
                        }}>
                            <Grid container spacing={2}>
                                {nav.map(navItem => (
                                    <Grid key={navItem.text} xs={6} sm={4} lg={6} xl={4}>
                                        <Box
                                            component={Link}
                                            to={navItem.link}
                                            sx={{
                                                borderRadius: 2.5,
                                                backgroundColor: 'white',
                                                padding: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1,
                                                height: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: navItem.disabled ? 'customGrey.secondaryText' : 'inherit',
                                                textDecoration: 'inherit',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255,255,255,0.7)',
                                                },
                                                pointerEvents: navItem.disabled ? 'none' : 'unset',
                                            }}
                                        >
                                            <Box sx={{
                                                '& svg': {
                                                    fontSize: '2rem',
                                                }
                                            }}>
                                                {navItem.icon}
                                            </Box>
                                            <Typography variant="h5" component="div" textAlign="center">
                                                {navItem.text}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        {municipalityId &&
                            <Paper elevation={0} sx={{
                                p: 2,
                                borderRadius: 2.5,
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                flexBasis: {
                                    lg: '50%',
                                }
                            }}>
                                <Box justifyContent="center" display="flex">
                                    <HeadingWithIcon
                                        icon={<ChatOutlinedIcon />}
                                        text="Upozornění ke svozu"
                                    />
                                </Box>
                                <Box sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                    maxHeight: {
                                        xs: 320,
                                        lg: 435,
                                    },
                                    mt: 1.5,
                                }}>
                                    <CollectionNotificationListHome />
                                </Box>
                                <Box pt={3} display="flex" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="terciary"
                                        component={Link}
                                        to={`/${ROUTE_SEGMENTS.public}/${ROUTE_SEGMENTS.collectionNotification}`}
                                    >
                                        Více
                                    </Button>
                                </Box>
                            </Paper>
                        }
                    </Box>
                    <Box sx={{
                        mt: 2,
                        px: {
                            xs: 0,
                            sm: 2
                        } 
                    }}>
                        {municipalityId &&
                            <Paper sx={{ p: 2, borderRadius: 2.5, mb: 3 }} elevation={0}>
                                <Box mb={2} justifyContent="center" display="flex">
                                    <HeadingWithIcon
                                        icon={<InsertChartOutlinedIcon />}
                                        text="Trend separace v obci"
                                    />
                                </Box>
                                <MunicipalitySortingTrendHome />
                                <Box pt={3} pb={1} display="flex" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="terciary"
                                        component={Link}
                                        to={`/${ROUTE_SEGMENTS.public}/${ROUTE_SEGMENTS.municipalitySortingTrend}`}
                                    >
                                        Více
                                    </Button>
                                </Box>
                            </Paper>
                        }
                    </Box>
                </Box>
            </Fade>
            <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                <p>Verze aplikace {APP_VERSION}</p>
            </Box>
        </>
    )
}

export default PublicHomeView
