import { useForm, Controller, type SubmitHandler } from "react-hook-form"

import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { UserRole, type UserRoleFormInput, type User } from "@/types"
import { useUpdateUserRole } from "@/hooks/api/use-users"
import { USER_ROLE_LABEL_MAP } from "@/constants/general"

const UserRoleForm = ({
    user,
    closeCallback,
} : {
    user: User,
    closeCallback?: () => void,
}) => {

    const initialValues = {
        role: user.role ?? UserRole.basic,
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(() => reset(initialValues))

    const mutationCreate = useUpdateUserRole(
        user.id,
        {
            setRequestInProgress,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        }
    )

    const onSubmit: SubmitHandler<UserRoleFormInput> = data => {
        setRequestInProgress(true)
        mutationCreate.mutate(data)
    }

    const filledRole = watch('role')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Upravit roli'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={false}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            error={error}
            setError={setError}
            disableSave={user.role === filledRole}
        >
            <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Rok">
                            {Object.values(UserRole).map(role => role !== UserRole.collectionPointAdmin &&(
                                <MenuItem
                                    key={role}
                                    value={role}
                                >
                                    {USER_ROLE_LABEL_MAP[role]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
        </Form>
    )
}

export default UserRoleForm
