
import { useState } from "react"
import { useAtomValue } from "jotai"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import Alert from '@mui/material/Alert'
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"

import { PersonBox, PersonDetails, PersonValue } from '@/components/person-box'
import { UserRole, type CollectionPointDetail } from '@/types'
import HeadingWithIcon from '@/components/heading-with-icon'
import { selectedCollectionPointIdAtom } from "@/state"
import { useReimportCollectionPointAdmin } from "@/hooks/api/use-collection-point"
import PermissionsGate from "@/components/permissions-gate"


export default function CollectionPointAdmin({
    admin,
}: {
    admin: CollectionPointDetail["admin"]
}) {

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const [success, setSuccess] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)


    const mutation = useReimportCollectionPointAdmin(
        collectionPointId,
        {
            setRequestInProgress,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            },
            onSuccessCallback(message) {
                setSuccess(message)
            }
        },
    )

    const handleClickReimport = () => {
        setRequestInProgress(true)
        setError(null)
        setSuccess(null)
        mutation.mutate()
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}>
            <HeadingWithIcon
                text="Správce svozového místa"
                icon={<PermContactCalendarIcon />}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '1rem',
                }}
            >
                {[admin].map(({ name, mobile, email }, index) => (
                    <PersonBox key={index} fullName={name}>
                        <PersonDetails>
                            <PersonValue type="email" value={email} />
                            <PersonValue type="phone" value={mobile} />
                        </PersonDetails>
                    </PersonBox>
                ))}
            </Box>
            {(!success && !error) ?
                <PermissionsGate userRoles={[ UserRole.admin, UserRole.municipalityOfficial ]}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="outlined"
                            color="terciary"
                            onClick={() => handleClickReimport()}
                            disabled={requestInProgress}
                        >
                            Aktualizovat správce z EOKA
                        </Button>
                    </Box>
                </PermissionsGate>
                :
                <Box>
                    {success &&
                        <Alert
                            severity="success"
                            sx={(theme) => ({
                                flexGrow: 1,
                                margin: theme.spacing(1, 0),
                            })}
                            action={
                                <IconButton
                                    color="success"
                                    size="small"
                                    onClick={() => {
                                        setSuccess(null)
                                    }}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            }
                        >
                            {success}
                        </Alert>
                    }
                    {error &&
                        <Alert
                            severity="error"
                            sx={(theme) => ({
                                flexGrow: 1,
                                margin: theme.spacing(1, 0),
                            })}
                            action={
                                <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                        setError(null)
                                    }}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            }
                        >
                            {error}
                        </Alert>
                    }
                </Box>
            }
        </Box>
    )
}
