import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'
import { Helmet } from 'react-helmet-async'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Alert from '@mui/material/Alert'

import CustomPaper from '@/components/custom-paper'
import { selectedCollectionPointIdAtom } from '@/state'
import CollectionCompanyLoader from '@/wrappers/collection-company-loader'
import CreateDestroyedBinReportForm from '@/components/forms/create-destroyed-bin-report'
import CreateUncollectedBinReportForm from '@/components/forms/create-uncollected-bin-report'
import CollectionPointBinsLoader from '@/wrappers/collection-point-bins-loader'
import CardButton from '@/components/card-button'
import CollectionPointMessageListCollectionCompany from '@/components/collection-point/message-list-collection-company'

function CollectionClaimView() {

    const title = 'Reklamace svozu'

    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const [destroyedBinReport, setDestroyedBinReport] = useState<boolean>(false)
    const [uncollectedBinReport, setUncollectedBinReport] = useState<boolean>(false)
    const [success, setSuccess] = useState(false)

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                    gap: 3,
                }}>
                    <Box sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                            xl: '65%'
                        },
                    }}>
                        <Typography variant="h2" component="h1" mb={2.5}>
                            {title}
                        </Typography>
                        {collectionPointId ?
                            <>
                                {(!destroyedBinReport && !uncollectedBinReport) ?
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 1,
                                        flexWrap: 'wrap',
                                    }}>
                                        <CardButton
                                            title='Nádoba nebyla vysypána'
                                            icon={<RestoreFromTrashIcon fontSize='large' />}
                                            onClick={() => setUncollectedBinReport(true)}
                                        />
                                        <CardButton
                                            title='Nádoba byla při výsypu zničena'
                                            icon={<DeleteForeverIcon fontSize='large' />}
                                            onClick={() => setDestroyedBinReport(true)}
                                        />
                                    </Box>
                                    :
                                    <CollectionCompanyLoader>
                                        {collectionCompany => (
                                            <CollectionPointBinsLoader>
                                                {bins => (
                                                    <>
                                                        {destroyedBinReport &&
                                                            <CreateDestroyedBinReportForm
                                                                collectionCompany={collectionCompany}
                                                                bins={bins}
                                                                closeCallback={() => setDestroyedBinReport(false)}
                                                                onSuccessCallback={() => setSuccess(true)}
                                                            />
                                                        }
                                                        {uncollectedBinReport &&
                                                            <CreateUncollectedBinReportForm
                                                                collectionCompany={collectionCompany}
                                                                bins={bins}
                                                                closeCallback={() => setUncollectedBinReport(false)}
                                                                onSuccessCallback={() => setSuccess(true)}
                                                            />
                                                        }
                                                    </>
                                                )}
                                            </CollectionPointBinsLoader>
                                        )}
                                    </CollectionCompanyLoader>
                                }
                                {success &&
                                    <Alert
                                        severity="success"
                                        sx={(theme) => ({
                                            flexGrow: 1,
                                            margin: theme.spacing(1, 0),
                                        })}
                                        onClose={() => setSuccess(false)}
                                    >
                                        Reklamace odeslána.
                                    </Alert>
                                }
                            </>
                            :
                            <Box>
                                Vyberte prosím konkrétní svozové místo výše.
                            </Box>
                        }
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                            xl: '35%'
                        },
                        pl: {
                            xs: 0,
                            lg: 3,
                        },
                        pt: {
                            xs: 3,
                            lg: 0,
                        },
                        borderLeft: {
                            xs: '0px',
                            lg: `1px solid ${theme.palette.customGrey.divider}`,
                        },
                        borderTop: {
                            xs: `1px solid ${theme.palette.customGrey.divider}`,
                            lg: '0px',
                        },
                    })}>
                        <CollectionCompanyLoader>
                            {collectionCompany => (
                                <CollectionPointMessageListCollectionCompany collectionCompanyId={collectionCompany.id}/>
                            )}
                        </CollectionCompanyLoader>
                    </Box>
                </Box>
            </CustomPaper>
        </>
    )
}

export default CollectionClaimView
