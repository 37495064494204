import { useForm, type SubmitHandler } from "react-hook-form"
import Box from '@mui/material/Box'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import {
    type EkokomBonus,
    type EkokomBonusUpsertFormInput,
    EkokomBonusType,
    type MunicipalitySize,
} from "@/types"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import { convertFromCents } from "@/helpers"
import { useUpsertEkokomBonus } from "@/hooks/api/use-ekokom-bonus"

const materialKeys = [
    "paper",
    "plastic",
    "glass_mixed",
    "glass_clear",
    "liquid_paperboard_single",
    "liquid_paperboard_combined",
    "metal_single",
    "metal_combined",
    "wood",
]

const BONUS_LABEL_MAP = {
    [EkokomBonusType.basic]: 'Základní',
    [EkokomBonusType.collection_yard]: 'Sběrné dvory',
    [EkokomBonusType.other]: 'Ostatní',
}

const EkokomBonusForm = ({
    bonuses,
    closeCallback,
    bonusType,
    year,
    municipalitySizes,
} : {
    bonuses?: Array<EkokomBonus>,
    closeCallback?: () => void,
    bonusType: EkokomBonusType,
    year: number
    municipalitySizes: Array<MunicipalitySize>,
}) => {

    const formDataId = `ekokomBonusForm-${bonusType}-${year}-v${APP_VERSION}`

    const mappedValues = {}

    for (const size of municipalitySizes) {
        const ekokomBonus = bonuses.find(bonus => bonus.municipality_size_id === size.id)
        for (const key of materialKeys) {
            let value = ''
            if (ekokomBonus) {
                value = ekokomBonus[key+'_cents'] != null ? convertFromCents(ekokomBonus[key+'_cents']).toString() : '0'
            }
            mappedValues[`${key}${size.id}`] = value
        }
    }

    const initialValues: EkokomBonusUpsertFormInput = ({
        year,
        bonusType,
        ...mappedValues,
    } as EkokomBonusUpsertFormInput)

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useUpsertEkokomBonus(
        municipalitySizes,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<EkokomBonusUpsertFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={`Upravit hodnoty pro: ${BONUS_LABEL_MAP[bonusType]}`}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            error={error}
            setError={setError}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowX: 'auto',
            }}>
                <table style={{
                    minWidth: 1000,
                }}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 120, fontWeight: 500, fontSize: '15px'  }}>Velikost obce</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Papír</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Plast</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Směsné sklo</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Čiré sklo</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Nápoj. kartony (samostatné)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Nápoj. kartony (směs)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Kov (samostatné)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Kov (směs)</th>
                            <th style={{ fontWeight: 500, fontSize: '15px' }}>Dřevo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {municipalitySizes.map(size => (
                            <tr key={size.id}>
                                <th style={{fontWeight: 500, fontSize: '15px'}}>{size ? `${size?.citizens_count_gte} - ${size?.citizens_count_lt}` : "Velikost neurčena"}</th>
                                {materialKeys.map(key => (
                                    <td key={key}>
                                        <RHFNumberTextfield
                                            name={(key+size.id) as keyof EkokomBonusUpsertFormInput}
                                            control={control}
                                            thousandSeparator
                                            disallowNegative
                                            size="small"
                                            required
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </Form>
    )
}

export default EkokomBonusForm
