import { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import type {
    MunicipalitySize,
} from '@/types'
import { useEkokomStats } from '@/hooks/api/use-ekokom-stats'
import EkokomStatsByMunicipalitySizeForm from '../forms/ekokom-stats-by-municipality-size'


function EkokomStatsListByMunicipalitySize({
    year,
    municipalitySizes,
}: {
    year: number,
    municipalitySizes: Array<MunicipalitySize>,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const { status, data, error, isPlaceholderData } = useEkokomStats(year)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst EKOKOM statistiky dle velikosti obce pro rok ${year}`}
        />
    }

    const sortedAndFilteredData = data.filter(item => item.municipality_size_id).sort((a, b) => a.id - b.id)

    return (
        <Box px={2.5}>
            {edit ?
                <EkokomStatsByMunicipalitySizeForm
                    year={year}
                    stats={sortedAndFilteredData}
                    municipalitySizes={municipalitySizes}
                    closeCallback={() => setEdit(false)}
                />
                :
                <>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        mb: 1,
                    }}>
                        <Typography variant="h4" component="h2" flexGrow={1}>
                            Dle velikosti obce
                        </Typography>
                        <Box>
                            <Tooltip title="Upravit">
                                <IconButton
                                    color='terciary'
                                    onClick={() => setEdit(true)}
                                >
                                    <EditRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    {sortedAndFilteredData.length === 0 ?
                        <Box px={2.5}>
                            Zatím nejsou vyplněné žádné EKOKOM statistiky.
                        </Box>
                    :
                        <Box sx={(theme) => ({
                            borderRadius: 2.5,
                            border: `1px solid ${theme.palette.divider}`,
                            overflowX: 'auto',
                        })}>
                            <Table
                                aria-label="Ekokom statistiky"
                                sx={{
                                    minWidth: 600,
                                    '& td, & th': {
                                        paddingY: 1.5,
                                    },
                                }}
                                size='small'
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: 3 }}>Velikost obce</TableCell>
                                        <TableCell>Směsný (SKO)</TableCell>
                                        <TableCell>Tříděný</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedAndFilteredData.map(row => {
                                        const municipalitySize = municipalitySizes.find(size => size.id === Number(row.municipality_size_id))
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                                    {municipalitySize ? `${municipalitySize?.citizens_count_gte} - ${municipalitySize?.citizens_count_lt}` : "Velikost neurčena"}
                                                </TableCell>
                                                <TableCell>
                                                    {row.mixed_weight_per_citizen}
                                                </TableCell>
                                                <TableCell>
                                                    {row.sorted_weight_per_citizen}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    }
                </>
            }
            <Box minHeight={4}>
                {isPlaceholderData &&
                    <LinearProgress />
                }
            </Box>
        </Box>
    )
}

export default EkokomStatsListByMunicipalitySize
