import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'
import Skeleton from '@mui/material/Skeleton'

import ErrorBox from "@/components/error-box"
import PermissionsGate from '@/components/permissions-gate'
import { type CommunicationThread, UserRole } from '@/types'
import { useCommunication } from '@/hooks/api/use-communication'
import CommunicationThreadMessage from '@/components/communication/thread-message'
import CommunicationThreadMessageSend from '@/components/communication/thread-message-send'
import CommunicationActions from './actions'
import ArchiveDialog from './archive-dialog'
import ResolveDialog from './resolve-dialog'
import { ROUTE_SEGMENTS } from '@/constants/general'
import CommunicationThreadStatus from './thread-status'
import { getDaysPassed, printHumanDateTime } from '@/helpers'

function CommunicationThreadDetail({
    communicationId,
    full,
}: {
    communicationId: number,
    full?: boolean,
}) {

    const navigate = useNavigate()

    // Action dialogs
    const [resolve, setResolve] = useState<CommunicationThread | null>(null)
    const [archive, setArchive] = useState<CommunicationThread | null>(null)

    const archivationSuccessCallback = () => {
        setArchive(null)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.communication}`)
    }
    
    const { data, error } = useCommunication(communicationId)

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst detail komunikace ID ${communicationId}`}
        />
    }

    const isLoaded = !!data

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
        }}>
            {full &&
                <Box>
                    <Typography variant="h4" component="h2" mb={2}>
                        {isLoaded ? data.communication_type.name : <Skeleton />}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                    }}>
                        <Box sx={{ flexBasis: '45%', color: 'customGrey.tableText' }}>
                            <Typography variant="h5" component="h3">
                                Stav
                            </Typography>
                            {isLoaded ?
                                <CommunicationThreadStatus communicationThread={data} />
                                :
                                <Skeleton />
                        }
                        </Box>
                        <Box sx={{ flexBasis: '55%', color: 'customGrey.tableText' }}>
                            <Typography variant="h5" component="h3">
                                Odesláno
                            </Typography>
                            <Typography variant="body1" component="p">
                                {isLoaded ? printHumanDateTime(dayjs(data.created_at)) : <Skeleton />}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            }
            <Box sx={{
                display: 'flex',
                gap: 1,
            }}>
                <Box sx={{
                    ...(full ? {
                        flexBasis: '45%',
                    } : {
                        flexGrow: 1,
                    }),
                    color: 'customGrey.tableText',
                }}>
                    <Typography variant="h5" component="h3">
                        ID
                    </Typography>
                    <Typography variant="body1" component="p">
                        {isLoaded ? data.id : <Skeleton />}
                    </Typography>
                </Box>
                <Box sx={{
                    ...(full ? {
                        flexBasis: '55%',
                    } : {
                        flexGrow: 1,
                    }),
                    color: 'customGrey.tableText',
                }}>
                    <Typography variant="h5" component="h3">
                        Aktualizováno
                    </Typography>
                    <Typography variant="body1" component="p">
                        {isLoaded ? printHumanDateTime(dayjs(data.updated_at)) : <Skeleton />}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" component="h3" sx={{ color: 'customGrey.tableText', mb: 1 }}>
                    Zprávy ({isLoaded ? data.messages.length : '-'})
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                }}>
                    {isLoaded ?
                        <>
                            {data.messages.sort((a, b) => a.id - b.id).map(message => (
                                <CommunicationThreadMessage key={message.id} message={message} />
                            ))}
                            {data.closed_at ?
                                <Alert severity="success">
                                    Požadavek byl uzavřen {dayjs(data.closed_at).format('D. M. YYYY HH:mm')} ({getDaysPassed(dayjs(data.closed_at))})
                                </Alert>
                                :
                                <>
                                    <PermissionsGate
                                        userRoles={[
                                            UserRole.admin,
                                            UserRole.municipalityOfficial,
                                            UserRole.eokoDataAdmin,
                                            UserRole.collectionCompany
                                        ]}
                                    >
                                        <CommunicationThreadMessageSend communicationId={communicationId} authority/>
                                    </PermissionsGate>
                                    <PermissionsGate
                                        userRoles={[
                                            UserRole.collectionPointAdmin,
                                            UserRole.resident,
                                        ]}
                                    >
                                        <CommunicationThreadMessageSend communicationId={communicationId}/>
                                    </PermissionsGate>
                                </>
                            }
                        </>
                        :
                        <Box height={162}>
                            <Skeleton />
                        </Box>
                    }
                </Box>
            </Box>
            {isLoaded &&
                <PermissionsGate
                    userRoles={[
                        UserRole.admin,
                        UserRole.municipalityOfficial,
                    ]}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '1rem',
                    }}>
                        <CommunicationActions
                            communicationThread={data}
                            setResolve={setResolve}
                            setArchive={setArchive}
                        />
                    </Box>
                </PermissionsGate>
            }
            <Dialog
                key={'resolve-'+resolve?.id}
                open={!!resolve}
                onClose={() => setResolve(null)}
            >
                <DialogContent>
                     <ResolveDialog
                        communicationId={resolve?.id}
                        handleClose={() => setResolve(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        communicationId={archive?.id}
                        handleClose={() => setArchive(null)}
                        successCallback={() => archivationSuccessCallback()}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default CommunicationThreadDetail
