import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { UserRole } from '@/types'
import { USER_ROLE_LABEL_MAP } from '@/constants/general'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const options = Object.values(UserRole).filter(role => role !== UserRole.collectionPointAdmin)

export default function RoleSelector({
    roles,
    setRoles,
    callback,
    label = 'Kategorie',
}: {
    roles,
    setRoles,
    callback?: () => void,
    label?: string,
}) {

    const handleChange = (event: SelectChangeEvent<typeof roles>) => {
        const {
            target: { value },
        } = event
        setRoles(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
        if (callback) callback()
    }

    return (
        <FormControl sx={{ minWidth: 190, flexGrow: 1 }}>
            <InputLabel id="roles-select-label" size='small'>{label}</InputLabel>
            <Select
                labelId="roles-select-label"
                id="roles-select"
                multiple
                value={roles}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={USER_ROLE_LABEL_MAP[value]} size='small' />
                      ))}
                    </Box>
                  )}
                MenuProps={MenuProps}
                size='small'
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={roles.includes(option)} />
                        <ListItemText primary={USER_ROLE_LABEL_MAP[option]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
