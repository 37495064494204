import { useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { selectedMunicipalityIdAtom } from '@/state'
import { useCategoryCollectionPointCount } from '@/hooks/api/use-municipality'
import type { CollectionPointCategoriesTable } from '@/types'
import CategoryTable from './category-table'

function CategoryOverview({
    year,
    lacksComparison,
}: {
    year: number,
    lacksComparison: boolean,
}) {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useCategoryCollectionPointCount(municipalityId, year)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst přehled kategorií pro rok ${year} a obec s ID ${municipalityId}`}
        />
    }

    const sumLevels = (levels: Record<string, number>): number => {
        return Object.values(levels).reduce((sum, value) => sum + value, 0)
    }

    const primaryMixedWeightLevels = Object.keys(data.active?.mixed_weight_levels).length !== 0 ?
        data.active?.mixed_weight_levels :
        Object.keys(data.in_comparison?.mixed_weight_levels).length !== 0 ?
            data.in_comparison?.mixed_weight_levels :
            null
    const primaryMixedVolumeLevels = Object.keys(data.active?.mixed_volume_levels).length !== 0 ?
        data.active?.mixed_volume_levels :
        Object.keys(data.in_comparison?.mixed_volume_levels).length !== 0 ?
            data.in_comparison?.mixed_volume_levels :
            null
      
    const primaryMixedWeightSum = primaryMixedWeightLevels ? sumLevels(primaryMixedWeightLevels) : 1
    const primaryMixedVolumeSum = primaryMixedVolumeLevels ? sumLevels(primaryMixedVolumeLevels) : 1
    
    const comparisonMixedWeightSum = lacksComparison ? null : sumLevels(data.in_comparison?.mixed_weight_levels)
    const comparisonMixedVolumeSum = lacksComparison ? null : sumLevels(data.in_comparison?.mixed_volume_levels)

    const categories: CollectionPointCategoriesTable[] = Array.from({ length: 5 }, (_, index) => {
        const severity = index + 1
        return {
          text: `Kat.\xa0${severity}`,
          severity,
          color: `categories.${['first', 'second', 'third', 'fourth', 'fifth'][index]}`,
          primary_weight_count: primaryMixedWeightLevels?.[severity] ?? 0,
          primary_weight_percentage: ((primaryMixedWeightLevels?.[severity] || 0)/(primaryMixedWeightSum/100)).toFixed(2),
          primary_volume_count: primaryMixedVolumeLevels?.[severity] ?? 0,
          primary_volume_percentage: ((primaryMixedVolumeLevels?.[severity] || 0)/(primaryMixedVolumeSum/100)).toFixed(2),
          comparison_weight_count: data.in_comparison?.mixed_weight_levels[severity] ?? 0,
          comparison_weight_percentage: ((data.in_comparison?.mixed_weight_levels[severity] || 0)/(comparisonMixedWeightSum/100)).toFixed(2),
          comparison_volume_count: data.in_comparison?.mixed_volume_levels[severity] ?? 0,
          comparison_volume_percentage: ((data.in_comparison?.mixed_volume_levels[severity] || 0)/(comparisonMixedVolumeSum/100)).toFixed(2),
        }
    })

    return (
        <Box sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            flexDirection: {
                xs: 'column',
                xl: 'row',
            },
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
            }}>
                <CategoryTable
                    heading="Počet občanů v kat. dle hmotnosti"
                    categories={categories}
                    attribute="primary_weight"
                />
                <CategoryTable
                    heading="Počet občanů v kat. dle objemu"
                    categories={categories}
                    attribute="primary_volume"
                />
            </Box>
            {!lacksComparison &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: {
                        xs: '120px',
                        sm: '260px',
                        xl: 0,
                    }
                }}>
                    <ArrowRightAltRoundedIcon sx={{
                        color: 'customGrey.secondaryText',
                        transform: {
                            xs: 'rotate(90deg)',
                            xl: 'none',
                        },
                    }} />
                </Box>
            }
            {!lacksComparison &&
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    opacity: 0.8,
                }}>
                    <CategoryTable
                        heading="Počet občanů v kat. dle hmotnosti"
                        categories={categories}
                        attribute="comparison_weight"
                    />
                    <CategoryTable
                        heading="Počet občanů v kat. dle objemu"
                        categories={categories}
                        attribute="comparison_volume"
                    />
                </Box>
            }
        </Box>
    )
}

export default CategoryOverview
