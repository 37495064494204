import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { useSingleCategoryListExport } from '@/hooks/api/use-export'
import { ExportStatus } from '@/types'
import { selectedMunicipalityIdAtom } from '@/state'

function CollectionPointCategoriesExportDownload({
    exportId,
    handleClose,
}: {
    exportId: number,
    handleClose: () => void,
}) {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [keepRefetching, setKeepRefetching] = useState<boolean>(true)
    
    const { status, data, error } = useSingleCategoryListExport(exportId, municipalityId, keepRefetching)

    useEffect(() => (() => {
        if (data?.state === ExportStatus.successful || data?.state === ExportStatus.failed) {
            setKeepRefetching(false)
        }
    }), [data])

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst export s ID ${exportId}`}
        />
    }

    return (
        <Box sx={{
            flexGrow: 1,
            width: '100%',
            pb: 0.5,
        }}>
            {data.state === ExportStatus.processing &&
                <Alert
                    severity="info"
                    icon={<CircularProgress size={20} color="inherit" />}
                >
                    Vytvářím export
                </Alert>
            }
            {data.state === ExportStatus.successful &&
                <Alert
                    severity="success"
                    action={
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            flexWrap: 'wrap',
                        }}>
                            {data.documents[0]?.url &&
                                <Button
                                    color="success"
                                    size="small"
                                    target="_blank"
                                    href={data.documents[0]?.url}
                                    variant='contained'
                                    startIcon={<FileDownloadRoundedIcon />}
                                >
                                    Stáhnout
                                </Button>
                            }
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => handleClose()}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                    }
                >
                    Export vytvořen
                </Alert>
            }
            {data.state === ExportStatus.failed &&
                <Alert
                    severity="error"
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => handleClose()}
                        >
                            Zavřít
                        </Button>
                    }
                >
                    Export se nepodařilo vytvořit
                </Alert>
            }
        </Box>
    )
}

export default CollectionPointCategoriesExportDownload
