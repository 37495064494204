import {
    type Dispatch,
    type SetStateAction,
    useState,
    type MouseEvent,
} from 'react'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import GiteRoundedIcon from '@mui/icons-material/GiteRounded'

import type { CommunicationThread } from '@/types'
import { HISTORY_RETURN_PARAM_KEY, ROUTE_SEGMENTS } from '@/constants/general'
import { selectedCollectionPointIdAtom } from '@/state'

function CommunicationActions({
    communicationThread,
    setResolve,
    setArchive,
    allowDetail,
}: {
    communicationThread: CommunicationThread,
    setResolve: Dispatch<SetStateAction<CommunicationThread>>,
    setArchive: Dispatch<SetStateAction<CommunicationThread>>,
    allowDetail?: boolean,
}) {

    const navigate = useNavigate()
    
    const setCollectionPointId = useSetAtom(selectedCollectionPointIdAtom)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setResolve(null)
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.origin}/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.communication}/${communicationThread.id}`)
    }

    const handleClickDetail = (id: number) => {
            setCollectionPointId(id)
            navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.detail}?${HISTORY_RETURN_PARAM_KEY}=true`)
        }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id="actions-button"
                    aria-label="actions"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    color="terciary"
                    onClick={handleClick}
                    sx={{ backgroundColor: 'editableOrange.main' }}
                >
                    <MoreVertRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="actions-menu"
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e: MouseEvent) => {
                    e?.stopPropagation()
                    handleClose()
                }}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                {allowDetail &&
                    <MenuItem>
                        <ListItemIcon>
                            <VisibilityRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Detail komunikace
                    </MenuItem>
                }
                {communicationThread.household?.id &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            handleClickDetail(communicationThread.household.id)
                        }}
                    >
                        <ListItemIcon>
                            <GiteRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Detail svoz. místa
                    </MenuItem>
                }
                <MenuItem
                     onClick={e => {
                        e.stopPropagation()
                        handleCopyToClipboard()
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <LinkRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Zkopírovat odkaz
                </MenuItem>
                {!communicationThread.closed_at &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            setResolve(communicationThread)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <CheckCircleRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Vyřešeno
                    </MenuItem>
                }
                {communicationThread.closed_at &&
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation()
                            setArchive(communicationThread)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <ArchiveRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Archivovat
                    </MenuItem>
                }
            </Menu>
        </>
    )
}

export default CommunicationActions
