import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useAtomValue } from 'jotai'
import PeopleIcon from '@mui/icons-material/People'
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Unstable_Grid2'

import DetailHeader from '@/components/collection-point/detail/detail-header'
import CollectionPointDetailBins from '@/components/collection-point/detail/waste-containers'
import CollectionPointAdmin from '@/components/collection-point/detail/admin'
// import { Payers } from '@/components/collection-point/detail/payers'
import { useCollectionPoint } from '@/hooks/api/use-collection-point'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { selectedCollectionPointIdAtom, selectedMunicipalityIdAtom } from '@/state'
import HeadingWithIcon from '@/components/heading-with-icon'
import MixedWeightDiscountTarget from './mixed-weight-discount-target'
import MixedVolumeDiscountTarget from './mixed-volume-discount-target'
import ForcedDiscountTarget from './forced-discount-target'
import CreateAttributesChangeRequestForm from '@/components/forms/create-attributes-change-request'

export default function CollectionPointDetailWrapper() {
    
    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)
    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [change, setChange] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const toggleSuccess = () => {
        setSuccess(!success)
    }
    
    const { status, data, error } = useCollectionPoint(collectionPointId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst detail svozového místa s ID ${collectionPointId}`}
        />
    }

    const disabledDiscounts = data.force_discount_cents === 0 || (!data.force_discount_cents && data.mixed_volume_categories_disabled && data.mixed_weight_categories_disabled)
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        }}>
            <Box  sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h2" component="h1" mb={2.5}>
                    Detail svozového místa: {data.code}
                </Typography>
                <DetailHeader collectionPointDetail={data} />
            </Box>
            {!disabledDiscounts &&
                <Box>
                    <HeadingWithIcon
                        text="Slevový cíl"
                        icon={<SavingsRoundedIcon />}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row',
                            },
                            columnGap: 3,
                            rowGap: 1,
                            pt: 2,
                        }}
                    >
                        {data.force_discount_cents ? 
                            <ForcedDiscountTarget amountCents={data.force_discount_cents} />
                            :
                            <>
                                {!data.mixed_weight_categories_disabled &&
                                    <MixedWeightDiscountTarget
                                        collectionPointDetail={data}
                                    />
                                }
                                {!data.mixed_volume_categories_disabled &&
                                    <MixedVolumeDiscountTarget
                                        collectionPointDetail={data}
                                    />
                                }
                            </>
                        }
                    </Box>
                </Box>
            }
            <CollectionPointDetailBins bins={data.bins} />
            <Grid container rowSpacing={4} columnSpacing={6}>
                <Grid xs={12} sm={6}>
                    <CollectionPointAdmin admin={data.admin}/>
                </Grid>
                <Grid xs={12} sm={6}>
                    {/* <Payers /> */}
                    <HeadingWithIcon
                        text="Celkový počet poplatníků"
                        icon={<PeopleIcon />}
                    />
                    <Box pt={1.5}>{data.number_of_members ? data.number_of_members : 'Zatím nedoplněno v EOKU'}</Box>
                </Grid>
            </Grid>
            <Container sx={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'center',
            }} maxWidth="sm">
                {change ?
                    <Box flexGrow={1} minHeight={300}>
                        {success ? 
                            <Alert
                                severity="success"
                                sx={(theme) => ({
                                    flexGrow: 1,
                                    margin: theme.spacing(1, 0),
                                })}
                                action={
                                    <Button
                                        color="success"
                                        size="small"
                                        onClick={() => {
                                            toggleSuccess()
                                            setChange(false)
                                        }}
                                    >
                                        Zavřít
                                    </Button>
                                }
                            >
                                Žádost o změnu úspěšně odeslána.
                            </Alert>
                            :
                            <CreateAttributesChangeRequestForm
                                municipalityId={municipalityId}
                                toggleSuccess={toggleSuccess}
                                closeCallback={() => setChange(false)}
                            />
                        }
                    </Box>
                    :
                    <Button
                        variant="contained"
                        color="terciary"
                        onClick={() => setChange(true)}
                    >
                        Nahlásit změnu
                    </Button>
                }
            </Container>
        </Box>
    )
}
