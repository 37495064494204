import { useState } from 'react'
import { useSetAtom } from 'jotai'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useMunicipalityScores } from '@/hooks/api/use-municipality-scores'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useMediaQuery } from '@mui/material'
import { selectedMunicipalityIdAtom } from '@/state'

const getMedalColors = (index: number): {
    backgroundColor: string,
    border: string,
    color?: string,
} => {
    if (index === 0) return {
        backgroundColor: '#F0D545',
        border: '1px solid #D5B508',
        color: 'white',
    }
    if (index === 1) return {
        backgroundColor: '#CBCBCB',
        border: '1px solid #989898',
        color: 'white',
    }
    if (index === 2) return {
        backgroundColor: '#B17A12',
        border: '1px solid #815502',
        color: 'white',
    }
    return {
        backgroundColor: '#B5DDAE',
        border: '1px solid #B5DDAE',
    }
}

const Scoreboard = () => {

    const [expand, setExpand] = useState<boolean>(false)

    const fitsLarge = useMediaQuery('(min-width:420px)')

    const setMunicipalityId = useSetAtom(selectedMunicipalityIdAtom)

    const { status, data, error } = useMunicipalityScores(true)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message="Nepovedlo se načíst porovnání obcí"
        />
    }

    if (data.length === 0) {
        return null
    }

    const weightPercentage = Number(data[data.length-1].mixed_weight_per_citizen)/100

    const scores = expand ? data : data.slice(0, 3)
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            mb: 5,
        }}>
            <Typography variant="h4" component="h2" color="primary.dark" textAlign="center">
                Nejlépe třídí
            </Typography>
            {scores.map((score, index) => (
                <Box
                    key={score.id}
                    onClick={() => setMunicipalityId(score.municipality.id)}
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover .hover-label': {
                            color: 'primary.dark',
                        },
                    }}
                >
                    <Box sx={{
                        width: 32,
                        height: 32,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        fontWeight: 700,
                        fontSize: '1.1rem',
                        ...getMedalColors(index),
                    }}>
                        {index+1}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        gap: 0.6,
                    }}>
                        <Typography variant="h4" component="div" className="hover-label">
                            {score.municipality?.name}
                        </Typography>
                        <Box sx={{
                            position: 'relative',
                            height: '8px',
                            borderRadius: '4px',
                            backgroundColor: '#A6D79D',
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                height: '8px',
                                borderRadius: '4px',
                                top: 0,
                                left: 0,
                                width: `${Number(score.mixed_weight_per_citizen)/weightPercentage}%`,
                                backgroundColor: '#7F8386',
                            }}/>
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box display="flex" justifyContent="center" pt={1}>
                <Button
                    color="primary"
                    variant="outlined"
                    size={fitsLarge ? 'medium' : 'small'}
                    onClick={() => setExpand(!expand)}
                >
                    {expand ? 'Méně' : 'Více'}
                </Button>
            </Box>
        </Box>
    )
}

export default Scoreboard
