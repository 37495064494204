import { DOM_NODE_ID } from '@/constants/general'
import {
    type ErrorResponse,
    type MonthlyMunicipalityStats,
    type MunicipalityStatsByVolume,
    type TotalMunicipalityStats,
    type Coordinates,
    UserRole,
    WasteTypeCodes,
    type CollectedWasteStat,
} from '@/types'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

export function convertFromCents(price: number): number {  
    if (!price) {
        return 0
    }
    return Number.parseFloat((price/100).toFixed(2))
}

export function convertToCents(price: number | string | undefined): number {  
    if (!price) {
        return 0
    }
    if (typeof price === 'string') {
        return Math.round(Number.parseFloat(price)*100)
    }
    return Math.round(price*100)
}

export function isErrorResponse(data): data is ErrorResponse {
    return data && typeof data.error === 'string';
}

export function getUserRole(): UserRole {
    const domNode = document.getElementById(DOM_NODE_ID)
    const userRole = domNode?.getAttribute('user-role')

    if (userRole && Object.values(UserRole).includes(userRole as UserRole)) {
        return userRole as UserRole
    }

    return UserRole.basic
}

export function getUserId(): string | null {
    const domNode = document.getElementById(DOM_NODE_ID)
    const userId = domNode?.getAttribute('user-id')

    return userId ? userId : null
}

export function getUserMunicipalityId(): number | null {
    const domNode = document.getElementById(DOM_NODE_ID)
    const municipalityId = domNode?.getAttribute('user-municipality-id')

    return municipalityId ? Number(municipalityId) : null
}

export function printPrice(price: number | undefined, printCurrency = true): string {  
    if (!price) {
        return '0'+(printCurrency ? '\xa0Kč' : '')
    }
    return price.toLocaleString('en', { useGrouping: true }).replace(/,/g, '\xa0')+(printCurrency ? '\xa0Kč' : '')
}

export function roundPrice(price: number | string): number {
    if (typeof price === 'string') {
        return Math.round(Number.parseFloat(price))
    }
    return Math.round(price)
}

export function getMidpointDate(
    date1: Dayjs,
    date2: Dayjs
): {
    midpointDate: Dayjs,
    totalMonths: number,
} {
    // Determine the earlier and later dates
    const earlierDate = date1.isBefore(date2) ? date1 : date2
    const laterDate = date1.isBefore(date2) ? date2 : date1
  
    // Calculate the total number of months between the two dates
    const totalMonths = laterDate.diff(earlierDate, 'months')+1
  
    // Calculate the midpoint by adding half of the total months to the earlier date
    const midpointDate = earlierDate.add(totalMonths / 2, 'months')
  
    return { midpointDate, totalMonths }
}

export const calculateMonthlyAndTotalMunicipalityStats = (
    data: MunicipalityStatsByVolume,
    otherData?: Array<CollectedWasteStat>,
): {
    monthlyData: MonthlyMunicipalityStats[],
    totalData: TotalMunicipalityStats,
} => {
    const monthsMap: { [key: string]: MonthlyMunicipalityStats } = {}
    const total = {
        mixed_weight: 0,
        bio_weight: 0,
        paper_weight: 0,
        plastic_weight: 0,
        glass_weight: 0,
        other_recycling_weight: 0,
        all_weight: 0,
        recycling_weight: 0,
        avg_mixed_ratio: 0,
        mixed_bin_dumps_count: 0,
        bio_bin_dumps_count: 0,
        paper_bin_dumps_count: 0,
        plastic_bin_dumps_count: 0,
        glass_bin_dumps_count: 0,
        all_bin_dumps_count: 0,
        recycling_bin_dumps_count: 0,
        citizens_count: data.citizens_count ? data.citizens_count : 1,
    }

    for (const entry of data.stats) {
        const { month, material, corrected_weight, corrected_dumps_count } = entry
        const [year, monthNumber] = month.split('-').map(Number)

        const key = `${year}-${monthNumber}`

        // Initialize the object for the first time this key (month/year) is encountered
        if (!monthsMap[key]) {
            monthsMap[key] = {
                id: key,
                year,
                month: monthNumber,
                mixed_weight: 0,
                bio_weight: 0,
                paper_weight: 0,
                plastic_weight: 0,
                glass_weight: 0,
                other_recycling_weight: 0,
                all_weight: 0,
                recycling_weight: 0,
                avg_mixed_ratio: 0,
                mixed_bin_dumps_count: 0,
                bio_bin_dumps_count: 0,
                paper_bin_dumps_count: 0,
                plastic_bin_dumps_count: 0,
                glass_bin_dumps_count: 0,
                all_bin_dumps_count: 0,
                recycling_bin_dumps_count: 0,
                citizens_count: data.citizens_count ? data.citizens_count : 1,
            }
        }

        const stats = monthsMap[key]

        // Add weight and dump count to the respective material
        stats.all_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
        stats.all_weight += Number.parseInt(corrected_weight, 10)
        total.all_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
        total.all_weight += Number.parseInt(corrected_weight, 10)

        switch (material) {
            case WasteTypeCodes.mixed:
                stats.mixed_weight += Number.parseInt(corrected_weight, 10)
                stats.mixed_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                total.mixed_weight += Number.parseInt(corrected_weight, 10)
                total.mixed_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                break
            case WasteTypeCodes.bio:
                stats.bio_weight += Number.parseInt(corrected_weight, 10)
                stats.bio_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                total.bio_weight += Number.parseInt(corrected_weight, 10)
                total.bio_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                break
            case WasteTypeCodes.paper:
                stats.paper_weight += Number.parseInt(corrected_weight, 10)
                stats.paper_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                total.paper_weight += Number.parseInt(corrected_weight, 10)
                total.paper_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                break
            case WasteTypeCodes.plastic:
                stats.plastic_weight += Number.parseInt(corrected_weight, 10)
                stats.plastic_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                total.plastic_weight += Number.parseInt(corrected_weight, 10)
                total.plastic_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                break
            case WasteTypeCodes.glass:
            case WasteTypeCodes.clearGlass:
                stats.glass_weight += Number.parseInt(corrected_weight, 10)
                stats.glass_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                total.glass_weight += Number.parseInt(corrected_weight, 10)
                total.glass_bin_dumps_count += Number.parseInt(corrected_dumps_count, 10)
                break
        }
    }

    if (otherData) {
        for (const entry of otherData) {
            const { year_month } = entry
            const [year, monthNumber] = year_month.split('-').map(Number)

            const key = `${year}-${monthNumber}`

            // Initialize the object for the first time this key (month/year) is encountered
            if (!monthsMap[key]) {
                monthsMap[key] = {
                    id: key,
                    year,
                    month: monthNumber,
                    mixed_weight: 0,
                    bio_weight: 0,
                    paper_weight: 0,
                    plastic_weight: 0,
                    glass_weight: 0,
                    other_recycling_weight: 0,
                    all_weight: 0,
                    recycling_weight: 0,
                    avg_mixed_ratio: 0,
                    mixed_bin_dumps_count: 0,
                    bio_bin_dumps_count: 0,
                    paper_bin_dumps_count: 0,
                    plastic_bin_dumps_count: 0,
                    glass_bin_dumps_count: 0,
                    all_bin_dumps_count: 0,
                    recycling_bin_dumps_count: 0,
                    citizens_count: data.citizens_count ? data.citizens_count : 1,
                }
            }

            const stats = monthsMap[key]

            const allMixed = entry.mixed_weight + entry.large_volume_waste_weight

            const otherRecycling = entry.liquid_paperboard_combined_weight + entry.liquid_paperboard_single_weight +
                entry.metal_combined_weight + entry.metal_single_weight + entry.wood_weight + entry.hazardous_waste_weight

            stats.all_weight += allMixed + entry.bio_weight + entry.paper_weight +
                entry.plastic_weight + entry.glass_clear_weight + entry.glass_mixed_weight + otherRecycling
            total.all_weight += allMixed + entry.bio_weight + entry.paper_weight +
                entry.plastic_weight + entry.glass_clear_weight + entry.glass_mixed_weight + otherRecycling

            stats.mixed_weight += allMixed
            total.mixed_weight += allMixed

            stats.bio_weight += entry.bio_weight
            total.bio_weight += entry.bio_weight

            stats.paper_weight += entry.paper_weight
            total.paper_weight += entry.paper_weight

            stats.plastic_weight += entry.plastic_weight
            total.plastic_weight += entry.plastic_weight

            stats.glass_weight += entry.glass_clear_weight + entry.glass_mixed_weight
            total.glass_weight += entry.glass_clear_weight + entry.glass_mixed_weight

            stats.other_recycling_weight += otherRecycling
            total.other_recycling_weight += otherRecycling
        }
    }

    // Calculate avg_mixed_ratio, recycling_weight and recycling_bin_dumps_count for each month/year entry
    for (const stats of Object.values(monthsMap)) {
        stats.avg_mixed_ratio = (stats.mixed_weight / stats.all_weight) * 100
        stats.recycling_weight = (stats.all_weight-stats.mixed_weight)
        stats.recycling_bin_dumps_count = (stats.all_bin_dumps_count-stats.mixed_bin_dumps_count)
    }
    // Calculate the same for total
    total.avg_mixed_ratio = (total.mixed_weight / total.all_weight) * 100
    total.recycling_weight = (total.all_weight-total.mixed_weight)
    total.recycling_bin_dumps_count = (total.all_bin_dumps_count-total.mixed_bin_dumps_count)

    return {
        monthlyData: Object.values(monthsMap),
        totalData: total,
    }
}

export const findCenter = (coordinates: Coordinates[]): { latitude: number; longitude: number } | null => {
    if (coordinates.length === 0) {
        return { latitude: 48.875, longitude: 16.57198528635988 }
    }

    const totalCoords = coordinates.reduce(
        (acc, coord) => {
            acc.latitude += Number.parseFloat(coord.latitude)
            acc.longitude += Number.parseFloat(coord.longitude)
            return acc
        },
        { latitude: 0, longitude: 0 }
    )

    const centerLatitude = totalCoords.latitude / coordinates.length
    const centerLongitude = totalCoords.longitude / coordinates.length

    return { latitude: centerLatitude, longitude: centerLongitude }
}

export const getYearTab = (year: number, years: Array<number>): number => {
    const index = years.findIndex(item => item === year)

    return index ?? 0
}

export const getDaysPassed = (date: Dayjs): string => {
    if (!date) return '-'
    const now = dayjs()
    const daysPassed = now.diff(date, 'day')
    if (daysPassed === 0) return 'dnes'
    if (daysPassed === 1) return 'včera'
    return `před ${daysPassed} dny`
}

export const printHumanDateTime = (date: Dayjs): string => {
    if (!date) return '-'
    const now = dayjs()
    const daysPassed = now.diff(date, 'day')
    if (daysPassed === 0) return 'dnes v '+date.format('H:mm')
    if (daysPassed === 1) return 'včera v '+date.format('H:mm')
    if (daysPassed === 2) return 'předevčírem v '+date.format('H:mm')
    if (date.year() === now.year()) return date.format('D. M. v H:mm')
    return date.format('D. M. YYYY H:mm')
}
