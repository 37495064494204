import dayjs, { type Dayjs } from 'dayjs'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/cs'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'
import Grid from '@mui/material/Unstable_Grid2'

import { WasteTypeIds, type ISODateString } from '@/types'
import {
    WASTE_TYPE_ID_COLOR_MAP,
    WASTE_TYPE_ID_LABEL_MAP,
} from '@/constants/general'
import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { selectedCollectionPointIdAtom } from '@/state'
import { useCollectionPointWeightings } from '@/hooks/api/use-collection-point'

type MonthsMap = {
    [month: number]: {
        [WasteTypeIds.bio]: number | null
        [WasteTypeIds.paper]: number | null
        [WasteTypeIds.plastic]: number | null
        [WasteTypeIds.mixed]: number | null
        [WasteTypeIds.glass]: number | null
    }
}

const generateMonthsMap = (): MonthsMap => {
    const monthsMap: MonthsMap = {}

    for (let month = 1; month <= 12; month++) {
        monthsMap[month] = {
            [WasteTypeIds.bio]: null,
            [WasteTypeIds.paper]: null,
            [WasteTypeIds.plastic]: null,
            [WasteTypeIds.mixed]: null,
            [WasteTypeIds.glass]: null,
        }
    }

    return monthsMap
}

const getLastTwoYears = (endDate): { yearOne: number; yearTwo: number } => {
    const endYear = endDate.year()

    return {
        yearOne: endYear - 1,
        yearTwo: endYear,
    }
}

export default function DisposalTrendMonthlyTrend({
    endDate,
}: {
    endDate: Dayjs
}) {
    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const startOfPreviousYear = endDate.subtract(1, 'year').startOf('year')
    const endOfCurrentYear = endDate.endOf('year')

    const { status, data, error } = useCollectionPointWeightings(
        collectionPointId,
        startOfPreviousYear.format('YYYY-MM-DD') as ISODateString,
        endOfCurrentYear.format('YYYY-MM-DD') as ISODateString
    )

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return (
            <ErrorBox
                error={error}
                message={`Nepovedlo se načíst statistiky výsypu svozového místa ID ${collectionPointId}`}
            />
        )
    }

    const { yearOne, yearTwo } = getLastTwoYears(endDate)

    const yearOneMonthsMap = generateMonthsMap()
    const yearTwoMonthsMap = generateMonthsMap()

    for (const entry of data.bin_weightings) {
        const date = dayjs(entry.time_unit)
        const year = date.year()
        const month = date.month() + 1

        if (year === yearOne) {
            yearOneMonthsMap[month][entry.waste_type_id] += entry.corrected_weight ? Number(entry.corrected_weight) : 0
        } else {
            yearTwoMonthsMap[month][entry.waste_type_id] += entry.corrected_weight ? Number(entry.corrected_weight) : 0
        }
    }

    const accumulatedData = {
        [WasteTypeIds.bio]: [],
        [WasteTypeIds.paper]: [],
        [WasteTypeIds.plastic]: [],
        [WasteTypeIds.mixed]: [],
        [WasteTypeIds.glass]: [],
    }

    const cumulativeSumsYearOne = {
        [WasteTypeIds.bio]: 0,
        [WasteTypeIds.paper]: 0,
        [WasteTypeIds.plastic]: 0,
        [WasteTypeIds.mixed]: 0,
        [WasteTypeIds.glass]: 0,
    }

    const cumulativeSumsYearTwo = {
        [WasteTypeIds.bio]: 0,
        [WasteTypeIds.paper]: 0,
        [WasteTypeIds.plastic]: 0,
        [WasteTypeIds.mixed]: 0,
        [WasteTypeIds.glass]: 0,
    }

    const currentDate = dayjs()

    for (const month of Object.keys(yearOneMonthsMap)) {
        const wasteDataYearOne = yearOneMonthsMap[month]
        const wasteDataYearTwo = yearTwoMonthsMap[month]

        // Accumulate values, treating null as 0
        cumulativeSumsYearOne[WasteTypeIds.bio] += wasteDataYearOne[WasteTypeIds.bio] || 0
        cumulativeSumsYearOne[WasteTypeIds.paper] += wasteDataYearOne[WasteTypeIds.paper] || 0
        cumulativeSumsYearOne[WasteTypeIds.plastic] += wasteDataYearOne[WasteTypeIds.plastic] || 0
        cumulativeSumsYearOne[WasteTypeIds.mixed] += wasteDataYearOne[WasteTypeIds.mixed] || 0
        cumulativeSumsYearOne[WasteTypeIds.glass] += wasteDataYearOne[WasteTypeIds.glass] || 0

        cumulativeSumsYearTwo[WasteTypeIds.bio] += wasteDataYearTwo[WasteTypeIds.bio] || 0
        cumulativeSumsYearTwo[WasteTypeIds.paper] += wasteDataYearTwo[WasteTypeIds.paper] || 0
        cumulativeSumsYearTwo[WasteTypeIds.plastic] += wasteDataYearTwo[WasteTypeIds.plastic] || 0
        cumulativeSumsYearTwo[WasteTypeIds.mixed] += wasteDataYearTwo[WasteTypeIds.mixed] || 0
        cumulativeSumsYearTwo[WasteTypeIds.glass] += wasteDataYearTwo[WasteTypeIds.glass] || 0

        const isBeforeThisMonth = dayjs()
            .year(yearTwo)
            .month(Number(month) - 1)
            .isBefore(currentDate)

        // Add the accumulated data point
        accumulatedData[WasteTypeIds.bio].push({
            yearOne: cumulativeSumsYearOne[WasteTypeIds.bio],
            yearTwo: isBeforeThisMonth ? cumulativeSumsYearTwo[WasteTypeIds.bio] : null,
            month: Number(month),
        })
        accumulatedData[WasteTypeIds.paper].push({
            yearOne: cumulativeSumsYearOne[WasteTypeIds.paper],
            yearTwo: isBeforeThisMonth ? cumulativeSumsYearTwo[WasteTypeIds.paper] : null,
            month: Number(month),
        })
        accumulatedData[WasteTypeIds.plastic].push({
            yearOne: cumulativeSumsYearOne[WasteTypeIds.plastic],
            yearTwo: isBeforeThisMonth ? cumulativeSumsYearTwo[WasteTypeIds.plastic] : null,
            month: Number(month),
        })
        accumulatedData[WasteTypeIds.mixed].push({
            yearOne: cumulativeSumsYearOne[WasteTypeIds.mixed],
            yearTwo: isBeforeThisMonth ? cumulativeSumsYearTwo[WasteTypeIds.mixed] : null,
            month: Number(month),
        })
        accumulatedData[WasteTypeIds.glass].push({
            yearOne: cumulativeSumsYearOne[WasteTypeIds.glass],
            yearTwo: isBeforeThisMonth ? cumulativeSumsYearTwo[WasteTypeIds.glass] : null,
            month: Number(month),
        })
    }

    const isRenderable = (wasteTypeId: number) =>
        cumulativeSumsYearOne[wasteTypeId] !== 0 || cumulativeSumsYearTwo[wasteTypeId] !== 0

    return (
        <Grid container spacing={2}>
            {[WasteTypeIds.mixed, WasteTypeIds.bio, WasteTypeIds.plastic, WasteTypeIds.paper, WasteTypeIds.glass].map(wasteTypeId =>
                isRenderable(wasteTypeId) && (
                    <Grid key={wasteTypeId} xs={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            pt: 3,
                            pb: 2,
                        }}>
                            <Box sx={{
                                width: 14,
                                height: 14,
                                backgroundColor: WASTE_TYPE_ID_COLOR_MAP[wasteTypeId].main,
                                borderRadius: 7,
                            }}/>
                            <Typography variant="h5" component="span">
                                {WASTE_TYPE_ID_LABEL_MAP[wasteTypeId].long}
                            </Typography>
                        </Box>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                width={800}
                                height={400}
                                data={accumulatedData[wasteTypeId]}
                                margin={{
                                    top: 10,
                                    right: 20,
                                    left: 20,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" dy={10}/>
                                <YAxis tickFormatter={(tick) => tick+'\xa0kg'}/>
                                <Tooltip
                                    labelFormatter={tick =>
                                        `${dayjs()
                                            .month(tick - 1)
                                            .locale('cs')
                                            .format('MMMM')} (${tick})`
                                    }
                                    formatter={tick => `${(tick as number).toFixed(2)} kg`}
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="yearOne"
                                    stroke="#BEBEBE"
                                    strokeWidth={2}
                                    name={yearOne.toString()}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="yearTwo"
                                    strokeWidth={2}
                                    stroke={WASTE_TYPE_ID_COLOR_MAP[wasteTypeId].main}
                                    name={yearTwo.toString()}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Grid>
                )
            )}
        </Grid>
    )
}
