import {
    type Dispatch,
    type SetStateAction,
    useState,
} from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import Lightbox from "yet-another-react-lightbox"
import Grid from '@mui/material/Grid'
import "yet-another-react-lightbox/styles.css"
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

import {
    UserRole,
    type IllegalDumpingReport,
    type IllegalDumpingReportPublic,
} from '@/types'
import PermissionsGate from '@/components/permissions-gate'
import { printHumanDateTime } from '@/helpers'


function ReportCard({
    illegalDumpReport,
    setArchiveReport,
}: {
    illegalDumpReport: IllegalDumpingReportPublic | IllegalDumpingReport,
    setArchiveReport: Dispatch<SetStateAction<IllegalDumpingReportPublic>>,
}) {

    const [open, setOpen] = useState<number | null>(null)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            borderRadius: 2,
            overflow: 'hidden',
            backgroundColor: 'reports.background',
            py: 2,
            px: 2.5,
        }}>
            <Box sx={{
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 1.5,
                    alignItems: 'center',
                }}>
                    <AccountCircleRoundedIcon sx={{
                        color: 'reports.icon',
                        fontSize: '2rem',
                    }}/>
                    {'sender_email' in illegalDumpReport &&
                        <Typography variant="body2" component="p" color="reports.icon" sx={{ overflowWrap: 'anywhere' }}>
                            {illegalDumpReport.sender_email}
                        </Typography>
                    }
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}>
                    <Typography variant="body2" component="p" color="reports.icon">
                        {printHumanDateTime(dayjs(illegalDumpReport.created_at))}
                    </Typography>
                    <PermissionsGate userRoles={[ UserRole.admin, UserRole.eokoDataAdmin ]}>
                        <IconButton
                            color='terciary'
                            aria-label="Smazat hlášení"
                            onClick={() => setArchiveReport(illegalDumpReport)}
                            size="small"
                        >
                            <DeleteRoundedIcon />
                        </IconButton>
                    </PermissionsGate>
                </Box>
            </Box>
            <Typography variant="body1" component="p">
                {illegalDumpReport.description}
            </Typography>
            <Grid container spacing={1} pt={1}>
                {illegalDumpReport.documents.map((image, index) => (
                    <Grid item xs={6} sm={4} xl={3} key={index}>
                        <Box
                            sx={{
                                borderRadius: 2,
                                overflow: 'hidden',
                                aspectRatio: 4 / 3,
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(index)}
                        >
                            <img
                                src={image.url}
                                alt={image.filename}
                                style={{
                                    width: '100%',
                                    aspectRatio: 4 / 3,
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Lightbox
                open={open != null}
                close={() => setOpen(null)}
                slides={illegalDumpReport.documents.map(image => ({ src: image.url }))}
                index={open}
            />
        </Box>
    )
}

export default ReportCard
