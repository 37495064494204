import type { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    boxShadow: 'none',
    borderRadius: 10,
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
    },
    maxWidth: 200,
}))

interface CardButtonProps {
    title: string
    description?: string
    icon: ReactNode
    onClick: () => void
}
  
  const CardButton: React.FC<CardButtonProps> = ({ title, description, icon, onClick }) => {
    return (
        <StyledCard
            onClick={onClick}
        >
            <CardContent>
                <Box textAlign="center">
                    {icon}
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    {description && (
                        <Typography variant="body2" color="textSecondary">
                            {description}
                        </Typography>
                    )}
                </Box>
            </CardContent>
        </StyledCard>
    )
  }
  
  export default CardButton
