import { useState, type MouseEvent, Fragment } from 'react'
import { useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Collapse from '@mui/material/Collapse'

import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import type { MunicipalityFinanceMonth, MonthYear, MunicipalityFinanceYearSum } from '@/types'
import { NumericFormat } from 'react-number-format'
import { useMunicipalityFinances } from '@/hooks/api/use-municipality-finances'
import MunicipalityFinanceMonthForm from '../forms/municipality-finance-month'
import ActiveMotivationModelLoader from '@/wrappers/active-motivation-model-loader'
import MunicipalityFinancesIncomeStatementWrapper from './income-statement-wrapper'

type MonthMap = {
    [month: number]: {
        monthYear: MonthYear,
        year: number,
        month: number,
        entry: MunicipalityFinanceMonth,
    }
}

const generateMonthMap = (year: number): MonthMap => {
    const monthMap: MonthMap = {}
    // Iterate over each month (1 to 12)
    for (let month = 1; month <= 12; month++) {
        // Initialize the month object
        monthMap[month] = {
            monthYear: `${year}-${String(month).padStart(2, '0')}` as MonthYear,
            year,
            month,
            entry: null,
        }
    }
    return monthMap
}

function MunicipalityFinancesList({
    year,
}: {
    year: number,
}) {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [edit, setEdit] = useState<MunicipalityFinanceMonth | null>(null)
    const [create, setCreate] = useState<MonthYear | null>(null)

    const handleClick = (_event: MouseEvent<unknown>, month: MonthMap[number]) => {
        if (month.entry) {
            setEdit(month.entry)
        } else {
            setCreate(month.monthYear)
        }
    }

    
    const { status, data, error } = useMunicipalityFinances(
        municipalityId,
        year+'-01' as MonthYear,
        year+'-12' as MonthYear,
    )

    const { data: dataPreviousYear } = useMunicipalityFinances(
        municipalityId,
        (year-1)+'-01' as MonthYear,
        (year-1)+'-12' as MonthYear,
    )

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst údaje o hospodaření obce s ID ${municipalityId}`}
        />
    }

    const monthMap = generateMonthMap(year)

    for (const entry of data) {
        const date = dayjs(entry.year_month)
        const month = date.month()+1

        monthMap[month].entry = entry
    }

    const currentYear = dayjs().year()
    const currentMonth = dayjs().month() + 1

    const yearSum:MunicipalityFinanceYearSum = data.reduce((acc, entry) => {
        acc.bin_collection_expenses += entry.bin_collection_expenses
        acc.collection_yard_expenses += entry.collection_yard_expenses
        acc.other_expenses += entry.other_expenses
        acc.ekokom_income += entry.ekokom_income
        acc.citizen_fee_income += entry.citizen_fee_income
        acc.collection_yard_income += entry.collection_yard_income
        acc.other_income += entry.other_income
        return acc
    }, {
        bin_collection_expenses: 0,
        collection_yard_expenses: 0,
        other_expenses: 0,
        ekokom_income: 0,
        citizen_fee_income: 0,
        collection_yard_income: 0,
        other_income: 0,
    })

    const previousYearSum:MunicipalityFinanceYearSum = dataPreviousYear?.length > 0 ? 
        dataPreviousYear?.reduce((acc, entry) => {
            acc.bin_collection_expenses += entry.bin_collection_expenses
            acc.collection_yard_expenses += entry.collection_yard_expenses
            acc.other_expenses += entry.other_expenses
            acc.ekokom_income += entry.ekokom_income
            acc.citizen_fee_income += entry.citizen_fee_income
            acc.collection_yard_income += entry.collection_yard_income
            acc.other_income += entry.other_income
            return acc
        }, {
            bin_collection_expenses: 0,
            collection_yard_expenses: 0,
            other_expenses: 0,
            ekokom_income: 0,
            citizen_fee_income: 0,
            collection_yard_income: 0,
            other_income: 0,
        })
        :
        null

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto'
        }}>
            <Box maxWidth="md" sx={{
                mb: 4,
                px: 2.5,
            }}>
                <ActiveMotivationModelLoader year={year}>
                    {activeMotivationModel => (
                        <MunicipalityFinancesIncomeStatementWrapper
                            year={year}
                            activeMotivationModel={activeMotivationModel}
                            financeYearSum={yearSum}
                            previousYearSum={previousYearSum}
                        />
                    )}
                </ActiveMotivationModelLoader>
            </Box>
            <Box maxWidth="md" sx={{
                mb: 2,
                px: 2.5,
            }}>
                <Typography variant="h3" component="h2">
                    Skutečné příjmy a výdaje
                </Typography>
            </Box>
            <Table
                aria-label="Roční statistika produkce odpadu"
                sx={{
                    mt: 1,
                    mb: 3,
                    minWidth: 600,
                }}
                size='small'
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: 3, borderBottom: 'none' }}>Měsíc</TableCell>
                        <TableCell colSpan={3} align='center' sx={{ borderBottom: 'none', backgroundColor: 'rgba(255, 229, 231, 0.5)', borderTopLeftRadius: 10 }}>Náklady</TableCell>
                        <TableCell colSpan={4} align='center' sx={{ borderBottom: 'none', backgroundColor: 'rgba(225, 241, 222, 0.5)', borderTopRightRadius: 10 }}>Příjmy</TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}/>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: 3 }}/>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>Nádobový sběr</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>Provoz sběrných dvorů</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>Ostatní</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>EKOKOM</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>Občané</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>Sběrné dvory</TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>Ostatní</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(monthMap).map(month => (
                        <Fragment key={month.monthYear}>
                            <TableRow
                                sx={(theme) => ({
                                    cursor: 'pointer',
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& > td, th': { borderBottom: 'unset' },
                                    ...((edit && edit.id === month.entry?.id || create === month.monthYear) ? {
                                        backgroundColor: theme.palette.customLightYellow.light,
                                    } : {})
                                })}
                                hover
                                onClick={(event) => handleClick(event, month)}
                            >
                                <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                    {dayjs(month.monthYear).locale('cs').format('MMMM YYYY')}
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>
                                    {month.entry?.bin_collection_expenses != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.bin_collection_expenses}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>
                                    {month.entry?.collection_yard_expenses != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.collection_yard_expenses}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)' }}>
                                    {month.entry?.other_expenses != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.other_expenses}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>
                                    {month.entry?.ekokom_income != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.ekokom_income}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>
                                    {month.entry?.citizen_fee_income != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.citizen_fee_income}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>
                                    {month.entry?.collection_yard_income != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.collection_yard_income}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)' }}>
                                    {month.entry?.other_income != null ? 
                                        <Box>
                                            <NumericFormat
                                                displayType="text"
                                                value={month.entry.other_income}
                                                thousandSeparator={'\xa0'}
                                            />
                                            &nbsp;Kč
                                        </Box> :
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                            color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                        }}>
                                            <ErrorOutlineRoundedIcon />
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Úpravy">
                                        <IconButton>
                                            <EditRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ padding: 0 }} colSpan={9}>
                                    <Collapse in={edit && edit.id === month.entry?.id || create === month.monthYear} timeout="auto" unmountOnExit>
                                        <Box sx={(theme) => ({
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 1.5,
                                            p: 1.5,
                                            justifyContent: 'center',
                                            borderTop: `1px solid ${theme.palette.customGrey.divider}`
                                        })}>
                                            {edit && edit.id === month.entry?.id &&
                                                <MunicipalityFinanceMonthForm
                                                    municipalityFinanceMonth={edit}
                                                    closeCallback={() => setEdit(null)}
                                                />
                                            }
                                            {create === month.monthYear &&
                                                <MunicipalityFinanceMonthForm
                                                    yearMonth={create}
                                                    closeCallback={() => setCreate(null)}
                                                />
                                            }
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    ))}
                    <TableRow
                        key="sum"
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        <TableCell component="th" scope="row" sx={{ paddingLeft: 3, py: 2, fontWeight: 500 }}>
                            Celkem
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)', py: 2, fontWeight: 500, borderBottomLeftRadius: 10 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.bin_collection_expenses}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)', py: 2, fontWeight: 500 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.collection_yard_expenses}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(255, 229, 231, 0.5)', py: 2, fontWeight: 500 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.other_expenses}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)', py: 2, fontWeight: 500 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.ekokom_income}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)', py: 2, fontWeight: 500 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.citizen_fee_income}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)', py: 2, fontWeight: 500 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.collection_yard_income}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell sx={{ backgroundColor: 'rgba(225, 241, 222, 0.5)', py: 2, fontWeight: 500, borderBottomRightRadius: 10 }}>
                            <NumericFormat
                                displayType="text"
                                value={yearSum.other_income}
                                thousandSeparator={'\xa0'}
                            />
                            &nbsp;Kč
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}

export default MunicipalityFinancesList
