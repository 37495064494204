import Box from '@mui/material/Box'

import {
    CustomTabsWithPadding,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import MunicipalitySizesLoader from '@/wrappers/municipality-sizes-loader'
import RegionsLoader from '@/wrappers/regions-loader'
import { YEARS_FROM_START_TO_THIS } from '@/constants/general'
import EkokomStatsListByMunicipalitySize from './list-by-municipality-size'
import EkokomStatsListByRegion from './list-by-region'

function EkokomStatsTabs() {

    const { tab, handleTabChange, a11yProps } = useTabs()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabsWithPadding
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="ekokom stats tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {YEARS_FROM_START_TO_THIS.map((year, index) => (
                        <CustomTab key={year} label={year} {...a11yProps(index)} />
                    ))}
                </CustomTabsWithPadding>
            </Box>
            {YEARS_FROM_START_TO_THIS.map((year, index) => (
                <CustomTabPanelSmall key={year} value={tab} index={index}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                    }}>
                        <MunicipalitySizesLoader>
                            {municipalitySizes => {
                                const filteredMunicipalitySizes = municipalitySizes.filter(size => size.municipality_size_type === 'stat')
                                return (
                                    <EkokomStatsListByMunicipalitySize year={year} municipalitySizes={filteredMunicipalitySizes} />
                                )
                            }}
                        </MunicipalitySizesLoader>
                        <RegionsLoader>
                            {regions => (
                                    <EkokomStatsListByRegion year={year} regions={regions} />
                            )}
                        </RegionsLoader>
                    </Box>
                </CustomTabPanelSmall>
            ))}
        </>
    )
}

export default EkokomStatsTabs
