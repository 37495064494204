import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import RichText from '@/components/rich-text'
import { selectedMunicipalityIdAtom } from "@/state"
import { usePublicBinInstructions } from '@/hooks/api/use-public-bin-instruction'
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { UserRole } from '@/types'
import PermissionsGate from '@/components/permissions-gate'
import PublicBinInstructionForm from '../forms/public-bin-instruction'

function LargeContainerMapInfo() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [create, setCreate] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)

    const { status, data, error } = usePublicBinInstructions(municipalityId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst text k veřejným svozovým místům pro obec ID ${municipalityId}`}
        />
    }

    if (data.length === 0) {
        return (
            <PermissionsGate
                userRoles={[
                    UserRole.admin,
                    UserRole.municipalityOfficial,
                ]}
            >
                <Box pb={2} pt={1}>
                    {create ?
                        <PublicBinInstructionForm
                            closeCallback={() => setCreate(false)}
                        />
                        :
                        <Box>
                            <Button
                                variant="contained"
                                color="terciary"
                                startIcon={<AddRoundedIcon />}
                                onClick={() => setCreate(true)}
                            >
                                Přidat text
                            </Button>
                        </Box>
                    }
                </Box>
            </PermissionsGate>
        )
    }

    const publicBinInstruction = data[0]

    return (
        <Box pb={2} pt={1}>
            {edit ?
                <PublicBinInstructionForm
                    publicBinInstruction={publicBinInstruction}
                    closeCallback={() => setEdit(false)}
                />
                :
                <>
                    <RichText content={publicBinInstruction.description} />
                    <PermissionsGate
                        userRoles={[
                            UserRole.admin,
                            UserRole.municipalityOfficial,
                        ]}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '1rem',
                        }}>
                            <Tooltip title="Upravit">
                                <IconButton
                                    color='terciary'
                                    onClick={() => setEdit(true)}
                                    sx={{ backgroundColor: 'editableOrange.main' }}
                                >
                                    <EditRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </PermissionsGate>
                </>
            }
        </Box>
    )
}

export default LargeContainerMapInfo
