import { atom, type PrimitiveAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { getUserMunicipalityId } from './helpers'

const storage = createJSONStorage<number | null>(() => sessionStorage)

const initialMunicipalityId = getUserMunicipalityId()

export const selectedMunicipalityIdAtom = atomWithStorage<number | null>('municipalityId', initialMunicipalityId, storage)

export const selectedCollectionPointIdAtom = atomWithStorage<number | null>('collectionPointId', null, storage)

export const exportIdAtom = atom<number | null>(null) as PrimitiveAtom<number | null>
