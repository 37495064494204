import axios from 'axios'
import dayjs from 'dayjs'
import {
    useQuery,
    keepPreviousData,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import {
    type MonthYear,
    type Municipality,
    type MunicipalityCitizenStats,
    type MunicipalityStatsByVolume,
    type Pagination,
    type CollectionPointCategoryListResponse,
    type MutationOptions,
    type MunicipalityBin,
    type CollectionPointCategoryListFilters,
    type FormMutationOptions,
    type UpdateCollectionPointParticipationFormInput,
    MMParticipation,
    type MunicipalityCollectionCalendarEntry,
    type MunicipalityPublicVisibilityFormInput,
    type CollectionPointCategoryListOrder,
    type CategoryCollectionPointCountResponse,
} from '@/types'
import { convertToCents } from '@/helpers'

export function useMunicipalities(publicList?: boolean) {
    return useQuery({
        queryKey: [
            'municipalities',
            publicList,
        ],
        queryFn: async () => {
            const { data } : { data: Array<Municipality> } = await axios.get(
                '/api/municipality/'+(publicList ? 'public_list' : 'list'),
            )
            return data
        },
        staleTime: 1000*60*5,
    })
}

export function useMunicipalityStats(
    municipalityId: number,
    startDate: MonthYear,
    endDate: MonthYear,
) {
    return useQuery({
        queryKey: ['municipalityStats-' + municipalityId, 'startDate-'+startDate, 'endDate-'+endDate],
        queryFn: async () => {
            const { data } : { data: MunicipalityStatsByVolume } = await axios.get(
                `/api/municipality/stats?municipality_id=${municipalityId
                    }&begins_date=${startDate
                    }&ends_date=${endDate}`,
            )
            return data
        },
        placeholderData: keepPreviousData,
        enabled: !!municipalityId,
    })
}

export function useMunicipalityCitizenStats(municipalityId: number) {
    return useQuery({
        queryKey: ['municipalityCitizenStats-' + municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityCitizenStats> } = await axios.get(
                `/api/municipality/citizen_category_stats?municipality_id=${municipalityId
                    }&year=${dayjs().subtract(1, 'year').format('YYYY')}`,
            )
            return data
        },
    })
}

export function useCollectionPointCategoryList(
    municipalityId: number,
    motivationModelId: number,
    pagination: Pagination,
    year: number,
    filters: CollectionPointCategoryListFilters,
    order: CollectionPointCategoryListOrder,
) {
    return useQuery({
        queryKey: [
            'collectionPointCategoryList',
            municipalityId,
            motivationModelId,
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            'year-'+year,
            'weightCats-'+filters.mixedWeightCategories.join('-'),
            'dumpCats-'+filters.mixedVolumeCategories.join('-'),
            'types-'+filters.types.join('-'),
            'number-'+filters.numberOfMembers,
            'inMotivationProgram-'+filters.inMotivationProgram,
            'addressLike-'+filters.addressLike,
            'adminNameLike-'+filters.adminNameLike,
            'orderAttribute-'+order.orderAttribute,
            'orderDirection-'+order.orderDirection,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()

            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('motivation_model_id', motivationModelId.toString())
            queryParams.append('year', year.toString())
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            //queryParams.append('begins_date', startDate)
            //queryParams.append('ends_date', endDate)
            if (filters.mixedWeightCategories.length > 0) {
                queryParams.append('mixed_weight_category_level', `[${filters.mixedWeightCategories.join(',')}]`)
            }
            if (filters.mixedVolumeCategories.length > 0) {
                queryParams.append('mixed_volume_category_level', `[${filters.mixedVolumeCategories.join(',')}]`)
            }
            if (filters.types.length > 0) {
                queryParams.append('type', JSON.stringify(filters.types))
            }
            if (!Number.isNaN(Number.parseInt(filters.numberOfMembers))) {
                queryParams.append('number_of_members', filters.numberOfMembers)
            }
            if (filters.inMotivationProgram === MMParticipation.yes || filters.inMotivationProgram === MMParticipation.no) {
                queryParams.append('in_motivation_program', filters.inMotivationProgram === MMParticipation.yes ? 'true' : 'false')
            }
            if (filters.addressLike) {
                queryParams.append('address_like', filters.addressLike)
            }
            if (filters.adminNameLike) {
                queryParams.append('admin_name_like', filters.adminNameLike)
            }
            if (order.orderAttribute) {
                queryParams.append('order_attribute', order.orderAttribute)
                queryParams.append('order_direction', order.orderDirection)
            }
            
            const { data } : { data: CollectionPointCategoryListResponse } = await axios.get(
                `/api/municipality/category_list?${queryParams.toString()}`,
            )
            return data
        },
        placeholderData: keepPreviousData,
    })
}

export function useCategoryCollectionPointCount(
    municipalityId: number,
    year: number,
) {
    return useQuery({
        queryKey: [
            'categoryCollectionPointCount',
            municipalityId,
            'year-'+year,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('year', year.toString())
            
            const { data } : { data: CategoryCollectionPointCountResponse } = await axios.get(
                `/api/municipality/category_households_count?${queryParams.toString()}`,
            )
            return data
        },
        placeholderData: keepPreviousData,
    })
}

export const useRefreshHouseholdCategories = (options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: { municipalityId: number, motivationModelId: number, year: number }) => {
            return axios.post(`/api/municipality/refresh_households_categories?municipality_id=${data.municipalityId
                }&motivation_model_id=${data.motivationModelId
                }&year=${data.year 
                }`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionPointCategoryList'] })
            queryClient.invalidateQueries({ queryKey: ['categoryCollectionPointCount'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useRefreshHouseholdStats = (options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: { municipalityId: number }) => {
            return axios.post(`/api/municipality/refresh_households_stats?municipality_id=${data.municipalityId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionPointCategoryList'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export function useMunicipalityPublicBins(municipalityId: number) {
    return useQuery({
        queryKey: ['municipalityPublicBins-' + municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityBin> } = await axios.get(
                `/api/municipality/public_bins?municipality_id=${municipalityId}`,
            )
            return data
        },
        enabled: !!municipalityId,
    })
}

export const useUpdateCollectionPointParticipation = (collectionPointId: number, year: number, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: UpdateCollectionPointParticipationFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('household_id', collectionPointId.toString())
            queryParams.append('year', year.toString())
            queryParams.append('in_motivation_program', data.in_motivation_program ? 'true' : 'false')
            if (data.force_discount) {
                queryParams.append('force_discount_cents', convertToCents(data.force_discount).toString())
            }
            return axios.post(`/api/household_ext/update_in_motivation_program?${queryParams.toString()}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionPointCategoryList'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export function useMunicipalityCollectionCalendar(
    municipalityId: number,
    year: number,
) {
    return useQuery({
        queryKey: ['municipalityCollectionCalendar', municipalityId, 'year-'+year],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityCollectionCalendarEntry> } = await axios.get(
                `/api/municipality/collection_calendar?municipality_id=${municipalityId
                    }&year=${year}`,
            )
            return data
        },
        enabled: !!municipalityId,
    })
}

export const useSetMunicipalityPublicVisibility = (municipalityId: number, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MunicipalityPublicVisibilityFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('visible_on_public', data.visible ? 'true' : 'false')
            return axios.post(
                `/api/municipality/set_visible_on_public?${queryParams.toString()}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['municipalityScores'] })
            queryClient.invalidateQueries({ queryKey: ['municipalities', true] }) // public is true
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
