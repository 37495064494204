import { useForm, type SubmitHandler } from "react-hook-form"
import Box from '@mui/material/Box'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type {
    EkokomStatByRegionUpsertFormInput,
    EkokomStat,
    Region,
} from "@/types"
import RHFNumberTextfield from "@/components/form-fields/rhf-number-textfield"
import { useUpsertEkokomStatsByRegion } from "@/hooks/api/use-ekokom-stats"

const materialKeys = [
    "mixed_weight",
    "sorted_weight",
]

const EkokomStatsByRegionForm = ({
    stats,
    closeCallback,
    year,
    regions,
} : {
    stats?: Array<EkokomStat>,
    closeCallback?: () => void,
    year: number
    regions: Array<Region>,
}) => {

    const formDataId = `ekokomStatsByRegionForm-${year}-v${APP_VERSION}`

    const mappedValues = {}

    for (const region of regions) {
        const ekokomStat = stats.find(stat => stat.region_id === region.id)
        for (const key of materialKeys) {
            let value = ''
            if (ekokomStat) {
                value = ekokomStat[key+'_per_citizen'] != null ? ekokomStat[key+'_per_citizen'] : '0'
            }
            mappedValues[`${key}${region.id}`] = value
        }
    }

    const initialValues: EkokomStatByRegionUpsertFormInput = ({
        year,
        ...mappedValues,
    } as EkokomStatByRegionUpsertFormInput)

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useUpsertEkokomStatsByRegion(
        regions,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<EkokomStatByRegionUpsertFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading="Upravit hodnoty pro statistiky dle kraje"
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            error={error}
            setError={setError}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowX: 'auto',
            }}>
                <table style={{
                    maxWidth: 560,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 160, fontWeight: 500, fontSize: '15px', textAlign: 'left' }}>Kraj</th>
                            <th style={{ minWidth: 140, fontWeight: 500, fontSize: '15px' }}>Směsný (SKO)</th>
                            <th style={{ minWidth: 140, fontWeight: 500, fontSize: '15px' }}>Tříděný</th>
                        </tr>
                    </thead>
                    <tbody>
                        {regions.map(region => (
                            <tr key={region.id}>
                                <th style={{fontWeight: 500, fontSize: '15px', textAlign: 'left'}}>{region ? region.name : "Neurčeno"}</th>
                                {materialKeys.map(key => (
                                    <td key={key}>
                                        <RHFNumberTextfield
                                            name={(key+region.id) as keyof EkokomStatByRegionUpsertFormInput}
                                            control={control}
                                            thousandSeparator
                                            disallowNegative
                                            decimalScale={2}
                                            size="small"
                                            required
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </Form>
    )
}

export default EkokomStatsByRegionForm
