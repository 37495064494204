import { type ChangeEvent, useEffect, useRef, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CircularProgress from '@mui/material/CircularProgress'

export default function SearchField({
    setValue,
    inputValue,
    setInputValue,
    label,
}: {
    setValue: (arg1: string) => void,
    inputValue: string,
    setInputValue: (arg1: string) => void,
    label: string,
}) {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const timeoutIdRef = useRef<null | ReturnType<typeof setTimeout>>(null)

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setInputValue(value)

        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current)
        }

        timeoutIdRef.current = setTimeout(() => {
            setIsLoading(true)
            setValue(value)
        }, 400)
    }

    useEffect(() => {
        if (isLoading) {
            const timer = setTimeout(() => {
                setIsLoading(false)
            }, 500)
    
            return () => {
                clearTimeout(timer)
            }
        }
    }, [isLoading])

    return (
        <FormControl variant="outlined">
            <InputLabel htmlFor={label+'search-field'} size='small'>{label}</InputLabel>
            <OutlinedInput
                id={label+'search-field'}
                type="text"
                startAdornment={
                    <InputAdornment position="start">
                        <SearchRoundedIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear"
                            onClick={() => {
                                setValue('')
                                setInputValue('')
                            }}
                            edge="end"
                            size="small"
                            disabled={!inputValue}
                            sx={{
                                visibility: inputValue ? 'visible' : 'hidden',
                            }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : <CloseRoundedIcon />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
                value={inputValue}
                onChange={handleInputChange}
                size="small"
                autoComplete='off'
                sx={{
                    paddingRight: 1,
                }}
            />
        </FormControl>
    )
}
