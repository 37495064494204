import { useState, Fragment } from 'react'
import { atom, useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import 'leaflet/dist/leaflet.css'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'
import StraightRoundedIcon from '@mui/icons-material/StraightRounded'

import { useIllegalDumps } from '@/hooks/api/use-illegal-dump'
import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import {
    type IllegalDumpPublicList,
    ReportStates,
    type IllegalDumpListFilters,
    type Pagination,
} from '@/types'
import PaginationControls from '@/components/pagination-controls'
import IllegalDumpsActions from './actions'
import StateLabel from '@/components/state-label'
import IllegalDumpInProgressForm from '@/components/forms/illegal-dump-in-progress'
import IllegalDumpResolvedForm from '@/components/forms/illegal-dump-resolved'
import ArchiveDialog from './archive-dialog'
import CustomDumpMarker from '@/components/illegal-dumps/custom-dump-marker'
import type { LatLngBoundsExpression } from 'leaflet'
import { DEFAULT_BOUNDS, HISTORY_RETURN_PARAM_KEY } from '@/constants/general'
import { printHumanDateTime } from '@/helpers'


const initialFilters: IllegalDumpListFilters = {
    sort_by: 'created_at',
    sort_direction: 'DESC',
    state: '',
}

const filtersAtom = atom<IllegalDumpListFilters>(initialFilters)
const showFiltersAtom = atom<boolean>(false)

const paginationAtom = atom<Pagination>({
    limit: 10,
    offset: 0,
})

const columnDefinitions = [
    { label: 'Stav', attribute: null, enableSort: false, state: true },
    { label: 'Lokalita', attribute: null, enableSort: false },
    { label: 'Počet nahlášení', attribute: 'reports_count', enableSort: true },
    { label: 'První nahlášení', attribute: 'created_at', enableSort: true },
    { label: 'Vyřešení', attribute: 'resolved_at', enableSort: true },
]

const STATE_COLOR_MAP = {
    [ReportStates.sent]: 'state.reported',
    [ReportStates.inProgress]: 'state.inProgress',
    [ReportStates.resolved]: 'state.resolved',
}

const getFiltersCount = (filters: IllegalDumpListFilters): number => {
    let count = 0
    if (filters.sort_by !== initialFilters.sort_by) count++
    if (filters.sort_direction !== initialFilters.sort_direction) count++
    if (filters.state !== initialFilters.state) count++
    return count
}

function IllegalDumpList() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const navigate = useNavigate()

    const handleClick = (_event: React.MouseEvent<unknown>, id: number) => {
        navigate(`${id}?${HISTORY_RETURN_PARAM_KEY}=true`)
    }

    const handleMarkerClick = (id: number) => {
        navigate(`${id}?${HISTORY_RETURN_PARAM_KEY}=true`)
    }

    const [hoverDumpId, setHoverDumpId] = useState<number | null>(null)

    // Pagination
    const [pagination, setPagination] = useAtom(paginationAtom)

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    // Filters
    const [filters, setFilters] = useAtom(filtersAtom)
    const [showFilters, setShowFilters] = useAtom(showFiltersAtom)

    const handleResetFilters = () => {
        setFilters(initialFilters)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value as string,
        })
        handleResetPagination()
    }

    const handleClickOrder = (attribute: IllegalDumpListFilters['sort_by']) => {
        if (filters.sort_by !== attribute) {
            setFilters({
                ...filters,
                sort_by: attribute,
                sort_direction: 'ASC',
            })
        } else {
            if (filters.sort_direction === 'ASC') {
                setFilters({
                    ...filters,
                    sort_direction: 'DESC',
                })
            } else {
                setFilters({
                    ...filters,
                    sort_direction: 'ASC',
                })
            }
        }
    }

    const handleToggleStateFilter = () => {
        if (filters.state === '') {
            setFilters({
                ...filters,
                state: ReportStates.sent,
            })
        } else if (filters.state === ReportStates.sent) {
            setFilters({
                ...filters,
                state: ReportStates.inProgress,
            })
        } else if (filters.state === ReportStates.inProgress) {
            setFilters({
                ...filters,
                state: ReportStates.resolved,
            })
        } else {
            setFilters({
                ...filters,
                state: '',
            })
        }
    }

    const filtersCount = getFiltersCount(filters)

    // Action dialogs
    const [stateInProgress, setStateInProgress] = useState<IllegalDumpPublicList | null>(null)
    const [stateResolved, setStateResolved] = useState<IllegalDumpPublicList | null>(null)
    const [archive, setArchive] = useState<IllegalDumpPublicList | null>(null)
    
    const { status, data, error } = useIllegalDumps(municipalityId, pagination, filters)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst seznam hlášení černých skládek obce s ID ${municipalityId}`}
        />
    }

    const reportsWithCoordinates =
        data.illegal_dump ?
            data.illegal_dump?.filter(item => item.latitude && item.longitude)
            : []

    const bounds: LatLngBoundsExpression = reportsWithCoordinates.length > 0 ?
        reportsWithCoordinates.map(report => ([Number.parseFloat(report.latitude), Number.parseFloat(report.longitude)])) :
        DEFAULT_BOUNDS

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                px: 2,
                pt: 1,
                gap: 0.5,
            }}>
                <Button
                    onClick={() => setShowFilters(!showFilters)}
                    startIcon={<FilterAltRoundedIcon />}
                    color={showFilters ? 'primary' : 'terciary'}
                    sx={{
                        backgroundColor: showFilters ? 'customLightGreen.light' : 'customGrey.boxBackgroundLight',
                    }}
                >
                    Filtry
                </Button>
                {!!filtersCount &&
                    <Tooltip title="Resetovat filtry">
                        <Button
                            onClick={() => handleResetFilters()}
                            startIcon={<DoNotDisturbAltRoundedIcon />}
                            sx={{
                                backgroundColor: 'customLightGreen.dark',
                                px: 1,
                            }}
                        >
                            {filtersCount}
                        </Button>
                    </Tooltip>
                }
            </Box>
            <Collapse in={showFilters}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    px: 2.5,
                    gap: 1.5,
                    pb: 2,
                    pt: 1,
                    alignItems: 'center',
                }}>
                    <Box sx={{ minWidth: 140 }}>
                        <FormControl fullWidth>
                            <InputLabel id="state-select-label" size="small">Stav</InputLabel>
                            <Select
                                labelId="state-select-label"
                                id="state-select"
                                value={filters.state}
                                name="state"
                                label="Stav"
                                onChange={handleChange}
                                size="small"
                            >
                                <MenuItem value={''}>-</MenuItem>
                                <MenuItem value={ReportStates.sent}>Nahlášeno</MenuItem>
                                <MenuItem value={ReportStates.inProgress}>V řešení</MenuItem>
                                <MenuItem value={ReportStates.resolved}>Vyřešeno</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 140 }}>
                        <FormControl fullWidth>
                            <InputLabel id="sort_by-select-label" size="small">Řadit dle</InputLabel>
                            <Select
                                labelId="sort_by-select-label"
                                id="sort_by-select"
                                value={filters.sort_by}
                                name="sort_by"
                                label="Řadit dle"
                                onChange={handleChange}
                                size="small"
                            >
                                <MenuItem value={'created_at'}>První nahlášení</MenuItem>
                                <MenuItem value={'reports_count'}>Počet nahlášení</MenuItem>
                                <MenuItem value={'resolved_at'}>Datum vyřešení</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 140 }}>
                        <FormControl fullWidth>
                            <InputLabel id="sort_direction-select-label" size="small">Směr řazení</InputLabel>
                            <Select
                                labelId="sort_direction-select-label"
                                id="sort_direction-select"
                                value={filters.sort_direction}
                                name="sort_direction"
                                label="Směr řazení"
                                onChange={handleChange}
                                size="small"
                            >
                                <MenuItem value={'DESC'}>Sestupně</MenuItem>
                                <MenuItem value={'ASC'}>Vzestupně</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Collapse>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                }
            }}>
                <Box sx={{
                    flexBasis: {
                        xs: '100%',
                        xl: '65%',
                    },
                    pr: {
                        xs: 0,
                        xl: 3,
                    },
                    overflowX: 'auto',
                }}>
                    <Table
                        size="small"
                        aria-label="Seznam hlášení černých skládek"
                        sx={{
                            mt: 1,
                            minWidth: 600,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {columnDefinitions.map(({ label, attribute, enableSort, state }, index) => (
                                    <TableCell
                                        key={index}
                                        onClick={
                                            state ? () => handleToggleStateFilter() :
                                                enableSort ? () => handleClickOrder(attribute) : undefined
                                        }
                                        sx={{
                                            paddingLeft: index === 0 ? 3 : undefined,
                                            ...(enableSort || state) && {
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    opacity: 0.7,
                                                },
                                            },
                                        }}
                                    >
                                        {label}
                                        {enableSort && attribute === filters.sort_by &&
                                            <Box sx={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                pointerEvents: 'none',
                                            }}>
                                                <StraightRoundedIcon sx={{
                                                    transform: filters.sort_direction === 'ASC' ? undefined : 'rotate(180deg)',
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: '-17px',
                                                    color: 'primary.main',
                                                }}/>
                                            </Box>
                                        }
                                        {state && filters.state !== '' &&
                                            <Box sx={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                pointerEvents: 'none',
                                            }}>
                                                <Box sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    position: 'absolute',
                                                    left: 3,
                                                    top: -8,
                                                    backgroundColor: STATE_COLOR_MAP[filters.state],
                                                }}/>
                                            </Box>
                                        }
                                    </TableCell>
                                ))}
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.illegal_dump.map(row => (
                                <Fragment key={row.id}>
                                    <TableRow
                                        key={row.id}
                                        sx={(theme) => ({
                                            cursor: 'pointer',
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            ...(row.id === hoverDumpId ? {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            } : null),
                                            '& > td, th': { borderBottom: 'unset' },
                                            ...((stateInProgress?.id === row.id || stateResolved?.id === row.id) ? {
                                                backgroundColor: theme.palette.customLightYellow.light,
                                            } : {})
                                        })}
                                        onClick={(event) => handleClick(event, row.id)}
                                        onMouseEnter={() => setHoverDumpId(row.id)}
                                        onMouseLeave={() => setHoverDumpId(null)}
                                    >
                                        <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                            <StateLabel state={row.state} />
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Box>{row.address}</Box>
                                                <Box color="customGrey.secondaryText">{row.latitude} {row.longitude}</Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {row.illegal_dumping_reports.length}
                                        </TableCell>
                                        <TableCell>
                                            <Box color="customGrey.secondaryText">{printHumanDateTime(dayjs(row.created_at))}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box color="customGrey.secondaryText">{row.resolved_at ? printHumanDateTime(dayjs(row.resolved_at)) : '-'}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <IllegalDumpsActions
                                                illegalDump={row}
                                                setStateInProgress={setStateInProgress}
                                                setStateResolved={setStateResolved}
                                                setArchive={setArchive}
                                                allowDetail
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell style={{ padding: 0 }} colSpan={9}>
                                        <Collapse in={stateInProgress?.id === row.id || stateResolved?.id === row.id} timeout="auto" unmountOnExit>
                                            <Box sx={(theme) => ({
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 1.5,
                                                p: 1.5,
                                                justifyContent: 'center',
                                                borderTop: `1px solid ${theme.palette.customGrey.divider}`
                                            })}>
                                                {stateInProgress?.id === row.id &&
                                                    <IllegalDumpInProgressForm
                                                        illegalDump={stateInProgress}
                                                        closeCallback={() => setStateInProgress(null)}
                                                    />
                                                }
                                                {stateResolved?.id === row.id &&
                                                    <IllegalDumpResolvedForm
                                                        illegalDump={stateResolved}
                                                        closeCallback={() => setStateResolved(null)}
                                                    />
                                                }
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                    {data.records_count === 0 &&
                        <Box px={2.5} pt={2}>
                            {filters.state === '' ?
                                'Zatím nebyly nahlášeny žádné černé skládky' :
                                'Momentálně zde nejsou žádná hlášení se zvoleným stavem'
                            }
                        </Box>
                    }
                    <PaginationControls
                        pagination={pagination}
                        setPagination={setPagination}
                        currentArrayLength={data.illegal_dump.length}
                        recordsCount={data.records_count}
                    />
                </Box>
                <Box sx={(theme) => ({
                    flexBasis: {
                        xs: '100%',
                        xl: '35%',
                    },
                    pl: {
                        xs: 2,
                        xl: 0,
                    },
                    pr: 2,
                    pt: {
                        xs: 3,
                        xl: 0,
                    },
                    borderTop: {
                        xs: `1px solid ${theme.palette.customGrey.divider}`,
                        xl: '0px',
                    },
                })}>
                    <Box sx={{
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        width: '100%',
                        height: {
                            xs: '50vh',
                            xl: 'calc(100vh - 258px)',
                        },
                        '& .leaflet-container': {
                            width: '100%',
                            height: {
                                xs: '50vh',
                                xl: 'calc(100vh - 258px)',
                            },
                        },
                    }}>
                        <MapContainer
                            key={municipalityId}
                            bounds={bounds}
                            boundsOptions={{padding: [50, 50]}}
                            scrollWheelZoom={true}
                            attributionControl={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <AttributionControl position="bottomright" prefix={false} />
                            {reportsWithCoordinates.map((marker, index) => (
                                <CustomDumpMarker
                                    key={index}
                                    marker={marker}
                                    selectedId={hoverDumpId}
                                    setSelectedId={handleMarkerClick}
                                />
                            ))}
                        </MapContainer>
                    </Box>
                </Box>
            </Box>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        illegalDumpId={archive?.id}
                        handleClose={() => setArchive(null)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default IllegalDumpList
