import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useAtomValue } from 'jotai'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { selectedCollectionPointIdAtom, selectedMunicipalityIdAtom } from '@/state'
import CollectionCompanyLoader from '@/wrappers/collection-company-loader'
import CreateExtraCollectionRequestForm from '@/components/forms/create-extra-collection-request'
import CollectionPointMessageListMunicipality from '@/components/collection-point/message-list-municipality'

function ExtraCollectionView() {

    const title = 'Objednání extra svozu'

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)
    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const [success, setSuccess] = useState<boolean>(false)

    const toggleSuccess = () => {
        setSuccess(!success)
    }
    
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                    gap: 3,
                }}>
                    <Box sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                            xl: '65%'
                        },
                    }}>
                        <Typography variant="h2" component="h1" mb={2.5}>
                        {title}
                        </Typography>
                        {collectionPointId ?
                            <CollectionCompanyLoader>
                                {collectionCompany => (
                                    <>
                                        
                                        {success ? 
                                            <Alert
                                                severity="success"
                                                sx={(theme) => ({
                                                    flexGrow: 1,
                                                    margin: theme.spacing(1, 0),
                                                })}
                                                action={
                                                    <Button
                                                        color="success"
                                                        size="small"
                                                        onClick={() => toggleSuccess()}
                                                    >
                                                        Objednat další
                                                    </Button>
                                                }
                                            >
                                                Objednávka extra svozu úspěšně odeslána.
                                            </Alert>
                                            :
                                            <CreateExtraCollectionRequestForm
                                                collectionCompanyId={collectionCompany.id}
                                                toggleSuccess={toggleSuccess}
                                            />
                                        }
                                    </>
                                )}
                            </CollectionCompanyLoader>
                            :
                            <Box>
                                Vyberte prosím konkrétní svozové místo výše.
                            </Box>
                        }
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                            xl: '35%'
                        },
                        pl: {
                            xs: 0,
                            lg: 3,
                        },
                        pt: {
                            xs: 3,
                            lg: 0,
                        },
                        borderLeft: {
                            xs: '0px',
                            lg: `1px solid ${theme.palette.customGrey.divider}`,
                        },
                        borderTop: {
                            xs: `1px solid ${theme.palette.customGrey.divider}`,
                            lg: '0px',
                        },
                    })}>
                        <CollectionPointMessageListMunicipality municipalityId={municipalityId} />
                    </Box>
                </Box>
            </CustomPaper>
        </>
    )
}

export default ExtraCollectionView
