import axios from 'axios'
import {
    useQuery,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import type {
    MutationOptions,
    MunicipalityScore,
    MunicipalityScoreVisibilityFormInput,
} from '@/types'

export function useMunicipalityScores(visible?: boolean) {
    return useQuery({
        queryKey: [
            'municipalityScores',
        ],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityScore> } = await axios.get(
                `/api/municipality_score/list?${visible != null ?
                    visible ? 'visible=true' : 'visible=false'
                    : ''}`,
            )
            return data
        },
    })
}

export const useSetMunicipalityScoreVisibility = (municipalityId: number, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: MunicipalityScoreVisibilityFormInput) => {            
            const queryParams = new URLSearchParams()
            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('visible', data.visible ? 'true' : 'false')
            return axios.post(
                `/api/municipality_score/set_visibility?${queryParams.toString()}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['municipalityScores'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
