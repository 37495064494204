import Box from '@mui/material/Box'

import {
    CustomTabsWithPadding,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import UserList from './list'

function UsersTabs() {

    const { tab, handleTabChange, a11yProps } = useTabs()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabsWithPadding
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="users tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <CustomTab label="Správci svoz. míst" {...a11yProps(0)} />
                    <CustomTab label="Ostatní uživatelé" {...a11yProps(1)} />
                </CustomTabsWithPadding>
            </Box>
            <CustomTabPanelSmall value={tab} index={0}>
                <UserList showRoles="collectionPointAdmin"/>
            </CustomTabPanelSmall>
            <CustomTabPanelSmall value={tab} index={1}>
                <UserList showRoles="other"/>
            </CustomTabPanelSmall>
        </>
    )
}

export default UsersTabs
