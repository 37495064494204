import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded'

import type { CommunicationMessage } from '@/types'
import { printHumanDateTime } from '@/helpers'

function CommunicationThreadMessage({
    message,
}: {
    message: CommunicationMessage,
}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            padding: message.recipient?.id ? '0 0 0 2rem' : '0 2rem 0 0',
        }}>
            
            <Box sx={{
                p: 1.5,
                borderRadius: 2,
                backgroundColor: message.recipient?.id ? 'messages.authorityBackground' : 'messages.userBackground',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: "0.875rem",
                    mb: 1,
                    color: message.recipient?.id ? 'messages.authorityColor' : 'messages.userColor',
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                    }}>
                        {message.recipient?.id ? 
                            <MapsHomeWorkRoundedIcon sx={{
                                color: 'messages.authorityColor',
                                fontSize: '1.2rem',
                            }}/>
                            :
                            <AccountCircleRoundedIcon sx={{
                                color: 'messages.userColor',
                                fontSize: '1.2rem',
                            }}/>
                        }
                        <Box sx={{ overflowWrap: 'anywhere' }}>
                            {message.sender.email}
                        </Box>
                    </Box>
                    <Box>
                        {printHumanDateTime(dayjs(message.created_at))}
                    </Box>
                </Box>
                <Typography variant="body2" component="div" sx={{whiteSpace: 'pre-wrap'}}>
                    {message.content}
                </Typography>
            </Box>
        </Box>
    )
}

export default CommunicationThreadMessage
