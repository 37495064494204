import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTipTapExtensions from '@/hooks/use-tip-tap-extensions'
import { RichTextReadOnly } from 'mui-tiptap'

export default function RichText({ content }: { content: string | null | undefined }) {

    const extensions = useTipTapExtensions()

    return (
        <>
            {content ?
                <Box minHeight={60} sx={{
                    // Heading overrides - due to clash with custom MUI theme
                    '& .ProseMirror': {
                        '& h1': {
                            fontSize: '24px !important',
                            fontWeight: '500 !important',
                        },
                        '& h2': {
                            fontSize: '22px !important',
                        },
                        '& h3': {
                            fontSize: '20px !important',
                        },
                        '& h4': {
                            fontSize: '18px !important',
                        },
                        '& h5': {
                            fontSize: '16px !important',
                        },
                        '& h6': {
                            fontSize: '15px !important',
                        },
                    },
                }}>
                    <RichTextReadOnly content={content} extensions={extensions} />
                </Box>
                :
                <Typography variant="body2" component="p" minHeight={60}>
                {'-'}
                </Typography>
            }
        </>
    )
}
