import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import ErrorBox from "@/components/error-box"
import {
    type CommunicationListFilters,
    CommunicationTypeCodes,
} from '@/types'
import { useCommunicationTypes } from '@/hooks/api/use-communication-types'

function CommunicationTypeSelect({
    communicationTypeId,
    handleChange,
    collectionClaims,
} : {
    communicationTypeId: CommunicationListFilters["communicationTypeId"],
    handleChange: (event: SelectChangeEvent) => void,
    collectionClaims?: boolean,
}) {
    
    const { data, error } = useCommunicationTypes()

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst typy komunikace'}
        />
    }

    const options = data ? data.filter(item =>
        item.code !== CommunicationTypeCodes.extraCollectionRequest &&
        item.code !== CommunicationTypeCodes.containerRequest &&
        (collectionClaims ? 
            (item.code !== CommunicationTypeCodes.binReplacementRequest &&
                item.code !== CommunicationTypeCodes.attributesChangeRequest)
            :
            (item.code !== CommunicationTypeCodes.uncollectedBinReport &&
                item.code !== CommunicationTypeCodes.destroyedBinReport)
        )
    ) : []

    return (
        <FormControl fullWidth>
            <InputLabel id="type-select-label" size="small">Typ</InputLabel>
            <Select
                labelId="type-select-label"
                id="type-select"
                value={communicationTypeId ? communicationTypeId.toString() : ''}
                name="communicationTypeId"
                label="Typ"
                onChange={handleChange}
                size="small"
            >
                <MenuItem value={''}>-</MenuItem>
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CommunicationTypeSelect
