import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'
import type {
    CommunicationType,
} from '@/types'

export function useCommunicationTypes() {
    return useQuery({
        queryKey: ['communicationTypes'],
        queryFn: async () => {
            const { data } : { data: Array<CommunicationType> } = await axios.get(
                '/api/communication_type/list',
            )
            return data
        },
        staleTime: 1000*60*60
    })
}
