import { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { Fade } from '@mui/material'
import { useMunicipalityScores } from '@/hooks/api/use-municipality-scores'
import MunicipalityVisibilityForm from './forms/municipality-score-visibility'
import MunicipalityPublicVisibilityForm from './forms/municipality-public-visibility'

function ScoreboardManagementList() {

    const [selectedId, setSelectedId] = useState<number | null>(null)
    
    const { status, data, error } = useMunicipalityScores()

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst seznam obcí'}
        />
    }

    const selectedEntry = data.find(entry => entry.id === selectedId)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                },
                pb: 2,
            }}>
                <Box sx={theme => ({
                    flexBasis: {
                        xs: '100%',
                        xl: selectedId ? '70%' : '100%',
                    },
                    transition: theme.transitions.create(['flex-basis', 'padding'], {
                        duration: 200,
                    }),
                    pr: {
                        xs: 0,
                        xl: selectedId ? 3 : 0,
                    },
                    overflowX: 'auto',
                })}>
                    {data.length === 0 ?
                        <Box px={2.5}>
                            Seznam je prázdný
                        </Box>
                    :
                        <Box>
                            <Table
                                size="small"
                                aria-label="Seznam obcí"
                                sx={{
                                    mt: 1,
                                    minWidth: 600,
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: 3 }}>ID</TableCell>
                                        <TableCell>Pořadí</TableCell>
                                        <TableCell>Obec</TableCell>
                                        <TableCell>Kg SKO/občan</TableCell>
                                        <TableCell>Zobrazeno skóre na titulní str.</TableCell>
                                        <TableCell>Je na veřejném portálu</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                ...(row.id === selectedId ? {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                } : null),
                                            }}
                                            onClick={() => setSelectedId(row.id)}
                                            hover
                                        >
                                            <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                               {row.id}
                                            </TableCell>
                                            <TableCell>
                                               {index+1}
                                            </TableCell>
                                            <TableCell>
                                                {row.municipality.name}
                                            </TableCell>
                                            <TableCell>
                                                {row.mixed_weight_per_citizen}
                                            </TableCell>
                                            <TableCell>
                                                <Box p={1} display="flex" gap={1} alignItems="center">
                                                    {row.visible ?
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'success.light',
                                                        }}/>
                                                        :
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'error.light',
                                                        }}/>
                                                    }
                                                    {row.visible ?
                                                        <Box>Ano</Box>
                                                        :
                                                        <Box>Ne</Box>
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box p={1} display="flex" gap={1} alignItems="center">
                                                    {row.municipality.visible_on_public ?
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'success.light',
                                                        }}/>
                                                        :
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'error.light',
                                                        }}/>
                                                    }
                                                    {row.municipality.visible_on_public ?
                                                        <Box>Ano</Box>
                                                        :
                                                        <Box>Ne</Box>
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                            </Table>
                        </Box>
                    }
                </Box>
                <Box sx={(theme) => ({
                    flexBasis: {
                        xs: '100%',
                        xl: selectedId ? '30%' : '0%',
                    },
                    flexShrink: 0,
                    flexGrow: 0,
                    transition: theme.transitions.create(['flex-basis', 'padding'], {
                        duration: 200,
                    }),
                    pl: {
                        xs: 2,
                        xl: 0,
                    },
                    pr: selectedId ? 2 : 0,
                    pt: {
                        xs: 3,
                        xl: 0,
                    },
                    borderTop: {
                        xs: `1px solid ${theme.palette.customGrey.divider}`,
                        xl: '0px',
                    },
                })}>
                    {selectedId &&
                        <Box sx={{
                            pb: {
                                xs: 3,
                                xl: 0,
                            },
                        }}>
                            <Fade in={!!selectedId} style={{ transitionDelay: selectedId ? '200ms' : '0ms' }}>
                                <Box position="relative" py={2} key={selectedEntry.id}>
                                    <Typography variant="h4" component="h2" textAlign="center" mb={2}>
                                        {selectedEntry.municipality.name}
                                    </Typography>
                                    <MunicipalityVisibilityForm
                                        key={selectedEntry.municipality.id+selectedEntry.visible.toString()}
                                        municipalityId={selectedEntry.municipality.id}
                                        municipalityScore={selectedEntry}
                                    />
                                    <Box pt={2} />
                                    <MunicipalityPublicVisibilityForm
                                        key={selectedEntry.municipality.id+selectedEntry.municipality.visible_on_public.toString()}
                                        municipalityId={selectedEntry.municipality.id}
                                        municipalityVisible={selectedEntry.municipality.visible_on_public}
                                    />
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        borderRadius: '1rem',
                                    }}>
                                        <IconButton
                                            onClick={() => setSelectedId(null)}
                                            color="terciary"
                                        >
                                            <CloseRoundedIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default ScoreboardManagementList
