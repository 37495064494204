import TextField, { type BaseTextFieldProps } from "@mui/material/TextField"

interface TextareaProps {
    onChange: (event: { target: { name: string; value: string } }) => void,
    value: string,
    placeholder?: string,
    name?: string,
    label?: string,
    size?: BaseTextFieldProps["size"],
    required?: BaseTextFieldProps["required"],
    minRows?: number,
    maxRows?: number,
}

const Textarea = (
    props: TextareaProps
) => {

    const {
        onChange,
        value,
        placeholder,
        name,
        label,
        size,
        required,
        minRows = 4,
        maxRows = 10,
    } = props

    return (
        <TextField
            id="textarea"
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            name={name}
            label={label}
            size={size}
            required={required}
            autoComplete="off"
            multiline
            minRows={minRows}
            maxRows={maxRows}
        />
    )
}

export default Textarea
