import { atom, useAtom } from 'jotai'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LoopIcon from '@mui/icons-material/Loop'
import EmailIcon from '@mui/icons-material/Email'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import CommunicationThreadAccordion from '@/components/communication/thread-accordion'
import { useCommunicationsCollectionCompany } from '@/hooks/api/use-communication'
import { getUserId } from '@/helpers'
import { Heading } from '@/components/heading'
import type { Pagination } from '@/types'
import PaginationControls from '@/components/pagination-controls'

const paginationAtom = atom<Pagination>({
    limit: 10,
    offset: 0,
})

export default function CollectionPointMessageListCollectionCompany({
    municipalityId,
    collectionCompanyId,
}: {
    municipalityId?: number,
    collectionCompanyId: number,
}) {
    
    const userId = getUserId()

    const [pagination, setPagination] = useAtom(paginationAtom)
    
    const { status, data, error, refetch } = useCommunicationsCollectionCompany({
        initializedById: userId,
        municipalityId: municipalityId ?? undefined,
        collectionCompanyId,
        pagination,
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Heading icon={<EmailIcon />}>
                    Komunikace se svozovou společností
                </Heading>
                <IconButton
                    color="terciary"
                    onClick={() => {
                        refetch()
                    }}
                >
                    <LoopIcon />
                </IconButton>
            </Box>
            {status === 'pending' &&
                <LoadingBox />
            }
            {error &&
                <ErrorBox
                    error={error}
                    message={'Nepovedlo se načíst seznam komunikace'}
                />
            }
            {data &&
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    {data.communication?.length === 0 &&
                        <Box>
                            Seznam je zatím prázdný.
                        </Box>
                    }
                    {data.communication.map((communicationThread, index) => (
                        <CommunicationThreadAccordion
                            key={index}
                            communicationThread={communicationThread}
                        />
                    ))}
                    <PaginationControls
                        pagination={pagination}
                        setPagination={setPagination}
                        currentArrayLength={data.communication.length}
                        recordsCount={data.records_count}
                    />
                </Box>
            }
        </Box>
    )
}
