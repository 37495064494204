import type { AxiosResponse } from 'axios'

export interface MutationOptions {
    closeCallback?: () => void
    onSuccessCallback?: (arg1: AxiosResponse['data']) => void
    setRequestInProgress: (arg1: boolean) => void
    onErrorCallback?: (arg1: string) => void
}

export interface FormMutationOptions extends MutationOptions {
    formDataId: string
}

export interface Pagination {
    limit: number
    offset: number
}

export interface MotivationModelNameFormInput {
    name: string
    year: string
}

export interface MotivationModelDuplicateFormInput {
    name: string
    year: string
}

export interface MotivationModelCollectionFormInput {
    mixed_amount: number | string
    environment_payment: number | string
    bio_amount: number | string
    paper_amount: number | string
    plastic_amount: number | string
    smallAll: number | string
    small110: number | string
    small120: number | string
    small240: number | string
    big1100: number | string
    big1100Glass: number | string
    big1500GlassAndMetal: number | string
    smallExtended: boolean
}

export interface MotivationModelAccountingFormInput {
    nonpaying_citizens_count: number | string
    collection_yard_operation: number | string
    waste_management: number | string
    collection_yard_income: number | string
    other_income: number | string
}

export interface CitizenCategory {
    id: number
    level: number
    discount_amount_cents: number
    value_gte: number
    value_lt: number
}

export interface BinDumpCost {
    id: number
    volume: number
    amount_cents: number
    eoko_waste_type_id: number | null
}

export enum ScenarioTypes {
    pesimistic = 'pesimistic',
    realistic = 'realistic',
    optimistic = 'optimistic',
}

export enum ScenarioImprovementRatios {
    pesimistic = 0,
    realistic = 0.05,
    optimistic = 0.15,
}

export interface MotivationModelListItem {
    id: number
    name: string
    year: number
    active: boolean
    in_comparison: boolean
    mixed_amount_cents: number
    environment_payment_cents: number
    bio_amount_cents: number
    paper_amount_cents: number
    plastic_amount_cents: number
    annual_surcharge_cents: number | null
    next_year_discount_cents: number | null
    citizen_fee_cents: number | null
    citizens_count: number
    nonpaying_citizens_count: number
    collection_yard_operation_cents: number | null
    waste_management_cents: number | null
    collection_yard_income_cents: number | null
    other_income_cents: number | null
    selected_scenario: ScenarioTypes | null
    created_at: string
    updated_at: string
    mixed_weight_categories_disabled: boolean
    mixed_volume_categories_disabled: boolean
}

export interface MotivationModel extends MotivationModelListItem {
    mixed_weight_categories: Array<CitizenCategory>
    mixed_volume_categories: Array<CitizenCategory>
    bin_dump_costs: Array<BinDumpCost>
    mixed_weight_categories_disabled: boolean
    mixed_volume_categories_disabled: boolean
    ekokom_basic_bonus?: UsedEkokomBonus
    ekokom_collection_yard_bonus?: UsedEkokomBonus
    ekokom_other_bonus?: UsedEkokomBonus
}

export interface UsedEkokomBonus {
    id: number
    paper_cents?: number
    plastic_cents?: number
    glass_mixed_cents?: number
    glass_clear_cents?: number
    liquid_paperboard_single_cents?: number
    liquid_paperboard_combined_cents?: number
    metal_single_cents?: number
    metal_combined_cents?: number
    wood_cents?: number
}

export interface CitizenCategoryFormInput {
    discountLevel1Weight: number | string
    discountLevel2Weight: number | string
    discountLevel3Weight: number | string
    discountLevel4Weight: number | string
    discountLevel5Weight: number | string
    discountLevel1Volume: number | string
    discountLevel2Volume: number | string
    discountLevel3Volume: number | string
    discountLevel4Volume: number | string
    discountLevel5Volume: number | string
    limitLevel1Weight: number | string
    limitLevel2Weight: number | string
    limitLevel3Weight: number | string
    limitLevel4Weight: number | string
    limitLevel5Weight: null
    limitLevel1Volume: number | string
    limitLevel2Volume: number | string
    limitLevel3Volume: number | string
    limitLevel4Volume: number | string
    limitLevel5Volume: null
    limitsExtended: boolean
    enableWeight: boolean
    enableVolume: boolean
}

export enum ReportStates {
    sent = 'sent',
    inProgress = 'in_progress',
    resolved = 'resolved',
}

export interface IllegalDumpPublicList {
    id: number
    address: string
    latitude: string
    longitude: string
    state: ReportStates
}

export interface IllegalDumpingReportPublic {
    id: number
    description: string
    created_at: string
    documents: Array<UploadedDocument>
}

export interface IllegalDumpPublic extends IllegalDumpPublicList {
    created_at: string
    resolved_at: string
    illegal_dumping_reports: IllegalDumpingReportPublic[]
}

export interface IllegalDumpingReport extends IllegalDumpingReportPublic {
    sender_email: string
}

export interface IllegalDump extends IllegalDumpPublicList {
    created_at: string
    resolved_at: string
    illegal_dumping_reports: IllegalDumpingReport[]
}


export interface IllegalDumpFormInput {
    address: string
    description: string
    sender_email: string
}

export interface IllegalDumpFormInputWithFiles extends IllegalDumpFormInput {
    files: FileWithMetadata[]
    position: { lat: number, lng: number } | null
}

export interface IllegalDumpReportFormInput {
    description: string
    sender_email: string
}

export interface IllegalDumpStateChangeForm {
    description: string
}

export interface IllegalDumpReportFormInputWithFiles extends IllegalDumpReportFormInput {
    files: FileWithMetadata[]
}

export interface ErrorResponse {
    error: string
}

export interface Municipality {
    id: number
    name: string
    eoko_contact_id: number
    eoko_entitlement_town_id: number
    created_at: string
    updated_at: string
}

export interface TotalMunicipalityStats {
    mixed_weight: number
    bio_weight: number
    paper_weight: number
    plastic_weight: number
    glass_weight: number
    other_recycling_weight: number
    all_weight: number
    recycling_weight: number
    avg_mixed_ratio: number
    mixed_bin_dumps_count: number
    bio_bin_dumps_count: number
    paper_bin_dumps_count: number
    plastic_bin_dumps_count: number
    glass_bin_dumps_count: number
    citizens_count: number
    all_bin_dumps_count: number
    recycling_bin_dumps_count: number
}

export interface MonthlyMunicipalityStats extends TotalMunicipalityStats {
    id: string | number
    year: number
    month: number
}

export interface MunicipalityStatsByVolume {
    citizens_count: number
    stats: {
        month: MonthYear
        material: string
        volume: string
        weight: string
        dumps_count: string
        corrected_weight: string
        corrected_dumps_count: string
    }[]
}

export type MonthYear = `${number}-${number}`
export type ISODateString = `${number}-${number}-${number}`

export interface MunicipalityCitizenStats {
    level: number
    count: number
    range: string
}

export interface CategoryInfo {
    citizens_count: number
    household_count: number
    discount_per_citizen: number
    discount_per_category: number
    household_ids: number[]
}

export interface MotivationModelScenario {
    scenario: ScenarioTypes
    annual_surcharge_cents: number
    citizen_fee_cents: number
    min_annual_surcharge_cents: number
    max_annual_surcharge_cents: number
    min_citizen_fee_cents: number
    max_citizen_fee_cents: number
    weights: {
        mixed: number
        bio: number
        paper: number
        plastic: number
        glass_mixed: number
        glass_clear: number
        liquid_paperboard_single: number
        liquid_paperboard_combined: number
        metal_single: number
        metal_combined: number
        wood: number
        construction_waste: number
        hazardous_waste: number
        recycled: number
        all: number
        mixed_basic: number
        paper_basic: number
        plastic_basic: number
        glass_mixed_basic: number
        glass_clear_basic: number
        paper_collection_yard: number
        plastic_collection_yard: number
        glass_mixed_collection_yard: number
        glass_clear_collection_yard: number
        liquid_paperboard_single_collection_yard: number
        liquid_paperboard_combined_collection_yard: number
        metal_single_collection_yard: number
        metal_combined_collection_yard: number
        wood_collection_yard: number
        construction_waste_collection_yard: number
        hazardous_waste_waste_collection_yard: number
        paper_other: number
        plastic_other: number
        glass_mixed_other: number
        glass_clear_other: number
        liquid_paperboard_single_other: number
        liquid_paperboard_combined_other: number
        metal_single_other: number
        metal_combined_other: number
        wood_other: number
        construction_waste_other: number
        hazardous_waste_ot: number
    }
    categorized_households: {
        mixed_weight_categories: {
            "5": CategoryInfo
            "4": CategoryInfo
            "3": CategoryInfo
            "2": CategoryInfo
            "1": CategoryInfo
        },
        mixed_volume_categories: {
            "5": CategoryInfo
            "4": CategoryInfo
            "3": CategoryInfo
            "2": CategoryInfo
            "1": CategoryInfo
        },
        not_in_program_categories: {
            "0": CategoryInfo
        }
        sum_category: {
            discount_total: number
            citizens_count: number
            household_count: number
        }
    },
    expenses: {
        bin_dump: number
        collection_yard_operation_cents: number
        penalty: number
        total: number
        waste_management_cents: number
        weight: number
    }
    incomes: {
        ekokom: {
            paper_basic: number
            plastic_basic: number
            glass_mixed_basic: number
            glass_clear_basic: number
            paper_collection_yard: number
            plastic_collection_yard: number
            glass_mixed_collection_yard: number
            glass_clear_collection_yard: number
            liquid_paperboard_single_collection_yard: number
            liquid_paperboard_combined_collection_yard: number
            metal_single_collection_yard: number
            metal_combined_collection_yard: number
            wood_collection_yard: number
            paper_other: number
            plastic_other: number
            glass_mixed_other: number
            glass_clear_other: number
            liquid_paperboard_single_other: number
            liquid_paperboard_combined_other: number
            metal_single_other: number
            metal_combined_other: number
            paper: number
            plastic: number
            glass_mixed: number
            glass_clear: number
            liquid_paperboard_single: number
            liquid_paperboard_combined: number
            metal_single: number
            metal_combined: number
            wood: number
            ekokom_total: number
        },
        collection_yard_income: number
        other_income: number
        total: number
        citizens_income: number
        citizens_income_without_discount: number
        non_paying_citizens_expanse: number
        this_year_discount: number
        next_year_discount: number
    }
}

// Other bin dump types need to be specified if used
export interface MotivationModelCalculate extends MotivationModelScenario {
    bin_dumps: {
        "110"?: {
            "BIO"?: number
            "MIXED"?: number
            "PAPER"?: number
            "PLASTIC"?: number
        }
    }
}

export interface ScenarioParameters {
    scenario: ScenarioTypes
    annualSurcharge: string | null
    citizenFee: string | null
    lastChange: 'citizenFee' | 'annualSurcharge' | null
}

export interface CollectionNotification {
    id: number
    title: string
    description: string
    priority: 'low' | 'medium' | 'high'
    published_at: string | null
    published_by?: {
        id: number
        email: string
    }
    municipality_id: number
    created_at: string
    updated_at: string
}

export interface CollectionNotificationFormInput {
    priority: 'low' | 'medium' | 'high'
    title: string
    description: string
}

export interface CollectionNotificationsResponse extends Pagination {
    collection_notification: Array<CollectionNotification>
    records_count: number
}

export interface UploadedDocument {
    id: number
    filename: string,
    url: string,
    temporary_url: string,
}

export interface PayLessInstruction {
    id: number
    description: string
    municipality_id: number
    documents: Array<UploadedDocument>
}

export interface PayLessInstructionFormInput {
    description: string
}

export interface PublicBinInstruction {
    id: number
    description: string
    municipality_id: number
}

export interface PublicBinInstructionFormInput {
    description: string
}

export enum UserRole {
    basic = "basic",
    collectionCompany = "collection_company",
    municipalityOfficial = "municipal_official",
    collectionPointAdmin = "collection_point_administrator",
    resident = "resident",
    eokoDataAdmin = "eoko_data_administrator",
    admin = "admin",
}

export interface IllegalDumpsResponse extends Pagination {
    illegal_dump: Array<IllegalDump>
    records_count: number
}

export enum Endpoints {
    wasteFee = 'waste_fee',
    payLessInstruction = 'pay_less_instruction'
}

export interface FileMetadata {
    checksum: string
    filename: string
    contentType: string
    byteSize: number
}

export interface FileWithMetadata {
    filename: string
    byte_size: number
    checksum: string
    content_type: 'text/plain'
    file_data: string
}

export interface DirectUpload extends FileWithMetadata {
    documentable_id: number
}

export interface WasteFee {
    id: number
    description: string
    documents: Array<UploadedDocument>
    municipality_id: number
    year: number
}

export interface WasteFeeFormInput {
    year: number
    description: string
}

export interface CollectionCompany {
    id: number
    name: string
    created_at: string
    updated_at: string
    uncollected_bin_report_delay_days: number
}

export interface ContactDivisionFormInput {
    name: string
    description: string
}

export interface ContactFormInput {
    name: string
    description: string
    email: string
    phone_number: string
}

export interface Contact {
    id: number
    name: string
    email?: string
    phone_number?: string
    description?: string
    created_at: string
    updated_at: string
}

export interface ContactDivision {
    id: number
    name: string
    description?: string
    municipality_id: number | null
    collection_company_id: number | null
    created_at: string
    updated_at: string
    contacts: Array<Contact>
}

export interface AggregatedWasteData {
    name: string
    tons: number
    perPerson: number
    percentage: number
    percentageRecycling?: number | null
    dumpsCount?: number | null
    color: {
        light: string
        main: string
        dark: string
    }
}

export interface EkokomStats {
    ekokom_stat_id: number
    region_id: number | null
    region_name: string | null
    municipality_size_id: number | null
    municipality_size_name: string | null
    year: number
    citizens_count: number
    mixed_weight_per_citizen: string
    mixed_weight_total: string
    sorted_weight_per_citizen: string
    sorted_weight_total: string
}

export enum CollectedWasteRecordType {
    collectionYard = 'collection_yard',
    other = 'other',
}

export interface CollectedWasteStat {
    id: number
    mixed_weight: number
    bio_weight: number
    paper_weight: number
    plastic_weight: number
    glass_mixed_weight: number
    glass_clear_weight: number
    liquid_paperboard_single_weight: number
    liquid_paperboard_combined_weight: number
    metal_single_weight: number
    metal_combined_weight: number
    wood_weight: number
    construction_waste_weight: number
    hazardous_waste_weight: number
    large_volume_waste_weight: number
    year_month: string
    record_type: CollectedWasteRecordType
    municipality_id: number
    created_at: string
    updated_at: string
}

export interface CollectedWasteStatFormInput {
    year_month: string
    record_type: CollectedWasteRecordType
    mixed_weight: number
    bio_weight: number
    paper_weight: number
    plastic_weight: number
    glass_mixed_weight: number
    glass_clear_weight: number
    liquid_paperboard_single_weight: number
    liquid_paperboard_combined_weight: number
    metal_single_weight: number
    metal_combined_weight: number
    wood_weight: number
    construction_waste_weight: number
    hazardous_waste_weight: number
    large_volume_waste_weight: number
}

export type CollectionYard = {
    id: number
    name: string
    description: string
    municipality_id: number
    created_at: string
    updated_at: string
}

export type CollectionYardFormInput = {
    name: string
    description: string
}

export interface CommunicationThread {
    id: number
    archived: boolean
    created_in_eoko_at: string | null
    closed_at: string | null
    municipality_id: number | null
    collection_company_id: number | null
    communication_type: {
        id: number
        name: string
    }
    created_at: string
    updated_at: string
    household?: {
        id: number
        admin?: {
            id: number
            first_name: string
            last_name: string
        }
        address?: {
            id: number
            street: string
            street_number: string
        }
    }
}

export interface CommunicationsResponse extends Pagination {
    communication: Array<CommunicationThread>
    records_count: number
}

export interface CommunicationMessage {
    id: number
    content: string
    created_at: string
    sender: {
        id: number
        email: string
    }
    recipient?: {
        id: number
        email: string
    }
}

export interface CommunicationThreadDetail extends CommunicationThread {
    communication_type: {
        id: number
        name: string
        description: string
    }
    initialized_by: {
        id: number
        email: string
    }
    messages: Array<CommunicationMessage>
}

export interface CommunicationListFilters {
    communicationTypeId: number | ""
    closed: "" | "true" | "false"
}

export interface CommunicationsQueryOptions {
    initializedById?: string
    pagination: Pagination
    filters?: CommunicationListFilters
}

export interface CommunicationsCollectionCompanyQueryOptions extends CommunicationsQueryOptions {
    municipalityId?: number
    collectionCompanyId: number
}

export interface CommunicationsMunicipalityQueryOptions extends CommunicationsQueryOptions {
    municipalityId: number
    collectionCompanyId?: number
}

export interface HouseholdExtension {
    id: number
    interval_begins_date: string
    interval_ends_date: string
    mixed_volume: number
    mixed_weight: number
    in_motivation_program: boolean
    year: number
    verified_by_user_at: string | null
}

export interface CategoryDetails {
    id: number
    level: CategoriesNumbers
    discount_amount_cents: number
    motivation_model?: {
        id: number
        citizen_fee_cents: number
    }
}
export interface HouseholdExtensionWithCategories extends HouseholdExtension {
    force_discount_cents: number
    mixed_volume_category?: CategoryDetails
    mixed_weight_category?: CategoryDetails
    active_mixed_volume_category?: CategoryDetails
    active_mixed_weight_category?: CategoryDetails
}

export type CollectionPointListItem = {
    id: number
    code: string
    type: string
    number_of_members: number | null
    address: {
        id: number
        street: string
        street_number: string
    }
}

export type CollectionPointWeighting = {
    bin_weighting_id: string
    time_unit: string
    // weight: string
    corrected_weight: string
    bin_id: string
    bin_type_id: string
    bin_type_volume: string
    waste_type_id: string
    waste_type_name: string
}

export type CollectionPointWeightingsRespose = {
    number_of_members: number
    bin_weightings: Array<CollectionPointWeighting>
}

export enum WasteTypeIds {
    bio = 1,
    paper = 2,
    plastic = 3,
    mixed = 4,
    glass = 6,
    clearGlass = 7
}

export enum MaterialTypes {
    plastic = 'PLASTIC',
    metal = 'METAL',
    fiberglass = 'FIBERGLASS',
}

export enum CollectionPointTypes {
    apartmentBuilding = 'APARTMENT_BUILDING',
    holidayHome = 'HOLIDAY_HOME',
    familyHouse = 'FAMILY_HOUSE',
    publicWastepoint = 'PUBLIC_WASTE_POINT',
    unknown = 'UNKNOWN',
}

export type Bin = {
    id: number
    bin_type: {
        id: number
        volume: number
        material: MaterialTypes | null
        waste_type: {
            id: number
            name: string
        }
    }
    bin_code: {
        id: number
        visible_code: string
    }
    last_bin_weighting?: {
        id: number
        time_unit: string
        weight: number
    }
}

export type RemappedBin = {
    id: number
    rfid: string
    type: {
        label: string
        color: string
    },
    volume: string
    material: string
    date: string
}

export type CollectionPointDetail = {
    id: number
    code: string
    type: CollectionPointTypes
    number_of_members: number | null
    mixed_weight_categories_disabled: boolean
    mixed_volume_categories_disabled: boolean
    force_discount_cents: number | null
    address: {
        id: number
        street: string
        street_number: string
        town: string
        zip_code: string | null
        country_code: string | null
        latitude: string
        longitude: string
    }
    bins: Bin[]
    admin: {
        id: number
        name: string
        mobile: string | null
        email: string | null
    }
    exts?: HouseholdExtensionWithCategories[]
}

export type CollectionPointCategory = {
    id: number
    code: string
    number_of_members: number
    type: CollectionPointTypes
    address: {
        id: number
        street: string
        street_number: string
    }
    admin?: {
        id: number
        first_name: string
        last_name: string
    }
    exts?: HouseholdExtensionWithCategories[]
}

export interface CollectionPointCategoryWithSingleExtension extends CollectionPointCategory {
    ext?: HouseholdExtensionWithCategories
}

export enum Categories {
    one = '1',
    two = '2',
    three = '3',
    four = '4',
    five = '5'
}

export interface CreateCommunicationFormInput {
    description: string
}

export interface CreateClaimCommunicationFormInput extends CreateCommunicationFormInput {
    selectedBinIds: number[]
    selectedBins?: string
    day: string
}

export enum CategoriesNumbers {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5
}

export interface CollectionPointCategoryListResponse extends Pagination {
    eoko_household: Array<CollectionPointCategory>
    records_count: number
}

export enum WasteTypeCodes {
    mixed = 'MIXED',
    bio = 'BIO',
    paper = 'PAPER',
    plastic = 'PLASTIC',
    glass = 'GLASS',
    clearGlass = 'CLEAR_GLASS',
}

export interface MunicipalityBin {
    bin_id: string,
    household_id: string
    waste_type_code: WasteTypeCodes
    bin_volume: string,
    latitude: string | null,
    longitude: string | null,
}

export interface ApplicationInstruction {
    id: number
    code: string
    description: string
    created_at: string
    updated_at: string
}

export interface ApplicationInstructionFormInput {
    code: string
    description: string
}

export type Coordinates = {
    latitude: string
    longitude: string
}

export interface IllegalDumpListFilters {
    sort_by: 'created_at' | 'reports_count' | 'resolved_at'
    sort_direction: 'DESC' | 'ASC'
    state: ReportStates | ''
}

export enum MMParticipation {
    all = 'all',
    yes = 'yes',
    no = 'no',
}

export interface CollectionPointCategoryListFilters {
    mixedWeightCategories: Array<Categories>
    mixedVolumeCategories: Array<Categories>
    types: Array<CollectionPointTypes>
    numberOfMembers: string
    inMotivationProgram: MMParticipation
    addressLike: string
    adminNameLike: string
}

export enum OrderDirection {
    asc = 'asc',
    desc = 'desc',
}

export enum CollectionPointCategoryListOrderAttribute {
    code = 'code',
    type = 'type',
    address = 'address',
    name = 'name',
    numberOfMembers = 'number_of_members',
    activeMixedWeightCategoryLevel = 'active_mixed_weight_category_level',
    byActiveMixedVolumeCategoryLevel = 'active_mixed_volume_category_level',
    discountCents = 'discount_cents',
    feeCents = 'fee_cents',
}

export interface CollectionPointCategoryListOrder {
    orderAttribute: CollectionPointCategoryListOrderAttribute
    orderDirection: OrderDirection
}

export enum ExportStatus {
    successful = 'successful',
    processing = 'processing',
    failed = 'failed',
}

export interface CategoryListExport {
    id: number
    state: ExportStatus
    creator: {
        id: number
        email: string
    }
    municipality: {
        id: number
        name: string
    }
    documents: {
        id: number
        filename: string
        url: string
        temporary_url: string
    }[]
}

export interface CreateCategoryListExportResponse {
    id: number
    state: ExportStatus
    municipality_id: number
    creator_id: number
    created_at: string
    updated_at: string
}

export interface UpdateCollectionPointParticipationFormInput {
    in_motivation_program: boolean
    force_discount: string
}

export interface MunicipalityScoreVisibilityFormInput {
    visible: boolean
}

export interface MunicipalitySize {
    id: number
    citizens_count_gte: number
    citizens_count_lt: number
    created_at: string
    updated_at: string
    municipality_size_type: 'stat' | 'bonus'
}

export enum EkokomBonusType {
    basic = 'basic',
    collection_yard = 'collection_yard',
    other = 'other',
}

export interface EkokomBonus {
    id: number
    paper_cents: number
    plastic_cents: number
    glass_mixed_cents: number
    glass_clear_cents: number
    liquid_paperboard_single_cents: number
    liquid_paperboard_combined_cents: number
    metal_single_cents: number
    metal_combined_cents: number
    wood_cents: number
    bonus_type: EkokomBonusType
    custom: boolean
    valid_from: string
    valid_to: string | null
    municipality_size_id: number | null
    created_at: string
    updated_at: string
}

export interface EkokomBonusOtherFormInput {
    year: number
    bonusType: EkokomBonusType.other
    paper: number | string
    plastic: number | string
    glass_mixed: number | string
    glass_clear: number | string
    liquid_paperboard_single: number | string
    liquid_paperboard_combined: number | string
    metal_single: number | string
    metal_combined: number | string
    wood: number | string
}

export interface EkokomBonusUpsertFormInput {
    year: number
    bonusType: EkokomBonusType
    paper10: number | string
    plastic10: number | string
    glass_mixed10: number | string
    glass_clear10: number | string
    liquid_paperboard_single10: number | string
    liquid_paperboard_combined10: number | string
    metal_single10: number | string
    metal_combined10: number | string
    wood10: number | string
    paper11: number | string
    plastic11: number | string
    glass_mixed11: number | string
    glass_clear11: number | string
    liquid_paperboard_single11: number | string
    liquid_paperboard_combined11: number | string
    metal_single11: number | string
    metal_combined11: number | string
    wood11: number | string
    paper12: number | string
    plastic12: number | string
    glass_mixed12: number | string
    glass_clear12: number | string
    liquid_paperboard_single12: number | string
    liquid_paperboard_combined12: number | string
    metal_single12: number | string
    metal_combined12: number | string
    wood12: number | string
    paper13: number | string
    plastic13: number | string
    glass_mixed13: number | string
    glass_clear13: number | string
    liquid_paperboard_single13: number | string
    liquid_paperboard_combined13: number | string
    metal_single13: number | string
    metal_combined13: number | string
    wood13: number | string
    paper14: number | string
    plastic14: number | string
    glass_mixed14: number | string
    glass_clear14: number | string
    liquid_paperboard_single14: number | string
    liquid_paperboard_combined14: number | string
    metal_single14: number | string
    metal_combined14: number | string
    wood14: number | string
    paper15: number | string
    plastic15: number | string
    glass_mixed15: number | string
    glass_clear15: number | string
    liquid_paperboard_single15: number | string
    liquid_paperboard_combined15: number | string
    metal_single15: number | string
    metal_combined15: number | string
    wood15: number | string
}

export type CollectionPointMixedWeightCategory = {
    number_of_members: number,
    current_level: number,
    current_discount_cents: number,
    current_mixed_weight: number,
    current_category_lt: number,
    current_category_gte: number,
    better_category_level: number,
    better_category_discount_cents: number,
    weight_difference_monthly: number,
    categories_disabled: boolean
}

export type CollectionPointMixedVolumeCategory = {
    number_of_members: number,
    current_level: number,
    current_discount_cents: number,
    current_mixed_volume: number,
    current_dumps: number,
    worse_category_level: number,
    worse_category_discount_cents: number,
    worse_category_limit: number,
    largest_sko_bin_volume: number,
    dumps_difference: number,
    all_categories: {
        id: number,
        level: number,
        discount_amount_cents: number,
        created_at: string,
        updated_at: string,
        value_gte: number,
        value_lt: number,
        motivation_model_id: number,
    }[]
    categories_disabled: boolean
}

export interface MunicipalityFinanceMonth {
    id: number
    year_month: string
    bin_collection_expenses: number
    collection_yard_expenses: number
    other_expenses: number
    ekokom_income: number
    citizen_fee_income: number
    collection_yard_income: number
    other_income: number
    municipality_id: number
    created_at: string
    updated_at: string
}

export interface MunicipalityFinanceYearSum {
    bin_collection_expenses: number
    collection_yard_expenses: number
    other_expenses: number
    ekokom_income: number
    citizen_fee_income: number
    collection_yard_income: number
    other_income: number
}

export interface MunicipalityFinanceMonthFormInput {
    year_month: string
    bin_collection_expenses: number
    collection_yard_expenses: number
    other_expenses: number
    ekokom_income: number
    citizen_fee_income: number
    collection_yard_income: number
    other_income: number
}

export type CollectionCalendarEntry = {
    date: string,
    material_code: WasteTypeCodes,
    bin_visible_code: string,
    bin_id: string,
}

export interface UsersFilters {
    roles: UserRole[]
}

export interface User {
    id: number
    email: string
    created_at: string
    updated_at: string
    role: UserRole
    eoko_contacts_users_junctions: {
        id: number
        eoko_contact_id: number
    }[]
    municipality_id: number | null
    approved_at: string | null
    approved_by_id: number | null
    active: boolean
    welcome_email_sent_at: string | null
}

export interface UsersResponse extends Pagination {
    user: Array<User>
    records_count: number
}

export interface UserFormInput {
    email: string
    password: string
    role: UserRole
    municipality_id: string
}

export interface UserEmailFormInput {
    email: string
}

export interface UserRoleFormInput {
    role: UserRole
}

export interface UserPasswordFormInput {
    password: string
}

export interface ChangeMyPasswordFormInput {
    old_password: string
    password: string
    password_confirmation: string
}

export type MunicipalityCollectionCalendarEntry = {
    code: WasteTypeCodes
    regions: string[]
    dates: string[]
}

export interface MunicipalityScore {
    id: number
    mixed_weight_per_citizen: string
    visible: boolean
    municipality: {
        id: number
        name: string
        visible_on_public: boolean
    }
}

export enum CommunicationTypeCodes {
    containerRequest = 'container_request',
    extraCollectionRequest = 'extra_collection_request',
    destroyedBinReport = 'destroyed_bin_report',
    uncollectedBinReport = 'uncollected_bin_report',
    binReplacementRequest = 'bin_replacement_request',
    attributesChangeRequest = 'attributes_change_request',
}

export interface CommunicationType {
    id: number
    name: string
    description: string
    created_at: string
    updated_at: string
    code: CommunicationTypeCodes
}

export interface MunicipalityPublicVisibilityFormInput {
    visible: boolean
}

type CategoryLevel = Record<"1" | "2" | "3" | "4" | "5", number>

export interface CategoryCollectionPointCountResponse {
    active: {
        mixed_weight_levels: CategoryLevel
        mixed_volume_levels: CategoryLevel
        not_in_motivation_program: number
    }
    in_comparison: {
        mixed_weight_levels: CategoryLevel
        mixed_volume_levels: CategoryLevel
        not_in_motivation_program: number
    }
}

export interface CollectionPointCategoriesTable {
    text: string
    severity: number
    color: string
    primary_weight_count: number
    primary_weight_percentage: string
    primary_volume_count: number
    primary_volume_percentage: string
    comparison_weight_count: number
    comparison_weight_percentage: string
    comparison_volume_count: number
    comparison_volume_percentage: string
}

export interface Region {
    id: number
    name: string
    created_at: string
    updated_at: string
}

export interface EkokomStat {
    id: number
    year: number
    mixed_weight_per_citizen: string
    sorted_weight_per_citizen: string
    country: null
    created_at: string
    updated_at: string
    region_id: number | null
    municipality_size_id: number | null
}

export interface EkokomStatByMunicipalitySizeUpsertFormInput {
    year: number
    mixed_weight1: number | string
    sorted_weight1: number | string
    mixed_weigh2: number | string
    sorted_weight2: number | string
    mixed_weight3: number | string
    sorted_weight3: number | string
    mixed_weight4: number | string
    sorted_weight4: number | string
    mixed_weight5: number | string
    sorted_weight5: number | string
    mixed_weight6: number | string
    sorted_weight6: number | string
    mixed_weight7: number | string
    sorted_weight7: number | string
    mixed_weight8: number | string
    sorted_weight8: number | string
    mixed_weight9: number | string
    sorted_weight9: number | string
}

export interface EkokomStatByRegionUpsertFormInput {
    year: number
    mixed_weight1: number | string
    sorted_weight1: number | string
    mixed_weigh2: number | string
    sorted_weight2: number | string
    mixed_weight3: number | string
    sorted_weight3: number | string
    mixed_weight4: number | string
    sorted_weight4: number | string
    mixed_weight5: number | string
    sorted_weight5: number | string
    mixed_weight6: number | string
    sorted_weight6: number | string
    mixed_weight7: number | string
    sorted_weight7: number | string
    mixed_weight8: number | string
    sorted_weight8: number | string
    mixed_weight9: number | string
    sorted_weight9: number | string
    mixed_weight10: number | string
    sorted_weight10: number | string
    mixed_weight11: number | string
    sorted_weight11: number | string
    mixed_weight12: number | string
    sorted_weight12: number | string
    mixed_weight13: number | string
    sorted_weight13: number | string
    mixed_weight14: number | string
    sorted_weight14: number | string
}

export interface StatusMutationResponse {
    data: {
        payload: {
            id: number
            email: string
        } | null
        errors: string[]
        status_message: string | null
    }
}
