import { useState } from 'react'
import { atom, useAtom, useAtomValue } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import LoopIcon from '@mui/icons-material/Loop'

import { useCommunicationsCollectionCompany } from '@/hooks/api/use-communication'
import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import type {
    CommunicationThread,
    CommunicationListFilters,
    Pagination,
    Municipality,
} from '@/types'
import PaginationControls from '@/components/pagination-controls'
import CommunicationActions from './actions'
import ArchiveDialog from './archive-dialog'
import ResolveDialog from './resolve-dialog'
import { HISTORY_RETURN_PARAM_KEY } from '@/constants/general'
import CommunicationThreadStatus from './thread-status'
import { printHumanDateTime } from '@/helpers'
import CommunicationTypeSelect from './communication-type-select'

const initialFilters: CommunicationListFilters = {
    communicationTypeId: "",
    closed: "",
}

const filtersAtom = atom<CommunicationListFilters>(initialFilters)

const paginationAtom = atom<Pagination>({
    limit: 20,
    offset: 0,
})

function CommunicationListCollectionCompany({
    collectionCompanyId,
    municipalities,
}: {
    collectionCompanyId: number,
    municipalities: Array<Municipality>,
}) {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const navigate = useNavigate()

    const handleClick = (_event: React.MouseEvent<unknown>, id: number) => {
        navigate(`${id}?${HISTORY_RETURN_PARAM_KEY}=true`)
    }

    // Pagination
    const [pagination, setPagination] = useAtom(paginationAtom)

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    // Filters
    const [filters, setFilters] = useAtom(filtersAtom)

    const handleResetFilters = () => {
        setFilters(initialFilters)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value as string,
        })
        handleResetPagination()
    }

    // Action dialogs
    const [resolve, setResolve] = useState<CommunicationThread | null>(null)
    const [archive, setArchive] = useState<CommunicationThread | null>(null)
    
    const { status, data, error, refetch } = useCommunicationsCollectionCompany({
        collectionCompanyId,
        pagination,
        filters,
    })

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst seznam komunikace obce s ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: 2.5,
                gap: 1.5,
                pb: 2,
                pt: 1,
                alignItems: 'center',
            }}>
                <FilterAltRoundedIcon />
                <Box sx={{ minWidth: 140 }}>
                    <CommunicationTypeSelect
                        communicationTypeId={filters.communicationTypeId}
                        handleChange={handleChange}
                        collectionClaims
                    />
                </Box>
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <InputLabel id="state-select-label" size="small">Stav</InputLabel>
                        <Select
                            labelId="state-select-label"
                            id="state-select"
                            value={filters.closed}
                            name="closed"
                            label="Stav"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value="">Vše</MenuItem>
                            <MenuItem value="true">Vyřešeno</MenuItem>
                            <MenuItem value="false">Vytvořeno</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Tooltip title="Resetovat filtry">
                    <IconButton
                        aria-label="Resetovat filtry"
                        onClick={() => handleResetFilters()}
                        size="small"
                    >
                        <DoNotDisturbAltRoundedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Obnovit">
                    <IconButton
                        color="terciary"
                        onClick={() => {
                            refetch()
                        }}
                    >
                        <LoopIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {data.records_count === 0 ?
                <Box px={2.5}>
                    Svozová společnost nemá žádné aktivní reklamace
                </Box>
            :
                <Box>
                    <Table
                        size="small"
                        aria-label="Seznam reklamací svozů"
                        sx={{
                            mt: 1,
                            minWidth: 600,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ paddingLeft: 3 }}>Stav</TableCell>
                                <TableCell>Typ</TableCell>
                                <TableCell>Domácnost</TableCell>
                                <TableCell>Obec</TableCell>
                                <TableCell>Vytvoření</TableCell>
                                <TableCell>Poslední změna</TableCell>
                                <TableCell>Vyřešeno</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.communication.map(row => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                    onClick={(event) => handleClick(event, row.id)}
                                    hover
                                >
                                    <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                        <CommunicationThreadStatus communicationThread={row} />
                                    </TableCell>
                                    <TableCell>
                                        {row.communication_type.name}
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Box>{row.household?.admin?.first_name ?? '-'} {row.household?.admin?.last_name ?? '-'}</Box>
                                            <Box color="customGrey.secondaryText">{row.household?.address?.street ?? '-'} {row.household?.address?.street_number ?? '-'}</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {municipalities.find(municipality => municipality.id === row.municipality_id)?.name ?? '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Box color="customGrey.secondaryText">{printHumanDateTime(dayjs(row.created_at))}</Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box color="customGrey.secondaryText">{printHumanDateTime(dayjs(row.updated_at))}</Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box color="customGrey.secondaryText">{row.closed_at ? printHumanDateTime(dayjs(row.closed_at)) : '-'}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <CommunicationActions
                                            communicationThread={row}
                                            setResolve={setResolve}
                                            setArchive={setArchive}
                                            allowDetail
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <PaginationControls
                        pagination={pagination}
                        setPagination={setPagination}
                        currentArrayLength={data.communication.length}
                        recordsCount={data.records_count}
                    />
                </Box>
            }
            <Dialog
                key={'resolve-'+resolve?.id}
                open={!!resolve}
                onClose={() => setResolve(null)}
            >
                <DialogContent>
                     <ResolveDialog
                        communicationId={resolve?.id}
                        handleClose={() => setResolve(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        communicationId={archive?.id}
                        handleClose={() => setArchive(null)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default CommunicationListCollectionCompany
