import { useState } from 'react'
import { Link, Outlet, ScrollRestoration } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import RecyclingIcon from '@mui/icons-material/Recycling'
import Toolbar from '@mui/material/Toolbar'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import Tooltip from '@mui/material/Tooltip'

import AppBar from '@/components/app-bar'
import ProfileButton from '@/components/profile-button'
import Menu from '@/components/menu'
import MunicipalitySelector from '@/components/municipality-selector'
import CollectionPointSelector from '@/components/collection-point-selector'
import { ROUTE_SEGMENTS } from '@/constants/general'
import { Typography } from '@mui/material'
import Help from '@/components/help'
import VersionCheck from '@/components/version-check'

const drawerWidth = 250
const drawerHelpWidth = 320

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'openHelp' })<{
    open?: boolean
    openHelp?: boolean
}>(({ theme, open, openHelp }) => ({
    flexGrow: 1,
    padding: theme.spacing(0, 1.5, 1.5),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 2, 2),
    },
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
        marginLeft: `-${drawerWidth}px`,
    }),
    ...(!openHelp && {
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },
        marginRight:`-${drawerHelpWidth}px`,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        width: `calc(100% - ${drawerWidth}px)`,
    }),
    ...(openHelp && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
        width: `calc(100% - ${drawerHelpWidth}px)`,
    }),
}))

function PrivateLayout() {

    const [open, setOpen] = useState(true)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [isClosing, setIsClosing] = useState(false)

    const [openHelp, setOpenHelp] = useState(false)
    const [mobileOpenHelp, setMobileOpenHelp] = useState(false)
    const [isClosingHelp, setIsClosingHelp] = useState(false)

    const handleDrawerClose = () => {
        setIsClosing(true)
        setMobileOpen(false)
    }

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false)
    }

    const handleMobileDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen)
        }
    }

    const handleDrawerToggle = () => {
        if (!open) {
            setOpenHelp(false)
        }
        setOpen(!open)
    }

    const handleHelpDrawerClose = () => {
        setIsClosingHelp(true)
        setMobileOpenHelp(false)
    }

    const handleHelpDrawerTransitionEnd = () => {
        setIsClosingHelp(false)
    }

    const handleMobileHelpDrawerToggle = () => {
        if (!isClosingHelp) {
            setMobileOpenHelp(!mobileOpenHelp)
        }
    }

    const handleHelpDrawerToggle = () => {
        if (!openHelp) {
            setOpen(false)
        }
        setOpenHelp(!openHelp)
    }

    const drawer = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 2,
            height: '100%',
        }}>
            <Toolbar>
                <Box sx={{
                    mr: 2,
                    display: { xs: 'block', md: 'none' },
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleMobileDrawerToggle}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </Toolbar>
            <Box sx={{
                bgcolor: 'background.paper',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                flexGrow: 1,
                py: 1,
            }}>
                <Menu handleMobileClose={handleDrawerClose}/>
            </Box>
        </Box>
    )

    const drawerHelp = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 2,
            height: '100%',
        }}>
            <Toolbar>
                <Box sx={{
                    mr: 2,
                    display: { xs: 'block', md: 'none' },
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleMobileHelpDrawerToggle}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </Toolbar>
            <Box sx={{
                bgcolor: 'background.paper',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                flexGrow: 1,
                py: 1,
            }}>
                <Help />
            </Box>
        </Box>
    )

    return (
        <Box display="flex">
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => ({ md: theme.zIndex.drawer + 1 }) }}>
                <Toolbar sx={{ paddingRight: { xs: '7px', sm: '11px'}}}>
                    <Box sx={{
                        mr: 2,
                        display: { xs: 'block', md: 'none' },
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleMobileDrawerToggle}
                            
                        >
                            <MenuRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'block' },
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            {open ?
                                <MenuOpenRoundedIcon /> :
                                <MenuRoundedIcon />
                            }
                        </IconButton>
                    </Box>
                    <Box sx={{
                        minWidth: {
                            sx: 'auto',
                            md: 200,
                        },
                        display: 'flex',
                    }}>
                        <Box component={Link} to={`/${ROUTE_SEGMENTS.base}`} sx={{
                            color: 'primary.main',
                            display: 'flex',
                            gap: 1,
                            textDecoration: 'none',
                            p: 1,
                            borderRadius: 2,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                        }}>
                            <RecyclingIcon />
                            <Typography variant="h4" component="div">
                                Domů
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' },
                        gap: 1.5,
                    }}>
                        <MunicipalitySelector />
                        <CollectionPointSelector />
                    </Box>
                    <Box flexGrow={1}/>
                    {/* <Button color="inherit" onClick={authValue?.onLogout}>Logout</Button> */}
                    <ProfileButton />
                    <Box sx={{
                        ml: 2,
                        display: { xs: 'block', md: 'none' },
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open help drawer"
                            edge="start"
                            onClick={handleMobileHelpDrawerToggle}
                            
                        >
                            <HelpOutlineRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        ml: 2,
                        display: { xs: 'none', md: 'block' },
                    }}>
                        <Tooltip title="Nápověda">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleHelpDrawerToggle}
                            >
                                <HelpOutlineRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={open}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            bgcolor: 'transparent',
                            border: 'none',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Main open={open} openHelp={openHelp}>
                <Toolbar />
                <VersionCheck />
                <Box sx={{
                    display: {
                        xs: 'flex',
                        md: 'none',
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    gap: 1,
                    pb: 1,
                }}>
                    <MunicipalitySelector />
                    <CollectionPointSelector />
                </Box>
                <Outlet />
            </Main>
            <Box
                component="nav"
                sx={{
                    width: { md: drawerHelpWidth },
                    flexShrink: { md: 0 },
                    ...(!openHelp && !mobileOpenHelp && {
                        pointerEvents: 'none',
                    }),
                }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={mobileOpenHelp}
                    onTransitionEnd={handleHelpDrawerTransitionEnd}
                    onClose={handleHelpDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerHelpWidth },
                    }}
                >
                    {drawerHelp}
                </Drawer>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={openHelp}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerHelpWidth,
                            bgcolor: 'transparent',
                            border: 'none',
                            zIndex: 1,
                        },
                    }}
                >
                    {drawerHelp}
                </Drawer>
            </Box>
            <ScrollRestoration />
        </Box>
    )
}

export default PrivateLayout
