import { useState } from 'react'
import { atom, useAtom } from 'jotai'
import Box from '@mui/material/Box'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import {
    UserRole,
    type Pagination,
} from '@/types'
import PaginationControls from '@/components/pagination-controls'
import { useUsers } from '@/hooks/api/use-users'
import RoleSelector from './role-selector'
import { USER_ROLE_LABEL_MAP } from '@/constants/general'
import UserDetail from './user-detail'
import { Fade } from '@mui/material'
import UserForm from '@/components/forms/user'
import PermissionsGate from '@/components/permissions-gate'

const rolesAtom = atom<UserRole[]>([])

const paginationAtom = atom<Pagination>({
    limit: 20,
    offset: 0,
})

const userRolesWithoutCPAdmin = Object.values(UserRole).filter(role => role !== UserRole.collectionPointAdmin)

function UserList({
    showRoles,
}: {
    showRoles: 'collectionPointAdmin' | 'other',
}) {

    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const [create, setCreate] = useState<boolean>(false)

    // Pagination
    const [pagination, setPagination] = useAtom(paginationAtom)

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    // Filters
    const [roles, setRoles] = useAtom(rolesAtom)

    const handleResetFilters = () => {
        setRoles([])
    }
    
    const { status, data, error } = useUsers(pagination, {
        roles: showRoles === 'other' ?
            roles.length === 0 ? userRolesWithoutCPAdmin : roles
            :
            [UserRole.collectionPointAdmin],
    })

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst seznam uživatelů'}
        />
    }

    const selectedUser = data.user.find(user => user.id === selectedUserId)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: 2.5,
                gap: 1.5,
                pb: 2,
                pt: 1,
                alignItems: 'center',
            }}>
                <FilterAltRoundedIcon />
                {showRoles === 'other' &&
                    <Box display="flex">
                        <RoleSelector
                            label="Role"
                            roles={roles}
                            setRoles={setRoles}
                            callback={handleResetPagination}
                        />
                    </Box>
                }
                <Tooltip title="Resetovat filtry">
                    <IconButton
                        aria-label="Resetovat filtry"
                        onClick={() => handleResetFilters()}
                        size="small"
                    >
                        <DoNotDisturbAltRoundedIcon />
                    </IconButton>
                </Tooltip>
                
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    lg: 'row',
                }
            }}>
                <Box sx={theme => ({
                    flexBasis: {
                        xs: '100%',
                        lg: selectedUserId ? '70%' : '100%',
                    },
                    transition: theme.transitions.create(['flex-basis', 'padding'], {
                        duration: 200,
                    }),
                    pr: {
                        xs: 0,
                        lg: selectedUserId ? 3 : 0,
                    },
                    overflowX: 'auto',
                })}>
                    {data.records_count === 0 ?
                        <Box px={2.5}>
                            Seznam je prázdný
                        </Box>
                    :
                        <Box>
                            <Table
                                size="small"
                                aria-label="Seznam uživatelů"
                                sx={{
                                    mt: 1,
                                    minWidth: 600,
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: 3 }}>Aktivní</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>EOKO ID kontaktu</TableCell>
                                        <TableCell>Dat. schválení</TableCell>
                                        {showRoles === 'collectionPointAdmin' &&
                                            <TableCell>Dat. odeslání přístupu</TableCell>
                                        }
                                        <TableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.user.map(row => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                ...(row.id === selectedUserId ? {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                } : null),
                                            }}
                                            onClick={() => setSelectedUserId(row.id)}
                                            hover
                                        >
                                            <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                                <Box p={1.5}>
                                                    {row.active ?
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'success.light',
                                                        }}/>
                                                        :
                                                        <Box sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'error.light',
                                                        }}/>
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {row.email}
                                            </TableCell>
                                            <TableCell>
                                                {USER_ROLE_LABEL_MAP[row.role]}
                                            </TableCell>
                                            <TableCell>
                                                {row.eoko_contacts_users_junctions.length > 0 ?
                                                    row.eoko_contacts_users_junctions.map(junction => junction.eoko_contact_id).join(', ')
                                                    : '-'
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.approved_at ? dayjs(row.approved_at).format('D. M. YYYY HH:mm') : '-'}
                                            </TableCell>
                                            {showRoles === 'collectionPointAdmin' &&
                                                <TableCell>
                                                    {row.welcome_email_sent_at ? dayjs(row.welcome_email_sent_at).format('D. M. YYYY HH:mm') : '-'}
                                                </TableCell>
                                            }
                                            <TableCell align="right">
                                                <IconButton
                                                    id="user-settings-button"
                                                    aria-label="settings"
                                                    color="terciary"
                                                >
                                                    <SettingsRoundedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationControls
                                pagination={pagination}
                                setPagination={setPagination}
                                currentArrayLength={data.user.length}
                                recordsCount={data.records_count}
                            />
                        </Box>
                    }
                    <PermissionsGate
                        userRoles={[
                            UserRole.admin,
                            UserRole.municipalityOfficial,
                            UserRole.eokoDataAdmin,
                            UserRole.collectionCompany,
                        ]}
                    >
                        <Box px={2.5} pb={1.5}>
                            {create ?
                                <Box maxWidth="sm">
                                    <UserForm
                                        closeCallback={() => setCreate(false)}
                                    />
                                </Box>
                                :
                                <Button
                                    variant="contained"
                                    color="terciary"
                                    startIcon={<AddRoundedIcon />}
                                    onClick={() => setCreate(true)}
                                >
                                    Vytvořit uživatele
                                </Button>
                            }
                        </Box>
                    </PermissionsGate>
                </Box>
                <Box sx={(theme) => ({
                    flexBasis: {
                        xs: '100%',
                        lg: selectedUserId ? '30%' : '0%',
                    },
                    flexShrink: 0,
                    flexGrow: 0,
                    transition: theme.transitions.create(['flex-basis', 'padding'], {
                        duration: 200,
                    }),
                    pl: {
                        xs: 2,
                        lg: 0,
                    },
                    pr: selectedUserId ? 2 : 0,
                    pt: {
                        xs: 3,
                        lg: 0,
                    },
                    borderTop: {
                        xs: `1px solid ${theme.palette.customGrey.divider}`,
                        lg: '0px',
                    },
                })}>
                    {selectedUser &&
                        <Box sx={theme => ({
                            p: 2,
                            borderRadius: 2.5,
                            border: `1px solid ${theme.palette.divider}`,
                        })}>
                            <Fade in={!!selectedUserId} style={{ transitionDelay: selectedUserId ? '200ms' : '0ms' }}>
                                <Box>
                                    <UserDetail
                                        user={selectedUser}
                                        handleClose={() => setSelectedUserId(null)}
                                    />
                                </Box>
                            </Fade>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default UserList
