import { useForm, type SubmitHandler } from "react-hook-form"

import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFPassword from "@/components/form-fields/rhf-password"
import type { ChangeMyPasswordFormInput } from "@/types"
import { useChangeMyPassword } from "@/hooks/api/use-users"

const ChangeMyPasswordForm = ({
    closeCallback,
    onSuccessCallback,
} : {
    closeCallback?: () => void,
    onSuccessCallback?: () => void,
}) => {

    const initialValues = {
        old_password: '',
        password: '',
        password_confirmation: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(() => reset(initialValues))

    const mutationCreate = useChangeMyPassword(
        {
            setRequestInProgress,
            onSuccessCallback,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        }
    )

    const onSubmit: SubmitHandler<ChangeMyPasswordFormInput> = data => {
        setRequestInProgress(true)
        mutationCreate.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Změnit heslo'}
            isLoadedFromLocalStorage={false}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            error={error}
            setError={setError}
            buttonText="Změnit"
        >
            <RHFPassword
                name="old_password"
                control={control}
                label="Staré heslo"
                required
            />
            <RHFPassword
                name="password"
                control={control}
                label="Nové heslo"
                required
            />
            <RHFPassword
                name="password_confirmation"
                control={control}
                label="Nové heslo znovu"
                required
            />
        </Form>
    )
}

export default ChangeMyPasswordForm
