import {
    useState,
    type MouseEvent,
    Fragment,
} from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom"
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import Collapse from '@mui/material/Collapse'

import { useMotivationModels } from '@/hooks/api/use-motivation-model'
import { ROUTE_SEGMENTS, SCENARIO_LABEL_MAP } from '@/constants/general'
import { selectedMunicipalityIdAtom } from '@/state'
import { convertFromCents, printHumanDateTime, printPrice, roundPrice } from '@/helpers'
import MotivationModelActions from './actions'
import MotivationModelDuplicateForm from '@/components/forms/motivation-model-duplicate'
import type { MotivationModelListItem } from '@/types'
import ActivateDialog from './activate-dialog'
import MotivationModelNameForm from '../forms/motivation-model-name'
import ArchiveDialog from './archive-dialog'


function MotivationModelList({
    year,
}: {
    year: number,
}) {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const navigate = useNavigate()

    const [edit, setEdit] = useState<MotivationModelListItem | null>(null)
    const [duplicate, setDuplicate] = useState<MotivationModelListItem | null>(null)
    const [activate, setActivate] = useState<MotivationModelListItem | null>(null)
    const [archive, setArchive] = useState<MotivationModelListItem | null>(null)

    const handleClick = (_event: MouseEvent<unknown>, id: number, scenario: boolean) => {
        navigate(`${id}/${ROUTE_SEGMENTS.step}/${scenario ? '3' : '0'}`) 
    }
    
    const { status, data, error } = useMotivationModels(municipalityId, year)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto'
        }}>
            {data.length === 0 ?
                <Box px={2.5}>
                    Zatím jste nevytvořili žádný motivační model
                </Box>
            :
                <Table
                    size="small"
                    aria-label="Motivační program"
                    sx={{
                        mt: 1,
                        minWidth: 500,
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: 3 }}>ID</TableCell>
                            <TableCell>Název</TableCell>
                            <TableCell>Scénář</TableCell>
                            <TableCell>Slevy</TableCell>
                            <TableCell>Poplatek občana</TableCell>
                            <TableCell>Doplatek obce</TableCell>
                            <TableCell>Sleva příští rok</TableCell>
                            <TableCell>Vytvořeno</TableCell>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(row => (
                            <Fragment key={row.id}>
                                <TableRow
                                    sx={(theme) => ({
                                        cursor: 'pointer',
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '& > td, th': { borderBottom: 'unset' },
                                        ...((edit?.id === row.id || duplicate?.id === row.id) ? {
                                            backgroundColor: theme.palette.customLightYellow.light,
                                        } : {})
                                    })}
                                    hover
                                    onClick={(event) => handleClick(event, row.id, !!row.selected_scenario)}
                                >
                                    <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                        <Box display="flex" gap={1.5} alignItems="center">
                                            <Box component="span" minWidth={20}>{row.id}</Box>
                                            {row.active &&
                                                <Chip
                                                    label="Aktivní"
                                                    size="small"
                                                    color="primary"
                                                />
                                            }
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.selected_scenario ? SCENARIO_LABEL_MAP[row.selected_scenario] : '-'}</TableCell>
                                    <TableCell>
                                        {(row.mixed_volume_categories_disabled && row.mixed_weight_categories_disabled) ?
                                            '-'
                                            :
                                            <Box>
                                                {!row.mixed_weight_categories_disabled &&
                                                    <Tooltip title="Hmotnost">
                                                        <ScaleRoundedIcon sx={{fontSize: '1.4rem'}} />
                                                    </Tooltip>
                                                }
                                                {!row.mixed_volume_categories_disabled &&
                                                    <Tooltip title="Objem">
                                                        <DeleteRoundedIcon />
                                                    </Tooltip>
                                                }
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>{row.citizen_fee_cents != null ? printPrice(roundPrice(convertFromCents(row.citizen_fee_cents))) : '-'}</TableCell>
                                    <TableCell>{row.annual_surcharge_cents != null ? printPrice(roundPrice(convertFromCents(row.annual_surcharge_cents))) : '-'}</TableCell>
                                    <TableCell>{row.next_year_discount_cents != null ? printPrice(roundPrice(convertFromCents(row.next_year_discount_cents))) : '-'}</TableCell>
                                    <TableCell>
                                        <Box color="customGrey.secondaryText">{printHumanDateTime(dayjs(row.created_at))}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <MotivationModelActions
                                            motivationModel={row}
                                            setEdit={setEdit}
                                            setDuplicate={setDuplicate}
                                            setActivate={setActivate}
                                            setArchive={setArchive}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: 0 }} colSpan={9}>
                                        <Collapse in={edit?.id === row.id || duplicate?.id === row.id} timeout="auto" unmountOnExit>
                                            <Box sx={(theme) => ({
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 1.5,
                                                p: 1.5,
                                                justifyContent: 'center',
                                                borderTop: `1px solid ${theme.palette.customGrey.divider}`
                                            })}>
                                                {edit?.id === row.id &&
                                                    <MotivationModelNameForm
                                                        motivationModel={edit}
                                                        closeCallback={() => setEdit(null)}
                                                    />
                                                }
                                                {duplicate?.id === row.id &&
                                                    <MotivationModelDuplicateForm
                                                        motivationModel={duplicate}
                                                        closeCallback={() => setDuplicate(null)}
                                                    />
                                                }
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            }
            <Box p={2.5}>
                <Button
                    variant="contained"
                    color="terciary"
                    startIcon={<AddRoundedIcon />}
                    component={Link}
                    to={ROUTE_SEGMENTS.new+'?year='+year}
                >
                    Vytvořit nový model
                </Button>
            </Box>
            <Dialog
                key={'activate-'+activate?.id}
                open={!!activate}
                onClose={() => setActivate(null)}
            >
                <DialogContent>
                    <ActivateDialog
                        motivationModelId={activate?.id}
                        motivationModelName={activate?.name}
                        handleClose={() => setActivate(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        motivationModelId={archive?.id}
                        motivationModelName={archive?.name}
                        handleClose={() => setArchive(null)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default MotivationModelList
