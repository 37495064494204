import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFRichText from "@/components/form-fields/rhf-rich-text"
import type {
    PublicBinInstruction,
    PublicBinInstructionFormInput,
} from "@/types"
import {
    useCreatePublicBinInstruction,
    useUpdatePublicBinInstruction,
} from "@/hooks/api/use-public-bin-instruction"

const PublicBinInstructionForm = ({
    publicBinInstruction,
    closeCallback,
} : {
    publicBinInstruction?: PublicBinInstruction,
    closeCallback?: () => void,
}) => {

    const formDataId = `publicBinInstructionForm-${publicBinInstruction?.id}-v${APP_VERSION}`

    const initialValues = {
        description: publicBinInstruction ? publicBinInstruction.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreatePublicBinInstruction({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
    })

    const mutationUpdate = useUpdatePublicBinInstruction(
        publicBinInstruction?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<PublicBinInstructionFormInput> = data => {
        setRequestInProgress(true)
        if (publicBinInstruction?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    const description = watch('description')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={publicBinInstruction ? 'Upravit obsah' : 'Vytvořit sekci'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={publicBinInstruction ? 'Upravit' : 'Vytvořit sekci'}
            disableSave={!description}
        >
            <RHFRichText
                name="description"
                control={control}
                label="Obsah *"
                multipleRows
            />
        </Form>
    )
}

export default PublicBinInstructionForm
