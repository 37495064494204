import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type { MunicipalityPublicVisibilityFormInput } from "@/types"
import { useSetMunicipalityPublicVisibility } from "@/hooks/api/use-municipality"

const MunicipalityPublicVisibilityForm = ({
    municipalityId,
    municipalityVisible,
    closeCallback,
} : {
    municipalityId: number,
    municipalityVisible: boolean,
    closeCallback?: () => void,
}) => {

    const initialValues: MunicipalityPublicVisibilityFormInput = {
        visible: municipalityVisible,
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(() => reset(initialValues))

    const mutation = useSetMunicipalityPublicVisibility(
        municipalityId,
        {
            setRequestInProgress,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<MunicipalityPublicVisibilityFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    const newVisible = watch('visible')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={false}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Upravit"
            heading="Upravit zobrazení obce na veřejném portálu"
            disableSave={municipalityVisible === newVisible}
        >
            <Box mx="auto" mb={1}>
                <FormControlLabel control={
                    <Controller
                        name="visible"
                        control={control}
                        render={({ field }) => <Switch
                            {...field}
                            value={!!field.value}
                            checked={!!field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                        />}
                    />
                } label="Obec se zobrazuje na veřejném portálu" />
            </Box>
        </Form>
    )
}

export default MunicipalityPublicVisibilityForm
