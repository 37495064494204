import axios, { type AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

import type {
    MutationOptions,
    CommunicationThreadDetail,
    FormMutationOptions,
    CreateCommunicationFormInput,
    CommunicationsResponse,
    CreateClaimCommunicationFormInput,
    CommunicationsCollectionCompanyQueryOptions,
    CommunicationsMunicipalityQueryOptions,
} from '@/types'
import { isErrorResponse } from '@/helpers'
import { selectedCollectionPointIdAtom } from '@/state'

export function useCommunicationsCollectionCompany({
    municipalityId,
    collectionCompanyId,
    initializedById,
    pagination,
    filters,
}: CommunicationsCollectionCompanyQueryOptions) {
    return useQuery({
        queryKey: [
            'communications',
            `municipality-${municipalityId}`,
            `collectionCompany-${collectionCompanyId}`,
            `initializedBy-${initializedById}`,
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            `communicationTypeId-${filters?.communicationTypeId}`,
            `closed-${filters?.closed}`,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()

            if (municipalityId) queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('collection_company_id', collectionCompanyId.toString())
            if (initializedById) queryParams.append('initialized_by_id', initializedById)
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            queryParams.append('archived', 'false')
            if (filters?.closed) queryParams.append('closed', filters.closed)
            if (filters?.communicationTypeId) queryParams.append('communication_type_id', filters.communicationTypeId.toString())
            
            const { data } : { data: CommunicationsResponse} = await axios.get(
                `/api/communication/collection_company_list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export function useCommunicationsMunicipality({
    municipalityId,
    collectionCompanyId,
    initializedById,
    pagination,
    filters,
}: CommunicationsMunicipalityQueryOptions) {
    return useQuery({
        queryKey: [
            'communications',
            `municipality-${municipalityId}`,
            `collectionCompany-${collectionCompanyId}`,
            `initializedBy-${initializedById}`,
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            `communicationTypeId-${filters?.communicationTypeId}`,
            `closed-${filters?.closed}`,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()

            queryParams.append('municipality_id', municipalityId.toString())
            if (collectionCompanyId) queryParams.append('collection_company_id', collectionCompanyId.toString())
            if (initializedById) queryParams.append('initialized_by_id', initializedById)
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            queryParams.append('archived', 'false')
            if (filters?.closed) queryParams.append('closed', filters.closed)
            if (filters?.communicationTypeId) queryParams.append('communication_type_id', filters.communicationTypeId.toString())
            
            const { data } : { data: CommunicationsResponse} = await axios.get(
                `/api/communication/municipality_list?${queryParams.toString()}`
            )
            return data
        },
    })
}

export function useCommunication(communicationId) {
    return useQuery({
        queryKey: ['communication', communicationId],
        queryFn: async () => {
            const { data } : { data: CommunicationThreadDetail } = await axios.get(
                '/api/communication/single?id=' + communicationId,
            )
            return data
        },
    })
}

export const useSendMessageToAuthority = (communicationId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
        onErrorCallback,
    } = options

    return useMutation({
        mutationFn: (message: string) => {            
            return axios.post(`/api/message/send_to_authority?communication_id=${communicationId 
                }&content=${encodeURIComponent(message)}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communication', communicationId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useSendMessageToCitizen = (communicationId, options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
        onErrorCallback,
    } = options

    return useMutation({
        mutationFn: (message: string) => {            
            return axios.post(`/api/message/send_to_citizen?communication_id=${communicationId 
                }&content=${encodeURIComponent(message)}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communication', communicationId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        },
        onError: (axiosResponse: AxiosError) => {
            const errorData = axiosResponse.response?.data
            if (onErrorCallback){
                if (isErrorResponse(errorData)) {
                    onErrorCallback(errorData.error)
                } else {
                    console.error(axiosResponse)
                    onErrorCallback('Došlo k neočekávané chybě.');
                }
            }
        }
    })
}

export const useCreateDestroyedBinReport = (municipalityId, collectionCompanyId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateClaimCommunicationFormInput) => {         
            const message = `Nádoby:\n${data.selectedBins}\n\nDatum: ${data.day}\n\nPopis: ${data.description}`
            return axios.post(`/api/communication/create_destroyed_bin_report?collection_company_id=${collectionCompanyId
                }&municipality_id=${municipalityId
                }&initial_message_content=${encodeURIComponent(message)
                }&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCreateUncollectedBinReport = (municipalityId, collectionCompanyId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateClaimCommunicationFormInput) => {     
            const message = `Nádoby:\n${data.selectedBins}\n\nDatum: ${data.day}\n\nPopis: ${data.description}`       
            return axios.post(`/api/communication/create_uncollected_bin_report?collection_company_id=${collectionCompanyId
                }&municipality_id=${municipalityId    
                }&initial_message_content=${encodeURIComponent(message)
                }&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCreateExtraCollectionRequest = (collectionCompanyId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateCommunicationFormInput) => {            
            return axios.post(`/api/communication/create_extra_collection_request?collection_company_id=${collectionCompanyId
                }&initial_message_content=${encodeURIComponent(data.description)}&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCreateContainerRequest = (collectionCompanyId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateCommunicationFormInput) => {            
            return axios.post(`/api/communication/create_container_request?collection_company_id=${collectionCompanyId
                }&initial_message_content=${encodeURIComponent(data.description)}&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCreateAttributesChangeRequest = (municipalityId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateCommunicationFormInput) => {            
            return axios.post(`/api/communication/create_attributes_change_request?municipality_id=${municipalityId
                }&initial_message_content=${encodeURIComponent(data.description)}&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCreateBinReplacementRequest = (binSummary: string, municipalityId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const collectionPointId = useAtomValue(selectedCollectionPointIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CreateCommunicationFormInput) => {            
            const message = binSummary+'\n\nDůvod: '+data.description
            return axios.post(`/api/communication/create_bin_replacement_request?municipality_id=${municipalityId
                }&initial_message_content=${encodeURIComponent(message)}&household_id=${collectionPointId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useArchiveCommunication = (
    communicationId: number,
    options: MutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/communication/archive?id=${communicationId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            queryClient.invalidateQueries({ queryKey: ['communication', communicationId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useCloseCommunication = (
    communicationId: number,
    options: MutationOptions,
) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: () => {
            return axios.post(`/api/communication/close?id=${communicationId}`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['communications'] })
            queryClient.invalidateQueries({ queryKey: ['communication', communicationId] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
