import { useAtomValue } from "jotai"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import dayjs from "dayjs"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, BIN_MATERIAL_LABEL_MAP, WASTE_TYPE_ID_LABEL_MAP } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextarea from "@/components/form-fields/rhf-textarea"
import type {
    Bin,
    CollectionCompany,
    CreateClaimCommunicationFormInput,
} from "@/types"
import { useCreateUncollectedBinReport } from "@/hooks/api/use-communication"
import { useLocation } from "react-router-dom"
import { selectedMunicipalityIdAtom } from "@/state"

const getDaysArray = (length: number): dayjs.Dayjs[] => {
    return Array.from({ length }, (_, i) => dayjs().subtract(i, "day"))
}

const getBinSummary = (bin: Bin): string => {
    return `${bin.bin_code?.visible_code} - ${WASTE_TYPE_ID_LABEL_MAP[bin.bin_type.waste_type.id].short} - (${bin.bin_type.volume+'\xa0l'}, ${BIN_MATERIAL_LABEL_MAP[bin.bin_type.material]})`
}

const CreateUncollectedBinReportForm = ({
    collectionCompany,
    bins,
    closeCallback,
    onSuccessCallback,
} : {
    collectionCompany: CollectionCompany,
    bins: Bin[],
    closeCallback?: () => void,
    onSuccessCallback?: () => void,
}) => {

    const municipalityId = useAtomValue(selectedMunicipalityIdAtom)

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const binId = params.get('bin-id')

    const formDataId = `createUncollectedBinReportForm-${collectionCompany.id}-v${APP_VERSION}`

    const initialValues = {
        selectedBinIds: binId ? [Number(binId)] : [],
        day: dayjs().format('D. M. YYYY'),
        description: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useCreateUncollectedBinReport(
        municipalityId,
        collectionCompany.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: () => {
                onSuccessCallback()
                closeCallback()
            }
        }
    )

    const onSubmit: SubmitHandler<CreateClaimCommunicationFormInput> = data => {
        setRequestInProgress(true)
        const selectedBins = bins.filter(bin => data.selectedBinIds.includes(bin.id)).map(bin => getBinSummary(bin))
        mutation.mutate({
            ...data,
            selectedBins: selectedBins.join('\n'),
        })
    }

    const selectedBinIds = watch("selectedBinIds")

    const daysArray = getDaysArray(collectionCompany.uncollected_bin_report_delay_days ?? 2)

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Nahlášení nevyvezené nádoby'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={'Odeslat'}
            disableSave={selectedBinIds.length === 0}
        >
            <FormControl component="fieldset" sx={{ mb: 1 }}>
                <Typography variant="h6" component="div">Vyberte nádoby *</Typography>
                <FormGroup>
                    {bins.map((bin) => (
                        <FormControlLabel
                            key={bin.id}
                            control={
                            <Controller
                                name="selectedBinIds"
                                control={control}
                                render={({ field }) => (
                                <Checkbox
                                    checked={field.value.includes(bin.id)}
                                    onChange={(e) => {
                                        const newValue = e.target.checked
                                          ? [...field.value, bin.id]
                                          : field.value.filter((id: number) => id !== bin.id);
                                        field.onChange(newValue);
                                      }}
                                />
                                )}
                            />
                            }
                            label={getBinSummary(bin)}
                        />
                    ))}
                </FormGroup>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="day-select-label">Den svozu</InputLabel>
                <Controller
                    name="day"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Den svozu">
                            {daysArray.map(day => (
                                <MenuItem key={day.format('D. M. YYYY')} value={day.format('D. M. YYYY')}>
                                    {day.format('D. M. YYYY')}
                                    {day.diff(dayjs(), 'day') === 0 ? ' (dnes)' : ''}
                                    {day.diff(dayjs(), 'day') === -1 ? ' (včera)' : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
            <RHFTextarea
                name="description"
                control={control}
                label="Popis"
                size="small"
                required
            />
        </Form>
    )
}

export default CreateUncollectedBinReportForm
