import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import LargeContainerMap from '@/components/large-container-map'
import LargeContainerMapInfo from '@/components/large-container-map/info'

function LargeContainerMapView() {

    const title = 'Veřejná svozová místa'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box position="relative">
                    <Typography variant="h2" component="h1" mb={1.5}>
                        {title}
                    </Typography>
                    <LargeContainerMapInfo />
                    <LargeContainerMap />
                </Box>
            </CustomPaper>
        </>
    )
}

export default LargeContainerMapView
